import React from 'react';
import PropTypes from 'prop-types';

const Subtext = (props) => {

  let {fieldValue} = props.children;
  let {classExt} = props;
  let show = !fieldValue.validating && !fieldValue.pending && (fieldValue.valid || fieldValue.pristine || !fieldValue.touched);
  if(props.forceShow)
    show = true;
  let isHtml = props.text.indexOf('<') > -1;
  /*eslint-disable react/no-danger*/
  return (
    <div className={`ltFormSubtext ${classExt}`}>
      {show && !isHtml ?
        props.text : show && isHtml ? <span dangerouslySetInnerHTML={{__html: props.text}}/> : null
      }</div>
  );
  /*eslint-enable */
};

Subtext.propTypes = {
  children: PropTypes.object,
  text: PropTypes.string,
  classExt: PropTypes.string,
  forceShow: PropTypes.bool
};

export default Subtext;

