import React from 'react';
import PropTypes from 'prop-types';

const EditButton = (props) => {
    return (<button id="ltEditButton" onClick={props.action}>{props.label}</button>);
};

EditButton.propTypes = {
    action: PropTypes.func,
    label: PropTypes.string
};

export default EditButton;
