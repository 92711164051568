/**
 * Created by ghawk on 5/12/2017.
 */

const stepNumRegex = /\/\d+\//;

export const getFormId = () => {
  let formId;
  let loadFormIdFrom = window.ltPhoenixLoadFormFrom ? window.ltPhoenixLoadFormFrom : "all";
  if ((loadFormIdFrom === "all" || loadFormIdFrom === "hash") && location.hash.length > 0) {
    let hashPaths = location.hash.split('/');
    if (hashPaths !== undefined)
      formId = hashPaths[1] !== undefined && hashPaths[1] !== '' ? hashPaths[1].replace('#', '') : hashPaths[0].replace('#', '');
    else
      formId = location.hash.replace('#', '');
  } else {
    let paths = location.pathname.split('/');
    if (paths.length > 0) {
      formId = paths[paths.length - 1] !== '' ? paths[paths.length - 1] : paths[paths.length - 2];
    }
  }
  return formId !== undefined && formId.length > 0 ? formId : undefined;
};

export const getStepName = (payload, formId) => {
  let context = payload || location;
  let path = context.hash.length > 0 ? context.hash : context.pathname;
  let segments = path.split('/');

  if (path.match(stepNumRegex))
    return segments[segments.length - 3];

  if (!path.match(stepNumRegex) && segments.length > 2) {
    //check if last segment is formId
    return (segments[segments.length - 1] === formId) ? undefined : segments[segments.length - 1];
  }

  return undefined;
};

export const getStepNum = (payload) => {
  let context = payload || location;
  let path = context.hash.length > 0 ? context.hash : context.pathname;
  let segments = path.split('/');

  if (path.match(stepNumRegex))
    return parseInt(segments[segments.length - 2]);

  return undefined;
};

export const urlBuilder = (formId, stepName, index, qs, keepIndex) => {

  let path = location.hash.length > 0 ? location.hash : location.pathname;
  let segments = path.split('/');
  let hasStepNum = path.match(stepNumRegex);
  let stepNum = index || (hasStepNum ? parseInt(segments[segments.length - 2]) + (keepIndex ? 0 : 1) : 1);

  if (location.hash.length > 0) {
    if (stepName === undefined)
      return window.location.pathname + window.location.search + (qs !== undefined ? qs : '') + '#/' + formId;

    return window.location.pathname + window.location.search + (qs !== undefined ? qs : '') + '#/' + formId + '/' + stepName + '/' + stepNum + '/';
  }

  let formIdIndex = segments.findIndex((item) => {
    let formIdIndex = formId.indexOf('~') !== -1 ? formId.indexOf('~') : formId.length;
    let itemIndex = item.indexOf('~') !== -1 ? item.indexOf('~') : item.length;
    return item.length > 0 && formId.slice(0, formIdIndex) === item.slice(0, itemIndex);
  });

  //get part of path that is not part of formPath and replace
  if (formIdIndex !== -1) {
    segments = segments.slice(0, formIdIndex);
  }

  if (stepName === undefined)
    return segments.join('/') + '/' + formId + (window.location.search.length > 0 ? window.location.search + (qs !== undefined ? qs : '') : "");

  return segments.join('/') + '/' + formId + '/' + stepName + '/' + stepNum + '/' + (window.location.search.length > 0 ? window.location.search + (qs !== undefined ? qs : '') : "");

};

export const clearUrlPath = (formId) => {
  let hasHash = location.hash.length > 0;
  let path = hasHash ? location.hash : location.pathname;
  let segments = path.split('/');

  if (hasHash) {
    location.hash = formId;
    return;
  }

  let formIdIndex = segments.findIndex((item) => {
    return item !== "" && formId.indexOf(item) !== -1;
  });

  if (formIdIndex !== -1) {
    segments = segments.slice(0, formIdIndex + 1);
  }

  location.pathname = segments.join('/');
};
