import * as types from '../actions/formActionTypes';
import * as metaTypes from '../actions/formMetaTypes';

export default function formMetaReducer(
  state = { product: '', formId: '' },
  action
) {
  switch (action.type) {
    case types.STEP_LOADED:
      return Object.assign({}, state, {
        product: action.product,
        formUuid: action.formUuid,
        formId: action.formId,
        stepIndex: action.stepIndex,
        stepName: action.stepName,
        utoken: action.utoken,
        vertical: action.vertical,
        displayLanguage: action.displayLanguage,
        autoSubmitStatus: action.autoSubmitStatus,
        autoSubmitMissingProperties: action.autoSubmitMissingProperties,
      });
    case metaTypes.META_CHANGE:
      return Object.assign({}, state, action.formMeta);
    case types.FORM_AUTO_SUBMIT_FAIL:
      return Object.assign({}, state, {
        autoSubmitStatus: action.status,
      });
    case types.FORM_LOADING:
      return Object.assign({}, state, {
        autoSubmit: action.autoSubmit,
        autoSubmitStatus: action.autoSubmitStatus,
        treeAuthId: action.treeAuthId,
        insideFlow: action.insideFlow,
      });
    default:
      return state;
  }
}
