const useLast4SSNMultipartFormat = ({ step }) => {
  const { box1, box2 } = step.multipartcontrol;

  return {
    handleValueCombine: () => (val) => `${val.box3}`,
    splitFunction: () => (val) => {
      if (val.length !== 4) return;

      return {
        box1value: box1.placeholder.trim().length > 0 ? box1.placeholder.trim() : 'XXX',
        box2value: box2.placeholder.trim().length > 0 ? box2.placeholder.trim() : 'XX',
        box3value: val
      };
    }
  };
};

export default useLast4SSNMultipartFormat;
