import React from 'react';
import PropTypes from 'prop-types';
import './softOffer.css';
import {connect} from "react-redux";
import {getFormData} from "../../../selectors/formSelector";

class LabelNode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  render() {
    const label = this.props.step.labelnodelabel;
    const value = this.props.step.labelnodevalue ?
        this.props.formData[this.props.step.labelnodevalue] :
        null;

    // If there is a value set, then get the default value as well
    const defaultValue = !!this.props.step.labelnodevalue && this.props.step.labelnodedefaultvalue;

    return (
        <div className={"ltLabelNode" + (!value ? " noValue" : "")}>
          {!!label &&
            <label className="ltLabelNodeLabel" htmlFor={"labelNode-"+this.props.step.name}>{label}</label>
          }
          {(!!value || !!defaultValue) &&
            <span className="ltLabelNodeValue" id={"label-"+this.props.step.name}>{value || defaultValue}</span>
          }
        </div>
    );
  }
}

LabelNode.propTypes = {
  // Platform props
  step: PropTypes.object,
  dispatch: PropTypes.func,
  formData: PropTypes.object,
};


function mapStateToProps(state) {
  return {
    formData: getFormData(state)
  };
}

export default connect(mapStateToProps)(LabelNode);