const allowedStates = {
  AL: 'ALABAMA',
  AK: 'ALASKA',
  AZ: 'ARIZONA',
  AR: 'ARKANSAS',
  CA: 'CALIFORNIA',
  CO: 'COLORADO',
  CT: 'CONNECTICUT',
  DE: 'DELAWARE',
  DC: 'DISTRICT OF COLUMBIA',
  FL: 'FLORIDA',
  GA: 'GEORGIA',
  HI: 'HAWAII',
  ID: 'IDAHO',
  IL: 'ILLINOIS',
  IN: 'INDIANA',
  IA: 'IOWA',
  KS: 'KANSAS',
  KY: 'KENTUCKY',
  LA: 'LOUISIANA',
  ME: 'MAINE',
  MD: 'MARYLAND',
  MA: 'MASSACHUSETTS',
  MI: 'MICHIGAN',
  MN: 'MINNESOTA',
  MS: 'MISSISSIPPI',
  MO: 'MISSOURI',
  MT: 'MONTANA',
  NE: 'NEBRASKA',
  NV: 'NEVADA',
  NH: 'NEW HAMPSHIRE',
  NJ: 'NEW JERSEY',
  NM: 'NEW MEXICO',
  NY: 'NEW YORK',
  NC: 'NORTH CAROLINA',
  ND: 'NORTH DAKOTA',
  OH: 'OHIO',
  OK: 'OKLAHOMA',
  OR: 'OREGON',
  PA: 'PENNSYLVANIA',
  RI: 'RHODE ISLAND',
  SC: 'SOUTH CAROLINA',
  SD: 'SOUTH DAKOTA',
  TN: 'TENNESSEE',
  TX: 'TEXAS',
  UT: 'UTAH',
  VT: 'VERMONT',
  VA: 'VIRGINIA',
  WA: 'WASHINGTON',
  WV: 'WEST VIRGINIA',
  WI: 'WISCONSIN',
  WY: 'WYOMING',
};

const signUpComponentFieldMap = {
  FirstName: 'firstName',
  LastName: 'lastName',
  StreetAddress: 'streetAddress',
  ZipCode: 'zipCode',
  City: 'city',
  State: 'state',
  DOB: 'birthdate',
  EmailAddress: 'email',
  Password: 'password',
  Last4SSN: 'ssn3',
  termsSigned: 'termsSigned',
  PhoneNumber: 'phoneNumber',
};

const validate = (field, value) => {
  let regex;
  switch (field) {
    case 'FirstName':
    case 'LastName':
      regex = /^[a-zA-Z'.\-\s]{2,50}$/;
      return regex.test(value);
    case 'StreetAddress':
      regex = /^[a-zA-Z0-9-.,'#]+\s(?:[a-zA-Z0-9-.,'#\s])+$/;
      return regex.test(value);
    case 'ZipCode':
      regex = /^\d{5}$/;
      return regex.test(value);
    case 'City':
      regex = /^[a-zA-Z-.]+(?:[a-zA-Z-.\s])*$/;
      return regex.test(value);
    case 'State':
      return Object.keys(allowedStates).indexOf(value) !== -1;
    case 'DOB': {
      let tokens = value.split('/');
      return value.length === 10 && tokens.length === 3;
    }
    default:
      return true;
  }
};

const getComponentFormData = (formData) => {
  let result = {};
  const dataKeys = Object.keys(signUpComponentFieldMap);
  for (let i = 0; i < dataKeys.length; i++) {
    const currKey = dataKeys[i];
    const mappedKey = signUpComponentFieldMap[currKey];
    if (formData[currKey] && validate(currKey, formData[currKey])) {
      result[mappedKey] = formData[currKey];
    }
  }
  // PHX-5917 :: INFO :: Zach James
  // Component expects YYYY-MM-DD format
  // We store MM/DD/YYYY in DOB
  if (result['birthdate']) {
    const tokens = result['birthdate'].split('/');
    result['birthdate'] = `${tokens[2]}-${tokens[0]}-${tokens[1]}`;
  }
  if (Object.keys(result).length > 0) {
    if (!result.state || !result.city || !result.zipCode) {
      delete result.state;
      delete result.city;
      delete result.zipCode;
    }
    return result;
  } else {
    return undefined;
  }
};

module.exports = {
  getComponentFormData,
};
