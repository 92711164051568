import React from 'react';
import { Field, Errors, Control, actions } from 'react-redux-form';
import Subtext from '../subText';
import {
  getValidations,
  getValidationMessages,
} from '../../../validators/validators';
import PropTypes from 'prop-types';
import * as formatTypes from '../../../utils/formatTypes';
import * as boundTypes from '../../../utils/boundTypes';
import * as sliderTypes from '../../../utils/sliderTypes';
import { titleCase } from '../../../utils/titleCase';
import { reportFieldEngagement } from '../../../bootstrap';
import { getStore } from '../../../store';
import SliderButton from './SliderButton';

const errorComponentClassName = 'ltErrorText';
const _findRangeStep = (step, value) => {
  let index = _findRangeStepIndex(step, value);
  if (index < 0 || index >= step.rangecontrol.rangeSteps.length) {
    index = 0;
  }
  return step.rangecontrol.rangeSteps[index];
};

const _findRangeStepIndex = (step, value) => {
  if (step.rangecontrol.slidertype === sliderTypes.BUILD_YOUR_OWN) {
    let index = step.rangecontrol.rangeSteps.findIndex(
      (rangeStep) =>
        Number(rangeStep.lowrange) <= Number(value) &&
        Number(rangeStep.highrange) >= Number(value)
    );
    if (
      index == -1 &&
      Number(value) >
        Number(
          step.rangecontrol.rangeSteps[step.rangecontrol.rangeSteps.length - 1]
            .lowrange
        )
    ) {
      index = step.rangecontrol.rangeSteps.length - 1;
    }
    return index;
  }
  return step.rangecontrol.rangeSteps.findIndex(
    (rangeStep) => rangeStep.targetvalue === value
  );
};

const getRangeFeedback = (step, value, formatter) => {
  if (step.rangecontrol.rangeSteps) {
    let rangeStep = _findRangeStep(step, value);
    if (rangeStep && rangeStep.slidertemplate) {
      return rangeStep.slidertemplate;
    }
  }

  if (
    !step.rangecontrol.slidertype ||
    step.rangecontrol.slidertype === sliderTypes.GENERAL
  ) {
    return getRangeFeedbackGeneral(step, value, formatter);
  } else if (step.rangecontrol.slidertype === sliderTypes.BUILD_YOUR_OWN) {
    let stepIndex = _findRangeStepIndex(step, value);
    stepIndex = stepIndex == -1 ? 0 : stepIndex;
    let rangeStart = formatter(
      step.rangecontrol.rangeSteps[stepIndex].lowrange
    );
    let rangeEnd = formatter(step.rangecontrol.rangeSteps[stepIndex].highrange);
    if (step.formatter === formatTypes.PERCENT) {
      rangeStart = rangeStart.slice(0, -1);
    }
    let dividerText = step.rangecontrol.rangedivider;
    if (dividerText && dividerText.length > 0) {
      return `${rangeStart} ${dividerText} ${rangeEnd}`;
    } else {
      return rangeStart;
    }
  } else {
    let enumItem = step.enumeration.find((item) => item.value === value);
    if (enumItem) {
      return enumItem.label;
    }
  }
  return '';
};

const getLowerBoundValueFromStep = (step) => {
  if (
    !step.rangecontrol.slidertype ||
    step.rangecontrol.slidertype === sliderTypes.GENERAL
  ) {
    return step.validation.rangeLowerBound || step.rangecontrol.lowerbound;
  } else if (step.rangecontrol.slidertype === sliderTypes.BUILD_YOUR_OWN) {
    let lowerBound = _findRangeStepIndex(step, step.rangecontrol.lowerbound);
    if (lowerBound == -1) {
      lowerBound = '0';
    }
    return lowerBound;
  } else {
    return step.rangecontrol.lowerbound;
  }
};

const getUpperBoundValueFromStep = (step) => {
  if (
    !step.rangecontrol.slidertype ||
    step.rangecontrol.slidertype === sliderTypes.GENERAL
  ) {
    return step.validation.rangeUpperBound || step.rangecontrol.upperbound;
  } else if (step.rangecontrol.slidertype === sliderTypes.BUILD_YOUR_OWN) {
    let higherBound = _findRangeStepIndex(step, step.rangecontrol.upperbound);
    if (higherBound == -1) {
      higherBound = step.rangecontrol.rangeSteps.length - 1;
    }
    return higherBound;
  } else {
    return step.rangecontrol.upperbound;
  }
};

const getLowerBoundText = (step, formatter) => {
  if (
    step.rangecontrol.lowerboundtext &&
    step.rangecontrol.lowerboundtext.length > 0
  ) {
    return step.rangecontrol.lowerboundtext;
  }
  return formatter(getLowerBoundValueFromStep(step));
};

const getUpperBoundText = (step, formatter) => {
  if (
    step.rangecontrol.upperboundtext &&
    step.rangecontrol.upperboundtext.length > 0
  ) {
    return step.rangecontrol.upperboundtext;
  }
  return formatter(getUpperBoundValueFromStep(step));
};

const getValue = (step) => {
  let stepValue = step.value || step.rangecontrol.initialValue;
  if (
    step.rangecontrol.slidertype === sliderTypes.BUILD_YOUR_OWN ||
    step.rangecontrol.slidertype === sliderTypes.ENUMERATION
  ) {
    stepValue = _findRangeStepIndex(step, stepValue);
    if (stepValue == -1) {
      stepValue = getLowerBoundValueFromStep(step);
    }
  }
  return stepValue;
};

const getHelp = (step, stepValue) => {
  if (step.rangecontrol.rangeSteps) {
    let rangeStep = _findRangeStep(step, stepValue);
    if (rangeStep && rangeStep.helptemplate) {
      return rangeStep.helptemplate;
    }
  }
  return step.help;
};

const getRangeFeedbackGeneral = (step, value, formatter) => {
  let rangeFeedback = formatter(value);
  if (!step.rangecontrol.rangedivider) {
    return rangeFeedback;
  }
  let rangeStart;
  let rangeEnd;
  const numValue = Number(value);
  const numIncrement = Number(step.rangecontrol.increment);
  let stepLowerBound = Number(getLowerBoundValueFromStep(step));
  let stepUpperBound = Number(getUpperBoundValueFromStep(step));
  switch (step.rangecontrol.boundused) {
    case boundTypes.UPPER: {
      rangeStart = formatter(numValue - numIncrement);
      rangeEnd = formatter(numValue);
      if (step.formatter === formatTypes.PERCENT) {
        rangeStart = rangeStart.slice(0, -1);
      }
      if (step.rangecontrol.rangedivider.length > 0) {
        if (numValue - numIncrement < stepLowerBound) {
          rangeStart = formatter(stepLowerBound);
          rangeEnd = formatter(stepLowerBound + numIncrement);
        }
        rangeFeedback = `${rangeStart} ${step.rangecontrol.rangedivider} ${rangeEnd}`;
      } else rangeFeedback = formatter(value);
      break;
    }
    case boundTypes.MIDDLE: {
      rangeStart = formatter(numValue - numIncrement / 2);
      rangeEnd = formatter(numValue + numIncrement / 2);
      //check if at boundaries
      if (numValue - numIncrement < stepLowerBound) {
        rangeStart = formatter(stepLowerBound);
        rangeEnd = formatter(stepLowerBound + numIncrement);
      } else if (numValue + numIncrement > stepUpperBound) {
        rangeStart = formatter(stepUpperBound - numIncrement);
        rangeEnd = formatter(stepUpperBound);
      }
      if (step.formatter === formatTypes.PERCENT) {
        rangeStart = rangeStart.slice(0, -1);
      }
      if (step.rangecontrol.rangedivider.length > 0)
        rangeFeedback = `${rangeStart} ${step.rangecontrol.rangedivider} ${rangeEnd}`;
      else rangeFeedback = formatter(value);
      break;
    }
    case boundTypes.LOWER: {
      rangeStart = formatter(value);
      rangeEnd = formatter(numValue + numIncrement);
      if (step.formatter === formatTypes.PERCENT) {
        rangeStart = rangeStart.slice(0, -1);
      }
      if (step.rangecontrol.rangedivider.length > 0) {
        if (numValue + numIncrement > stepUpperBound) {
          rangeStart = formatter(stepUpperBound - numIncrement);
          rangeEnd = formatter(stepUpperBound);
        }
        rangeFeedback = `${rangeStart} ${step.rangecontrol.rangedivider} ${rangeEnd}`;
      } else rangeFeedback = formatter(value);
      break;
    }
  }
  return rangeFeedback;
};
const getMin = (step) => {
  let stepLowerBound = getLowerBoundValueFromStep(step);
  if (
    (!step.rangecontrol.slidertype ||
      step.rangecontrol.slidertype === sliderTypes.GENERAL) &&
    step.rangecontrol.rangedivider
  ) {
    const numIncrement = Number(step.rangecontrol.increment);
    const numLowerBound = Number(stepLowerBound) || 0;
    switch (step.rangecontrol.boundused) {
      case boundTypes.UPPER: {
        if (step.enumeration.length === 0) {
          return numLowerBound + numIncrement;
        } else return numLowerBound;
      }
      case boundTypes.MIDDLE: {
        return numLowerBound + numIncrement / 2;
      }
      case boundTypes.LOWER: {
        return numLowerBound;
      }
      default:
        return numLowerBound;
    }
  } else {
    return Number(stepLowerBound);
  }
};

const getStep = (step) => {
  if (
    !step.rangecontrol.slidertype ||
    step.rangecontrol.slidertype === sliderTypes.GENERAL
  ) {
    return step.rangecontrol.increment;
  } else {
    return 1;
  }
};

const getMax = (step) => {
  let stepUpperBound = getUpperBoundValueFromStep(step);
  if (
    (!step.rangecontrol.slidertype ||
      step.rangecontrol.slidertype === sliderTypes.GENERAL) &&
    step.rangecontrol.rangedivider
  ) {
    const numIncrement = Number(step.rangecontrol.increment);
    const numUpperBound = Number(stepUpperBound) || 20;
    switch (step.rangecontrol.boundused) {
      case boundTypes.UPPER: {
        return numUpperBound;
      }
      case boundTypes.MIDDLE: {
        return numUpperBound - numIncrement / 2;
      }
      case boundTypes.LOWER: {
        return numUpperBound - numIncrement;
      }
      default:
        return numUpperBound;
    }
  } else {
    return Number(stepUpperBound);
  }
};

const getVariableSlideIncrement = (step) => {
  let currentValue = Number(
    getStore().getState().formData[`${step.attributeid}`]
  );
  let increment = step.rangecontrol.increment;

  if (
    step.rangecontrol.variableIncrements !== undefined &&
    step.rangecontrol.variableIncrements !== null &&
    step.rangecontrol.variableIncrements.length > 0
  ) {
    let result = step.rangecontrol.variableIncrements.find(
      (variableRangeObject) =>
        currentValue >= Number(variableRangeObject.lowerbound) &&
        currentValue <= Number(variableRangeObject.upperbound)
    );

    increment = result.increment;
  }

  return Number(increment);
};

const debounceFunc = function (func, wait, immediate) {
  let timeout;
  return function executedFunction() {
    let context = this;
    let args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const debounceReport = debounceFunc((fieldName, fieldValue) => {
  reportFieldEngagement(fieldName, fieldValue);
}, 500);

/* PL-563: we need to increment/decrement the slider when user keeps the button pressed. onMousePressed
  event we set a flag(mousePressed) to indicate mouse is pressed and a timer is started which keeps calling incrementSlider or decrementSlider
  every 300ms, till the flag (mousePressed) is true.
  onMouseUp event we set the flag to false and clear the timer using mousePressedTimerId we created earlier.
  On mobile devices both touchstart, mousedown and touchend, moueseup events are triggered, so for touch events preventdefault is
  called to stop mouse events firing.
  */
let mousePressed = false;
let mousePressedTimerId = 0;

const decrementSlider = function (step) {
  let dispatch = getStore().dispatch;
  //get currentValue of slider and decrement it by the incremental value defined in rangecontrol
  let currentValue = Number(
    getStore().getState().formData[`${step.attributeid}`]
  );
  let newValue =
    currentValue > getMin(step)
      ? step.rangecontrol.variableIncrements !== undefined
        ? currentValue - getVariableSlideIncrement(step)
        : currentValue - Number(step.rangecontrol.increment)
      : getMin(step);
  dispatch(actions.change(`formData.${step.attributeid}`, `${newValue}`));
  //Trigger onInput event of slider element
  triggerInputEvent(step);
  return newValue;
};

const incrementSlider = function (step) {
  let dispatch = getStore().dispatch;
  //get currentValue of slider and increment it by the incremental value defined in rangecontrol
  let currentValue = Number(
    getStore().getState().formData[`${step.attributeid}`]
  );
  let newValue =
    currentValue < getMax(step)
      ? step.rangecontrol.variableIncrements !== undefined
        ? currentValue + getVariableSlideIncrement(step)
        : currentValue + Number(step.rangecontrol.increment)
      : getMax(step);
  dispatch(actions.change(`formData.${step.attributeid}`, `${newValue}`));
  //Trigger onInput event of slider element
  triggerInputEvent(step);
  return newValue;
};

const triggerInputEvent = function (step) {
  let sliderComponent = document.getElementById(step.attributeid);
  sliderComponent &&
    sliderComponent.dispatchEvent(new Event('input', { bubbles: true }));
};

const createTimer = function (event, step, callback) {
  if (event.type && event.type.indexOf('touch') > -1) {
    event.preventDefault();
    callback(step);
  }

  if (mousePressed == false && mousePressedTimerId == 0) {
    let timerId = setInterval(callback, 300, step);
    mousePressed = true;
    mousePressedTimerId = timerId;
  }
};
const stopTimer = function () {
  if (mousePressedTimerId != 0) {
    mousePressed = false;
    clearInterval(mousePressedTimerId);
    mousePressedTimerId = 0;
  }
};
/*
PL-563: On touch devices when user's touch moves away from the slider plus/minus buttons we need to stop the
slider increment/decrement action. This method gets the current element where the user's touch is and if its not
the slider button then cancel the increment/decrement timer.
*/
const touchMovedAction = function (event) {
  event.preventDefault();
  let xPos = event.touches[0].pageX;
  let yPos = event.touches[0].pageY;

  let currentTouchedElement = document.elementFromPoint(xPos, yPos);
  if (currentTouchedElement) {
    if (
      currentTouchedElement.id !== 'sliderMinusSpan' &&
      currentTouchedElement.id !== 'rangeMinusBtn' &&
      currentTouchedElement.id !== 'sliderPlusSpan' &&
      currentTouchedElement.id !== 'rangePlusBtn'
    )
      stopTimer();
  }
};
//end PL-563

const setSliderValue = function (newValue, step) {
  let dispatch = getStore().dispatch;
  dispatch(actions.change(`formData.${step.attributeid}`, `${newValue}`));
  //Trigger onInput event of slider element to set the slider position appropriately
  triggerInputEvent(step);
};

const Range = ({ step, formatter }) => {
  const [lastReportedValue, setLastReportedValue] = React.useState('');
  let quickAccessContainer = null;

  /* PL-552: Show frequently used amounts as buttons below the slider. The amounts will be configured in the form.
  The click event of the button will call a method to set the Slider to the selected amount
  */
  if (
    step.rangecontrol.quickAccessValues &&
    step.rangecontrol.quickAccessValues.length > 0
  ) {
    let quickAccessButtons = step.rangecontrol.quickAccessValues.map(function (
      name,
      index
    ) {
      return (
        <div
          className={`ltFormQuickAccessItem${index} GridItem`}
          key={`${name.quickAccessStoreValue}`}
        >
          <SliderButton
            name={`GridItemButton GridItemButton${index}`}
            clickAction={() => setSliderValue(name.quickAccessStoreValue, step)}
            displayText={name.quickAccessDisplayValue}
          />
        </div>
      );
    });
    quickAccessContainer = (
      <div className="ltFormRangeQuickAccessContainer">
        <div className="ltFormRangeQuickAccessHeader">
          {step.rangecontrol.quickaccessheader}
        </div>
        <div className="ltFormRangeQuickAccessGrid">{quickAccessButtons}</div>
      </div>
    );
  }

  return (
    <div className={`ltFormGroupContent${titleCase(step.nodetype, 0)}`}>
      <div className={`ltFormControlRange ${step.attributeid}`}>
        <div className="ltFormControlRangeHeader">
          {
            /* PL-563: if slider button is enabled show the Minus button */
            step.rangecontrol.sliderButtons && (
              <SliderButton
                name="Minus"
                clickAction={() => decrementSlider(step)}
                startTimerAction={(event) =>
                  createTimer(event, step, decrementSlider)
                }
                stopTimerAction={stopTimer}
                touchMoveAction={(event) => touchMovedAction(event)}
              />
            )
          }
          <Control.text
            model={`formData.${step.attributeid}`}
            component={(props) => (
              <div className={`ltFormRangeFeedback ${step.attributeid}`}>
                {getRangeFeedback(step, props.value, formatter)}
              </div>
            )}
          />
          {
            /* PL-563: if slider button is enabled show the Plus button */
            step.rangecontrol.sliderButtons && (
              <SliderButton
                name="Plus"
                clickAction={() => incrementSlider(step)}
                startTimerAction={(event) =>
                  createTimer(event, step, incrementSlider)
                }
                stopTimerAction={stopTimer}
                touchMoveAction={(event) => touchMovedAction(event)}
              />
            )
          }
        </div>
        <Field
          model={`formData.${step.attributeid}`}
          validateOn={['change']}
          validators={getValidations(step.validation)}
          updateOn={['change']}
          changeAction={(model, value, event) => (dispatch, getState) => {
              let fieldValue = event.currentValue ? event.currentValue : value;
              if (fieldValue !== lastReportedValue) {
                debounceReport(step.attributeid, fieldValue);
                setLastReportedValue(fieldValue);
              }
              if (
                getState().forms.formData[step.attributeid] !== undefined &&
                getState().forms.formData[step.attributeid].pristine ===
                  false &&
                event === undefined
              )
                return;
              if (
                step.rangecontrol.slidertype &&
                step.rangecontrol.slidertype !== sliderTypes.GENERAL
              ) {
                value = step.rangecontrol.rangeSteps[value].targetvalue;
              }
              dispatch(actions.change(model, value));
            }} /*this is only needed for ie11 issue*/
          mapProps={{
            onMouseUp: (props) => {
              return props.onChange;
            },
            value: (props) => {
              let stepValue = props.fieldValue.value || '';
              if (
                step.rangecontrol.slidertype &&
                step.rangecontrol.slidertype !== sliderTypes.GENERAL
              ) {
                stepValue = _findRangeStepIndex(step, props.fieldValue.value);
              }
              return stepValue;
            },
          }}
        >
          <input
            type="range"
            id={step.attributeid}
            className={`ltFormRange ${step.attributeid}`}
            style={{
              '--min': getMin(step),
              '--max': getMax(step),
              '--val': getValue(step),
            }}
            min={getMin(step)}
            max={getMax(step)}
            step={getStep(step)}
            onInput={(e) => {
              e.target.setAttribute(
                'style',
                `--min: ${getMin(step)};--max:${getMax(step)};--val:${
                  e.target.value
                }`
              );
            }}
          />
        </Field>
        <div className={`ltFormRangeRange ${step.attributeid}`}>
          <div className={`lowerRange ${step.attributeid}`}>
            {getLowerBoundText(step, formatter)}
          </div>
          <div className={`upperRange ${step.attributeid}`}>
            {getUpperBoundText(step, formatter)}
          </div>
          <div style={{ clear: 'both' }} />
        </div>
      </div>
      <Errors
        className={`ltFormHelpBlock ltHasError ${errorComponentClassName}`}
        wrapper="div"
        show={{ touched: true }}
        model={`formData.${step.attributeid}`}
        messages={getValidationMessages(step.validation)}
      />
      {quickAccessContainer}
      <Control.text
        model={`formData.${step.attributeid}`}
        component={(props) => (
          <Subtext
            forceShow={step.persistHelpText}
            text={getHelp(step, props.value)}
          >
            {props}
          </Subtext>
        )}
        mapProps={{
          fieldValue: (props) => props.fieldValue,
        }}
      />
    </div>
  );
};

Range.propTypes = {
  steps: PropTypes.array,
  step: PropTypes.object,
  handleNext: PropTypes.func,
  formatter: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  fieldValue: PropTypes.object,
  dispatch: PropTypes.func,
  changeAction: PropTypes.func,
};

export default Range;
