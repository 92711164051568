import React from 'react';
import PropTypes from 'prop-types';

function GenericError({ errorMessage }) {
  return (
    <div className={'ltHasError'} style={{ display: 'block' }}>
      {errorMessage}
    </div>
  );
}
GenericError.propTypes = {
  errorMessage: PropTypes.string,
};
export default GenericError;
