/* eslint-disable no-useless-escape */
// PHX-5828 :: Zach James :: INFO
// This module is responsible for ensuring the initAuth call is made
// The call should be made after the user has given their email
// and we should wait until receiving a response before redirecting the user
// This solution currently doesn't work if EmailAddress is the last step TODO
import { getFetchFunction } from '../../bootstrap';
import { getStore } from '../../store';
import { actions } from 'react-redux-form';
import configSetting from '../../bootstrap/env';
let config =
  configSetting[process.env.PHOENIX_ENVIRONMENT] || configSetting.dev;

// PHX-5828 :: Zach James :: TECH DEBT
// We should rely on the validation done within PHX for testing email
// However, there's a bug where email is considered valid if the user
// navigates backwards while email is still validating
// I could pull the regex from the step definition, but since we need
// to update this again anyway, I'm hardcoding it for now
// Also the reason for the eslint disable at the file head
const emailRegex =
  /^((?:[^<>()\[\]\\.,;:\s@"]+(?:\.[^<>()\[\]\\.,;:\s@"]+)*)|(?:".+"))@(?:(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|((?:[a-zA-Z\-0-9]+\.)+([a-zA-Z]{2,})))$/;

const listenerCallback = (e) => {
  const { attributeid } = e.detail;
  // We want to give the user a chance to set email, so wait until email is loaded
  const store = getStore();
  let hasSeenEmail = store.getState().formMeta.hasSeenEmail;
  if (!hasSeenEmail) {
    if (attributeid === 'EmailAddress') {
      store.dispatch(actions.change('formMeta.hasSeenEmail', true));
    }
    return;
  }
  let emailAddress;
  let hasCalledInitAuth;
  if (store) {
    emailAddress = store.getState().formData.EmailAddress;
    hasCalledInitAuth = store.getState().formMeta.hasCalledInitAuth;
  }
  if (emailAddress && !hasCalledInitAuth) {
    if (emailRegex.test(emailAddress)) {
      const reqUrl =
        config.AUTH_INIT_URL + `?email=${encodeURIComponent(emailAddress)}`;
      store.dispatch(actions.change('formMeta.hasCalledInitAuth', true));
      getFetchFunction()(reqUrl)
        .then(() => {
          store.dispatch(
            actions.change('formMeta.receivedOkFromInitAuth', true)
          );
        })
        .catch(() => {
          // Any error handling we want to do?
          store.dispatch(
            actions.change('formMeta.receivedOkFromInitAuth', false)
          );
        });
    }
  }
};

export const addAuthInitListener = () =>
  window.addEventListener('ltPhoenixStepLoaded', listenerCallback);
export const removeAuthInitListener = () =>
  window.removeEventListener('ltPhoenixStepLoaded', listenerCallback);
