import React from 'react';
import SignUpComponent from './webComponents/ltSignUp/signUpComponent';
import componentElement from './componentElement';

export default function getComponent(
  componentConfig,
  handleSuccess,
  handleFailure
) {
  if (!componentConfig) return;
  switch (componentConfig.name) {
    case 'lt-signup-internal-sd-off':
      return React.createElement(SignUpComponent, {
        componentConfig,
        handleSuccess,
        handleFailure,
      });
    default:
      // PHX-5917 :: TECH-DEBT :: Zach James
      return componentElement(componentConfig);
  }
}
