import Queue from 'yocto-queue';
import { sendEventToKafka } from './kafkaService';

const queue = new Queue();
let isLoaded = false;

// Run enqueued events through attributed analytics method
const processQueue = () => {
  if (analyticsIsLoaded() && queue.size) {
    let e = queue.dequeue();
    while (e) {
      const { eventName, trackProperties, options, method } = e;
      window.ltanalytics[method](eventName, trackProperties, options);
      e = queue.dequeue();
    }
  }
};

export const analyticsIsLoaded = () => {
  isLoaded =
    isLoaded ||
    (window.ltanalytics && typeof window.ltanalytics.ready === 'function');

  return isLoaded;
};

export const getMappedTrackProperties = () => {
  if (isLoaded) {
    let trackProperties = window.ltanalytics.getTrackProperties();
    if (trackProperties.MTAIDSource) {
      // Relevant DWA-2120 code: https://gitlab.lendingtree.com/lendingtree/web-analytics/LT-AnalyticsBootstrap/-/blob/master/src/includes/integrations/lta-ash-data.ts#L70
      switch (trackProperties.MTAIDSource) {
        case 'Cookie':
          trackProperties.PhoenixMTAIDSource = 'MKT_TRACK';
          break;
        case 'API':
          // According to Puspendu, this should only be the case on a first load
          // Subsequent loads should read from the cookie
          trackProperties.PhoenixMTAIDSource = 'NGINX';
          break;
        default:
          trackProperties.PhoenixMTAIDSource = trackProperties.MTAIDSource;
          break;
      }
      delete trackProperties.MTAIDSource;
    }
    return trackProperties;
  }
};

// Do we want a max debounce time?  Worst case scenario is analytics was blocked and
// this will continue incrementing by 10% until the user moves away from the page.
let debounceTime = 2000;
const checkForAnalytics = () => {
  debounceTime *= 1.1;
  if (!analyticsIsLoaded()) {
    setTimeout(checkForAnalytics, debounceTime);
  } else {
    isLoaded = true;
    processQueue();
  }
};

setTimeout(checkForAnalytics, debounceTime);

export default function ltAnalytics(
  eventName,
  trackProperties,
  options,
  method = 'track'
) {
  if (method === 'page') eventName = 'Page View';
  trackProperties = Object.assign(trackProperties, getMappedTrackProperties());
  if (eventName === 'Form Start' && trackProperties?.insideFlow) {
    trackProperties['InsideFlowIndicator'] = true;
  }
  sendEventToKafka(eventName, trackProperties, options);

  if (analyticsIsLoaded()) {
    // Check for enqueued events and purge
    if (queue.size) processQueue();
    window.ltanalytics[method](eventName, trackProperties, options);
  } else {
    queue.enqueue({ eventName, trackProperties, options, method });
  }
}
