import * as types from '../actions/formActionTypes';

export default function formStateReducer(
  state = {
    formStatus: types.FORM_DEFAULT,
    formStep: [],
    augmentedValues: [],
    warnings: {},
  },
  action
) {
  switch (action.type) {
    case types.FORM_LOADING:
      return Object.assign({}, state, { formStatus: types.FORM_LOADING });
    case types.STEP_LOADED:
      return Object.assign({}, state, { formStatus: types.STEP_LOADED });
    case types.FORM_SUBMITTING:
      return Object.assign({}, state, {
        formStatus: types.FORM_SUBMITTING,
        displayText: action.loadingMessage,
      });
    case types.FORM_AUTO_SUBMIT_FAIL:
      return Object.assign({}, state, {
        formStatus: types.FORM_AUTO_SUBMIT_FAIL,
      });
    case types.FORM_VALIDATING:
      return Object.assign({}, state, { formStatus: types.FORM_VALIDATING });
    case types.FORM_VALIDATED:
      return Object.assign({}, state, { formStatus: types.FORM_VALIDATING });
    case types.FORM_SUBMIT_FAILURE:
      return Object.assign({}, state, { formStatus: types.FORM_VALIDATED });
    case types.FORM_PROCESSING:
      return Object.assign({}, state, { formStatus: types.FORM_PROCESSING });
    case types.FORM_PROCESSED:
      return Object.assign({}, state, { formStatus: types.FORM_PROCESSED });
    case types.FORM_BUSY:
      return Object.assign({}, state, { formStatus: types.FORM_BUSY });
    case types.FORM_SHOW_EXPLAIN_SIDEBAR: {
      return Object.assign({}, state, {
        formStatus: types.FORM_SHOW_EXPLAIN_SIDEBAR,
      });
    }
    case types.FORM_STATE_UPDATE: {
      return {
        ...state,
        ...action.formState,
        formStep: {
          ...state.formStep,
          ...action.formState.formStep,
        },
      };
    }
    case types.FORM_DEFAULT: {
      return Object.assign({}, state, { formStatus: types.FORM_DEFAULT });
    }
    case types.FORM_SET_WARNING: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
}
