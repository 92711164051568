import React from 'react';
import PropTypes from 'prop-types';
import mobiscroll from '../../../lib/mobiscroll/js/mobiscroll.react.min';
import '../../../lib/mobiscroll/css/mobiscroll.react.min.css';
import { reportFieldEngagement } from '../../../bootstrap';

class ScrollDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastReportedValue: '',
    };
    if (!this.props.value) {
      // If not showing the day wheel, default to the 1st of June. Else default to the middle of the wheel, the 15th
      this.props.controlFormat == 'monthandyear'
        ? this.props.onupdate('06/01/' + (new Date().getFullYear() - 35))
        : this.props.onupdate('06/15/' + (new Date().getFullYear() - 35));
    }
  }

  getYears = () => {
    const today = new Date();
    const delta = this.props.attributeid == 'DOB' ? 18 : 0;
    const lowerBound = today.getFullYear() - delta;
    const upperBound = lowerBound - (99 - delta);
    const month = today.getMonth();
    const day = today.getDate();
    return { lowerBound, upperBound, month, day };
  };

  updateValue = (event) => {
    // Zach James :: PHX-5081 :: TODO: changeAction code here
    // Depending on how much it changes...
    if (event.valueText && event.valueText !== this.state.lastReportedValue) {
      reportFieldEngagement(this.props.attributeid, event.valueText);
    }
    let inputDate = event.valueText;
    if (this.props.controlFormat == 'monthandyear') {
      // If not showing the day wheel, default to the 1st of the chosen month
      this.props.onupdate(inputDate.substr(0, 3) + '01' + inputDate.substr(5));
    } else {
      this.props.onupdate(inputDate);
    }
  };

  render() {
    const dateBoundaries = this.getYears();
    return (
      <div
        className={
          this.props.controlFormat == 'monthandyear'
            ? 'ltFormControlMonthYearScroll'
            : 'ltFormControlFullDateScroll'
        }
      >
        <mobiscroll.Date
          theme="ios"
          type="hidden"
          display="inline"
          layout="liquid"
          defaultValue={this.props.value}
          onSet={this.updateValue}
          dateWheels={
            this.props.controlFormat == 'monthandyear' ? 'MM yy' : null
          }
          min={new Date(dateBoundaries.upperBound, 0, 1)}
          max={
            new Date(
              dateBoundaries.lowerBound,
              dateBoundaries.month,
              dateBoundaries.day
            )
          }
          height={58}
        />
      </div>
    );
  }
}

ScrollDate.propTypes = {
  value: PropTypes.string,
  onupdate: PropTypes.func,
  controlFormat: PropTypes.string,
  attributeid: PropTypes.string,
};

export default ScrollDate;
