/* eslint-disable */
import React from 'react';
import { Errors, Control, actions } from 'react-redux-form';
import Subtext from '../subText';
import {
  runActionsAfterStep,
  updateMultipleAttributes,
  updateMultipleAttributesMap,
  changeFormStepProps,
} from '../../../actions/formActions';
import {
  getValidationMessages,
  getValidations,
} from '../../../validators/validators';
import { connect } from 'react-redux';
import AutoComplete from 'react-autosuggest';
import PropTypes from 'prop-types';
import { transform } from '../../../utils/stepFunctions';
import { Helmet } from 'react-helmet';
import config from '../../../reducers/config';
import { specialCharacterParser } from '../../../utils/parsers';
import { titleCase } from '../../../utils/titleCase';
import { getStore } from '../../../store';
import { reportFieldEngagement } from '../../../bootstrap';

const errorComponentClassName = 'ltErrorText';
const getMinimumCharactersRequired = (step) => {
  const afterAction = (step.actionsafterchange || []).find(
    (action) => action.autosuggest === true || action.autosuggest === 'true'
  );

  if (afterAction != null && afterAction.minimumCharactersRequired != null) {
    const minRequired = afterAction
      ? Number(afterAction.minimumCharactersRequired)
      : 0;

    return isNaN(minRequired) ? 0 : minRequired;
  }

  return 0;
};

class ComboBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      multiAttributeMod: undefined,
      googleMapApiStatus: false,
      lastReportedValue: '',
    };
    this.onSuggestionsFetchRequested =
      this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested =
      this.onSuggestionsClearRequested.bind(this);
    this.onRenderSuggestions = this.onRenderSuggestions.bind(this);
  }

  componentDidMount() {
    this.checkPrepopulatedDataValidity();
  }
  checkPrepopulatedDataValidity = async () => {
    const formData =
      getStore().getState().forms.formData[this.props.step.attributeid];
    if (
      this.controlRef.props.value &&
      formData.validity &&
      formData.validity.autoSuggestValidation === false
    ) {
      await this.onSuggestionsFetchRequested(this.controlRef.props);
      this.controlRef.props.onChange(this.controlRef.props.value);
    }
  };
  isGoogleApi = (actionsafterchange) => {
    return (
      actionsafterchange &&
      actionsafterchange.length > 0 &&
      actionsafterchange.find(
        (actionsafter) =>
          actionsafter.clientProcessId === 'googleStreetSuggestions'
      )
    );
  };

  /**
   * @param { import('../../../@types/process/ProcessChoice').default } [process]
   * @returns {boolean}
   */
  shouldSortList = (process) => {
    return process != null && process.sortList === true;
  };

  onSuggestionsFetchRequested = async ({ value }) => {
    let _items = [];
    let { step } = this.props;

    let modFunc = step.actionsafterchange.find(
      (item) => item.targetProperty === 'enumeration'
    );
    if (modFunc !== undefined) {
      this.setState({ multiAttributeMod: modFunc });
      let stepWithEnumeration = Object.assign({}, step, {
        actionsafterchange: [modFunc],
      });
      await this.props.dispatch(runActionsAfterStep(null, stepWithEnumeration));
    }

    if (
      this.props.step.enumeration &&
      this.props.step.enumeration.length > 0 &&
      this.isGoogleApi(step.actionsafterchange)
    ) {
      await this.props.dispatch(
        changeFormStepProps({
          targetProp: 'enumeration',
          targetValue: [...this.props.step.enumeration, { logo: true }],
          targetStepId: this.props.step.name,
        })
      ); //Logo space
    }

    if (step.actionsafterchange && step.actionsafterchange.length) {
      _items = this.props.step.enumeration;
    } else {
      _items = this.props.step.enumeration.filter((choice) => {
        return (
          choice.label.toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
        );
      });
    }

    // Sort the list alphabetically if the option exists on
    // the ActionBeforeRender ProcessChoice.
    if (this.shouldSortList(step.actionbeforerender)) {
      // Clone the array before sort to avoid mutating the original.
      _items = [..._items].sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );
    }

    this.setState({
      suggestions: !step.noitemsmatchmsg
        ? _items
        : _items.length
        ? _items
        : [{ label: step.noitemsmatchmsg, value: '' }],
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onRenderSuggestions = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        {children}
        <div>
          <Control.text
            model={`formData.${this.props.step.name}`}
            component={(props) => (
              <Subtext
                forceShow={
                  this.props.step.persistHelpText &&
                  props.fieldValue.submitFailed &&
                  !children
                    ? false
                    : true
                }
                text={this.props.step.help}
              >
                {props}
              </Subtext>
            )}
            mapProps={{
              fieldValue: (props) => props.fieldValue,
            }}
          />
        </div>
      </div>
    );
  };

  escapeRegExp = (query) => {
    return query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      let fieldValue = e.target.value;
      if (fieldValue !== this.state.lastReportedValue) {
        reportFieldEngagement(this.props.step.attributeid, fieldValue);
        this.setState({ lastReportedValue: fieldValue });
      }
    }
    specialCharacterParser(e);
  };

  handleOnBlur = (e, { highlightedSuggestion }) => {
    let fieldValue = e.target.value;
    if (
      fieldValue !== this.state.lastReportedValue &&
      highlightedSuggestion !== null &&
      highlightedSuggestion !== undefined &&
      !this.props.step.disableautoselectfirstoption
    ) {
      reportFieldEngagement(this.props.step.attributeid, fieldValue);
      this.setState({ lastReportedValue: highlightedSuggestion.value });
    }
    if (
      highlightedSuggestion !== null &&
      highlightedSuggestion !== undefined &&
      !this.props.step.disableautoselectfirstoption
    ) {
      this.controlRef.props.onChange(highlightedSuggestion.value);
    } else {
      if (
        this.state.suggestions.length !== 0 &&
        this.state.suggestions.length !== undefined &&
        !this.props.step.disableautoselectfirstoption
      ) {
        if (this.state.suggestions[0].value !== null && fieldValue.length < 5) {
          this.controlRef.props.onChange(this.state.suggestions[0].value);
        }
      }
    }
  };

  render() {
    const { suggestions, multiAttributeMod } = this.state;
    const { step, autoCompleteText, autoFocus } = this.props;

    let googleScript;

    if (step.actionsafterchange && step.actionsafterchange.length > 0) {
      let scripts = Array.from(document.getElementsByTagName('script'));

      let googleMapScript = scripts.find((i) =>
        i.src.includes('https://maps.googleapis.com/maps-api')
      );

      if (this.isGoogleApi(step.actionsafterchange) && !googleMapScript) {
        googleScript = (
          <Helmet>
            <script
              src={`${config.apiConfig.googleMapsAPIScriptPath}?libraries=places&key=${config.apiConfig.googleMapsAPIKey}`}
            />
          </Helmet>
        );
      }
    }
    return (
      <div className={`ltFormGroupContent${titleCase(step.nodetype, 0)}`}>
        {googleScript}

        <Control
          id={`formData.${step.attributeid}`}
          model={`formData.${step.attributeid}`}
          getRef={(node) => (this.controlRef = node)}
          component={AutoComplete}
          autoComplete={autoCompleteText}
          handleKeyPress={this.handleKeyPress}
          handleOnBlur={this.handleOnBlur}
          mapProps={{
            onSuggestionSelected:
              (props) =>
              (event, { suggestion, suggestionValue }) => {
                if (multiAttributeMod) {
                  if (multiAttributeMod.onselectmap) {
                    this.props.dispatch(
                      updateMultipleAttributesMap(
                        multiAttributeMod.onselectmap,
                        suggestion
                      )
                    );
                  } else if (multiAttributeMod.actionafterchangetext) {
                    this.props.dispatch(
                      updateMultipleAttributes(
                        multiAttributeMod.actionafterchangetext,
                        suggestion.value
                      )
                    );
                  }
                  let modFuncs = step.actionsafterchange.filter(
                    (item) => item.targetProperty !== 'enumeration'
                  );
                  if (modFuncs && modFuncs.length > 0) {
                    let stepWithOutEnumeration = Object.assign({}, step, {
                      actionsafterchange: modFuncs,
                    });
                    let skipValidation = true;
                    this.props.dispatch(
                      runActionsAfterStep(
                        null,
                        stepWithOutEnumeration,
                        null,
                        skipValidation
                      )
                    );
                  }
                }
                const { actionbeforerender } = step;
                if (actionbeforerender && actionbeforerender.onselectmap) {
                  this.props.dispatch(
                    updateMultipleAttributesMap(
                      actionbeforerender.onselectmap,
                      suggestion
                    )
                  );
                }
                return props.onChange(suggestionValue);
              },
            inputProps: function (props) {
              return {
                onChange: (event, { newValue }) => props.onChange(newValue),
                onKeyPress: (e) => props.handleKeyPress(e),
                onBlur: (event, field) => props.handleOnBlur(event, field),
                autoComplete: props.autoComplete ? props.autoComplete : 'off',
                value: props.viewValue || '',
                placeholder: step.placeholder,
                autoFocus: window.innerWidth >= 768 && autoFocus,
                id: props.id,
                name: step.name,
                readOnly: step.isFieldReadonly === true,
                className:
                  props.fieldValue.submitFailed === false ||
                  props.fieldValue.valid === true ||
                  props.fieldValue.pristine === true
                    ? 'ltFormControl'
                    : 'ltFormControl ltHasError',
              };
            },
          }}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          renderSuggestionsContainer={this.onRenderSuggestions}
          validators={{
            ...getValidations(
              step.validation,
              step.formatter,
              step.enumeration
            ),
          }}
          getSuggestionValue={(suggestion) => {
            return suggestion.value;
          }}
          shouldRenderSuggestions={(value) => {
            if (step.showitemsonfocus) return true;

            const minRequired = getMinimumCharactersRequired(step);

            if (
              !value ||
              value.trim().length == 0 ||
              !this.controlRef ||
              !this.controlRef.input ||
              value.trim().length < minRequired
            ) {
              return false;
            }
            if (
              this.controlRef.input.selectionEnd -
                this.controlRef.input.selectionStart ===
              value.length
            ) {
              //Hack to not render suggestions on step with multiple field with tab
              return false;
            }
            return true;
          }}
          suggestions={suggestions}
          changeAction={(model, value) => (dispatch, getState) => {
            if (step.actionsafterchange.length === 0) {
              let enums = getState().formDefinition.stepSet.find(
                (item) => item.attributeid === model.split('.')[1]
              ).enumeration;
              let modify = enums.find(
                (item) =>
                  item.value == value && item.actionAttribute !== undefined
              );
              if (modify !== undefined) {
                let currVal = getState().formData[modify.actionAttribute];
                model = `formData.${modify.actionAttribute}`;
                value =
                  modify.action != undefined
                    ? transform(
                        modify.action,
                        getState().formData[modify.actionAttribute],
                        modify.actionValue
                      ).toString()
                    : currVal;
              }
            }
            dispatch(actions.change(model, value));
          }}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          highlightFirstSuggestion={true}
          renderSuggestion={(item, options) => {
            if (item.logo) {
              if (
                this.controlRef.suggestionsContainer.firstChild.className ===
                'ltFormComboBoxList'
              ) {
                this.controlRef.suggestionsContainer.firstChild.lastChild.className =
                  'ltFormComboBoxListItem google-logo';
              }
              return <span className="autosuggest-logo" />;
            }
            if (!item.dunsNumber) {
              let re = new RegExp(
                '(' + this.escapeRegExp(options.query) + ')',
                'gi'
              );
              let parts = item.label.split(re);
              for (let i = 1; i < parts.length; i += 2) {
                parts[i] = <strong key={i}>{parts[i]}</strong>;
              }
              return <span>{parts}</span>;
            } else {
              return <span dangerouslySetInnerHTML={{ __html: item.label }} />;
            }
          }}
          theme={{
            container: 'ltFormComboBox',
            containerOpen: 'ltFormComboBoxOpen',
            input: 'react-autosuggest__input',
            inputOpen: 'react-autosuggest__input--open',
            inputFocused: 'react-autosuggest__input--focused',
            suggestionsContainer: '',
            suggestionsContainerOpen: 'ltFormComboBoxContainerOpen',
            suggestionsList: 'ltFormComboBoxList',
            suggestion: 'ltFormComboBoxListItem',
            suggestionFirst: 'react-autosuggest__suggestion--first',
            suggestionHighlighted: 'ltFormComboBoxListHighlight',
            sectionContainer: 'react-autosuggest__section-container',
            sectionContainerFirst:
              'react-autosuggest__section-container--first',
            sectionTitle: 'react-autosuggest__section-title',
          }}
        />

        <Errors
          component={(props) => (
            <div
              className={`ltHasError ${step.attributeid} ${errorComponentClassName}`}
            >
              {props.children}
            </div>
          )}
          wrapper="div"
          show={{ submitFailed: true }}
          model={`formData.${step.attributeid}`}
          messages={getValidationMessages(step.validation)}
        />
        {/*<Control.text model={`formData.${step.name}`}*/}
        {/*component={(props) => <Subtext text={step.help}>{props}</Subtext>}*/}
        {/*mapProps={{*/}
        {/*fieldValue: (props) => props.fieldValue*/}
        {/*}}*/}
        {/*/>*/}
      </div>
    );
  }
}

ComboBox.propTypes = {
  steps: PropTypes.array,
  step: PropTypes.object,
  handleNext: PropTypes.func,
  dispatch: PropTypes.func,
  autoCompleteText: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default connect()(ComboBox);
