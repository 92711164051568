import React from 'react';
import { Errors, Control, actions } from 'react-redux-form';
import PropTypes from 'prop-types';
import { reportFieldEngagement } from '../../../bootstrap';

/*eslint-disable react/no-multi-comp,react/no-danger*/

const errorComponentClassName = 'ltErrorText';
const selectedWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.children;
  delete inputProps.submitted;
  return (
    <label tabIndex="0" className={props.checked ? 'selected' : null}>
      <input {...inputProps} />
      {props.children}
    </label>
  );
};

selectedWrapper.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.array,
};

const ConfirmationCheckbox = ({ step }) => {
  step.attributeid = 'confirmInfo';
  return (
    <div className={`ltFormGroupContentConfirmationCheckBox`}>
      <div
        className={`ltFormCheckBoxControl ltFormCheckBoxControlConfirmation ${step.attributeid}`}
      >
        <Control.checkbox
          component={selectedWrapper}
          validators={{
            required: (val) => val,
          }}
          id={`formData.${step.attributeid}`}
          model={`formData.${step.attributeid}`}
          defaultChecked={false}
          //checked={(props) => props.checked}
          mapProps={{
            value: (props) => {
              return props.modelValue;
            },
            className: ({ fieldValue }) =>
              !fieldValue.valid && !fieldValue.pristine && fieldValue.touched
                ? 'ltHasError'
                : '',
          }}
          onClick={(e) => {
            reportFieldEngagement(step.attributeid, e.currentTarget.checked);
          }}
          changeAction={(model, value) => (dispatch) => {
            dispatch(actions.change(model, value));
            dispatch(
              actions.change(
                `formData.extra.${step.attributeid}`,
                `${step.attributeid}`
              )
            );
          }}
        >
          <i className={`ltFormCheckBoxIcon ${step.attributeid}`} />
          <div dangerouslySetInnerHTML={{ __html: step.confirmationlabel }} />
        </Control.checkbox>
      </div>
      <Errors
        component={(props) => (
          <div
            className={`ltHasError ${step.attributeid} ${errorComponentClassName}`}
          >
            {props.children}
          </div>
        )}
        wrapper="div"
        show={(field) => field.touched && !field.pristine && !field.validating}
        model={`formData.${step.attributeid}`}
        messages={{ required: 'Please confirm your information.' }}
      />
    </div>
  );
};
/*eslint-enable */
ConfirmationCheckbox.propTypes = {
  steps: PropTypes.array,
  step: PropTypes.object,
  checked: PropTypes.bool,
  children: PropTypes.array,
  modelValue: PropTypes.bool,
};

export default ConfirmationCheckbox;
