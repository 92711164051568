import React from 'react';
import PropTypes from 'prop-types';
import './softOffer.css';
import {connect} from "react-redux";
import {getFormData} from "../../../selectors/formSelector";
import Product from "./product";
import {actions} from "react-redux-form";
import SoftOfferDetails from "./softOfferDetails";

class ProductSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  /**
   * Sets ProductSelection variable to product parameter.  Generally this result is passed to a branch node.
   * Then calls parent handleNext() function.
   * @param product
   */
  chooseBranch(product) {
    this.props.dispatch(actions.change(`formData.ProductSelection`, product));
    this.props.handleNext();
  }

  /**
   * Sets ProductSelection variable to unlock constant.  Generally this result is passed to a branch node.
   * Then calls parent handleNext() function.
   */
  unlockLenders() {
    this.props.dispatch(actions.change(`formData.ProductSelection`, 'unlock'));
    this.props.handleNext();
  }

  /**
   * Each product can have either "accepted", "declined" or "no response" state.
   * @param product
   * @returns {string}
   */
  getProductOfferStatus(product) {
    // There is an accepted soft offer in the step set, return "Accepted" status
    if (this.props.step.acceptedSoftOffers && this.props.step.acceptedSoftOffers[product]) {
      return 'accepted';
    }

    // There is a declined soft offer in the step set, return "Accepted" status
    if (this.props.step.declinedSoftOffers && this.props.step.declinedSoftOffers[product]) {
      // Check if Savings were displayed to determine if declined, or no offer was presented
      return this.props.step.declinedSoftOffers[product].EstimatedMonthlySavings > 0 ?
          'declined' :
          'no offer';
    }

    // No response from user yet
    return 'no response';
  }

  /**
   * Orders product selection based on product ask
   * @param {string[]} products
   * @returns {string[]}
   */
  orderSelectedProducts(products) {
    const orderedProducts = [];

    if (products.indexOf("refinance") >= 0) {
      orderedProducts.push("refinance");
    }

    if (products.indexOf("auto") >= 0) {
      orderedProducts.push("auto");
    }

    if (products.indexOf("slrefi") >= 0) {
      orderedProducts.push("slrefi");
    }

    if (products.indexOf("personal") >= 0) {
      orderedProducts.push("personal");
    }

    return orderedProducts;
  }

  /**
   * Either render a soft offer, or an select product node
   */
  render() {
    // An array of products to render
    const products      = [];

    // Toggles whether or not Unlock button is displayed
    let hasSoftOffer = false;

    // Array of selected products, ordered based Product requirements
    const orderedProducts = this.orderSelectedProducts(this.props.step.selectedproducts);

    // Loop through all configured products
    for (let product of orderedProducts) {
      // Get user readable label
      const label = this.props.step.productLabels[product];

      switch (this.getProductOfferStatus(product)) {
        // Show soft offer for any product that has been accepted by customer
        case 'accepted':
          hasSoftOffer = true;
          products.push(<SoftOfferDetails
              Fees                               = {this.props.step.acceptedSoftOffers[product].Fees}
              LoanAmount                         = {this.props.step.acceptedSoftOffers[product].LoanAmount}
              SoftOfferType                      = {product}
              APRPercentage                      = {this.props.step.acceptedSoftOffers[product].APRPercentage}
              LoanTermInMonths                   = {this.props.step.acceptedSoftOffers[product].LoanTermInMonths}
              EstimatedMonthlySavings            = {this.props.step.acceptedSoftOffers[product].EstimatedMonthlySavings}
              PrincipalAndInterestMonthlyPayment = {this.props.step.acceptedSoftOffers[product].PrincipalAndInterestMonthlyPayment}
              label                              = {label}
              key                                = {product}
          />);
          break;
          // Show declined product node if product has been declined by customer.  There is no "handleNext" for this case.
        case 'declined':
          products.push(<Product
              key             = {product}
              label           = {label}
              product         = {product}
              completedResult = {{status: 'declined'}}
          />);
          break;
          // Show no offer product node if no savings were found.  There is no "handleNext" for this case.
        case 'no offer':
          products.push(<Product
              key             = {product}
              label           = {label}
              product         = {product}
              completedResult = {{status: 'no offer'}}
          />);
          break;
        // Show "start product flow" node if product has no response from customer yet
        case 'no response':
          products.push(<Product
              key             = {product}
              label           = {label}
              product         = {product}
              handleNext      = {this.chooseBranch.bind(this)}
              completedResult = {{}}
          />);
          break;
      }
    }

    return (
      <div className="ltFormContainer">
        {
          hasSoftOffer ?
            <div className="ltFormHeader">Lower another bill or unlock lender match</div> :
            <div className="ltFormHeader">Where do you want to start looking for savings?</div>
        }
        <main>
          {products}
          {
            hasSoftOffer &&
            <div className="ltFormGroup">
              <label className="ltFormBtnSubText">Are you done looking for savings?</label>
              <button type="button" className="ltFormButton acceptBtn" onClick={this.unlockLenders.bind(this)}>Unlock Your Lenders</button>
            </div>
          }
        </main>
      </div>
    );
  }
}

ProductSelection.propTypes = {
  // Platform props
  step: PropTypes.object,
  dispatch: PropTypes.func,
  formData: PropTypes.object,
  handleNext: PropTypes.func,

  // Builder props
  selectedproducts: PropTypes.array,
  productLabels: PropTypes.object,

  // Product Selection props
};

// Here be dragons
function mapStateToProps(state) {
  return {
    formData: getFormData(state)
  };
}

export default connect(mapStateToProps)(ProductSelection);