import configureStore from './configureStore';
import { persistStore } from 'redux-persist';

let persistor;

export const getStore = () => window.ReduxStore;

const hasStore = () => getStore() != null;

export const initStore = (formId) => {
  if (hasStore()) {
    return {
      store: getStore(),
      persistor : persistStore(getStore())
    };
  }

  const config = configureStore(formId)();

  window.ReduxStore = config.store;
  persistor = config.persistor;

  return {
    store: getStore(),
    persistor
  };
};
