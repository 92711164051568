import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import Modal from '../../components/modal/Modal';
import PropTypes from 'prop-types';
import * as statusType from '../../actions/formActionTypes';
import {
  formStatusReset,
  resetAutoSubmitStatus,
} from '../../actions/formActions';

const modalStyle = {
  background: 'white',
  border: '2px solid black',
  width: '500px',
  display: 'grid',
  placeContent: 'center',
  padding: '0 3rem 1rem 3rem',
  margin: '3rem auto',
};

const useModalExample = (showModal) => {
  const [isOpen, setIsOpen] = useState(showModal);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  return [isOpen, handleClose];
};

const FormAutoSubmit = (props) => {
  const [isModalOpen, handleModalClose] = useModalExample(props.showModal);
  const {
    formStatus,
    // eslint-disable-next-line no-unused-vars
    displayText,
    missingProperties,
    autoSubmitStatus,
  } = props;
  const hasModalOpened = useRef(false);

  const handleModalShow = useCallback(() => {
    hasModalOpened.current = true;
  }, []);

  useEffect(() => {
    if (hasModalOpened.current === true) {
      if (formStatus === statusType.FORM_SUBMITTING) {
        const timer = setTimeout(() => {
          handleModalClose();
          clearTimeout(timer);
        }, 3000);
      }
      if (
        formStatus === statusType.FORM_AUTO_SUBMIT_FAIL ||
        formStatus === statusType.FORM_SUBMIT_FAILURE
      ) {
        const timer = setTimeout(() => {
          handleModalClose();
          props.dispatch(formStatusReset());
          props.dispatch(resetAutoSubmitStatus());
          clearTimeout(timer);
        }, 3000);
      }
    }
  }, [formStatus]);

  return (
    <React.Fragment>
      <Modal
        show={isModalOpen}
        onHide={handleModalClose}
        onShow={handleModalShow}
        role="modal"
        style={modalStyle}
      >
        <h3>Submitting Request</h3>
        {autoSubmitStatus && formStatus === statusType.FORM_LOADING ? (
          <p className="auto-submit-initializing">Auto Submitting Form.. </p>
        ) : null}
        {!autoSubmitStatus &&
        formStatus === statusType.FORM_AUTO_SUBMIT_FAIL ? (
          <div className="auto-submit-failed">
            <p>
              Form could not be submitted as we need some more information.{' '}
            </p>
            <ul>
              {missingProperties &&
                missingProperties.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
            </ul>
          </div>
        ) : null}
        {autoSubmitStatus && formStatus === statusType.FORM_SUBMITTING ? (
          <div>
            <p className="auto-submit-validated">
              Collecting Information Completed
            </p>
            <p className="auto-submitting">Auto Submitting Form.. </p>
          </div>
        ) : null}
      </Modal>
    </React.Fragment>
  );
};

FormAutoSubmit.propTypes = {
  showModal: PropTypes.bool,
  formStatus: PropTypes.string,
  autoSubmitStatus: PropTypes.bool,
  displayText: PropTypes.string,
  missingProperties: PropTypes.array,
  dispatch: PropTypes.func,
};
export default connect()(FormAutoSubmit);
