
export const greaterThanEqual = (input, bound) =>{
  return input>=parseInt(bound);
};

export const lessThanEqual = (input, bound) =>{
  return input<=parseInt(bound);
};

export const greaterThan = (input, bound) =>{
  return input>parseInt(bound);
};

export const lessThan = (input, bound) =>{
  return input<parseInt(bound);
};

export const equals = (input, bound) =>{
  return input===parseInt(bound);
};
