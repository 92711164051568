import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { getStore } from '../store';
import RedSpotlight from '../assets/spotlight-1.png';
import GreenSpotlight from '../assets/spotlight-2.png';
import config from '../reducers/config';

const INVALID_PROPS_ERR_MSG =
  'Invalid "lat" and/or "lng" props sent to Maps component!';
// const RED_ICON = '../../spotlight-1.png';
// const GREEN_ICON = '../.. /spotlight-2.png';

const hasValidCenterProp = (center) =>
  center != null &&
  center.lat != null &&
  center.lng != null &&
  typeof center.lat === 'number' &&
  typeof center.lng === 'number';
const mapOptions = {
  disableDefaultUI: true,
  gestureHandling: 'none',
  zoomControlOptions: { position: 9 },
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: false,
  scrollwheel: false,
  draggableCursor: 'default',
  panControl: false,
  keyboardShortcuts: false,
  clickableIcons: false,
};

const GoogleMapComponent = ({
  center,
  zoom = 10,
  height = '50vh',
  width = '100%',
  fetchedVehicleDealerList,
}) => {
  if (!hasValidCenterProp(center)) {
    throw new Error(INVALID_PROPS_ERR_MSG);
  }
  const style = {
    width,
    height,
  };

  const [map, setMap] = useState(null);
  const onLoad = useCallback((googleMap) => setMap(googleMap), []);

  const getMarkers = () => {
    let { formData } = getStore().getState();
    return (
      fetchedVehicleDealerList.length > 0 &&
      fetchedVehicleDealerList.map((item) => ({
        lat: parseFloat(item.dealerLatitude),
        lng: parseFloat(item.dealerLongitude),
        dealerName: item.label,
        icon: formData.DealerId === item.value ? GreenSpotlight : RedSpotlight,
      }))
    );
  };

  const getCenter = () => {
    return fetchedVehicleDealerList.length > 0
      ? {
          lat: parseFloat(fetchedVehicleDealerList[0].dealerLatitude),
          lng: parseFloat(
            fetchedVehicleDealerList[fetchedVehicleDealerList.length - 1]
              .dealerLongitude
          ),
        }
      : center;
  };

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      fetchedVehicleDealerList.length > 0 &&
        fetchedVehicleDealerList.map((marker) => {
          bounds.extend(
            new window.google.maps.LatLng(
              parseFloat(marker.dealerLatitude),
              parseFloat(marker.dealerLongitude)
            )
          );
        });
      map.fitBounds(bounds);
    }
  }, [map, fetchedVehicleDealerList]);

  const markers = getMarkers();

  const mapCenter = getCenter();

  return (
    <LoadScript googleMapsApiKey={config.apiConfig.googleMapsAPIKey}>
      <GoogleMap
        onLoad={onLoad}
        mapContainerStyle={style}
        center={mapCenter}
        zoom={zoom}
        options={mapOptions}
      >
        {markers &&
          markers.map((currentMarker, i) => (
            <Marker
              key={i}
              position={{ lat: currentMarker.lat, lng: currentMarker.lng }}
              title={currentMarker.dealerName}
              icon={{ url: currentMarker.icon }}
            />
          ))}
      </GoogleMap>
    </LoadScript>
  );
};

GoogleMapComponent.propTypes = {
  center: PropTypes.object.isRequired,
  zoom: PropTypes.number,
  height: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  fetchedVehicleDealerList: PropTypes.array,
};
export default GoogleMapComponent;
