import config from '../reducers/config';
import { getStore } from '../store';
import { actions } from 'react-redux-form';
import { changeMeta } from '../actions/formActions';
import objectMapper from 'object-mapper';
import * as logtypes from '../common/logtypes';
import { ltLogger, getFetchFunction } from '../bootstrap';

class VerificationApi {
  static process = (param, path, validationerrortext, body) => {
    // Determine whether validation should be a GET or POST and set variables to pass to getFetchFunction()
    let fetchFunctionPath;
    let fetchMethod;
    let contentType;

    if (
      path === config.apiConfig.emailValidatePath ||
      path === config.apiConfig.phoneValidatePath ||
      path === config.apiConfig.zipcodeValidatePath ||
      path === config.apiConfig.servicableFromZipValidationPath ||
      path === config.apiConfig.stateCodeFromZipValidatePath
    ) {
      fetchFunctionPath = `${config.apiConfig.rootApiServerPath}${path}`;
      fetchMethod = 'POST';
      contentType = 'application/json';
    } else {
      fetchFunctionPath = `${config.apiConfig.rootApiServerPath}${path}${param}`;
      fetchMethod = 'GET';
      contentType = 'text/html';
    }

    // fetchFunction options
    let options = {
      credentials: 'include',
      method: fetchMethod,
      headers: {
        'Content-Type': `${contentType}`,
      },
    };

    // if body is passed into process(), use it
    if (body) {
      options.body = JSON.stringify(body);
    }

    return getFetchFunction()(fetchFunctionPath, options)
      .then((response) => {
        if (!response.ok) {
          if (
            response.status >= 500 &&
            path === config.apiConfig.emailValidatePath
          )
            VerificationApi.writeResultsToState({
              'formMeta.emailVerified': 'PHXEmailVerifyDown',
            });
        }
        return response;
      })
      .then(
        (response) =>
          new Promise((resolve) => {
            const contentType = response.headers.get('Content-Type'); // -> "text/html; charset=utf-8"

            if (/text\/html/i.test(contentType)) {
              response
                .text()
                .then((data) => {
                  if (!response.ok) {
                    throw new Error(
                      `Verify ${param} failed with status: ${response.statusText} statusCode: ${response.status} body: ${data}`
                    );
                  }
                })
                .catch(function (error) {
                  let { formMeta, formDefinition } = getStore().getState();

                  let localerr;
                  if (error.stack && /[\n\r]/g.test(error.stack)) {
                    localerr = error;
                    localerr.message = `Verify Error: ${
                      localerr && localerr.message
                        ? localerr.message
                        : 'No error provided'
                    }`;
                  } else {
                    localerr = new Error(
                      `Verify Error: ${
                        error && error.message
                          ? error.message
                          : 'No error provided'
                      }`
                    );
                  }

                  ltLogger(logtypes.ERROR, undefined, {}, undefined, {
                    error: localerr,
                    metaData: Object.assign({}, formMeta, {
                      displayedSteps: JSON.parse(
                        JSON.stringify(formDefinition.stepSet)
                      ),
                    }),
                  });
                  resolve(true);
                });
            } else if (/application\/json/.test(contentType)) {
              response
                .json()
                .then((data) => {
                  if (!response.ok) {
                    throw new Error(
                      `Verify ${param} failed with status: ${
                        response.statusText
                      } statusCode: ${response.status} body: ${JSON.stringify(
                        data
                      )}`
                    );
                  }
                  if (data.saveToState !== undefined)
                    VerificationApi.writeResultsToState(data.saveToState);
                  if (data.errorMessage && validationerrortext) {
                    VerificationApi.setDynamicErrorMessage(
                      data.errorMessage,
                      validationerrortext
                    );
                  }
                  resolve(
                    data.advance || (response.status === 200 && data.validated)
                  );
                })
                .catch(function (error) {
                  let { formMeta, formDefinition } = getStore().getState();
                  error = new Error(
                    `Verification Call: ${path} : ${error.message}`
                  );
                  ltLogger(logtypes.ERROR, undefined, {}, undefined, {
                    error: error,
                    metaData: Object.assign({}, formMeta, {
                      displayedSteps: JSON.parse(
                        JSON.stringify(formDefinition.stepSet)
                      ),
                    }),
                  });
                  resolve(true);
                });
            }
          })
      )
      .catch(function (error) {
        let { formMeta, formDefinition } = getStore().getState();
        error = new Error(`Verification Call: ${path} : ${error.message}`);
        ltLogger(logtypes.ERROR, undefined, {}, undefined, {
          error: error,
          metaData: Object.assign({}, formMeta, {
            displayedSteps: JSON.parse(JSON.stringify(formDefinition.stepSet)),
          }),
        });
        return true;
      });
  };

  static verifyEmail(email) {
    return VerificationApi.process(
      email,
      config.apiConfig.emailValidatePath,
      undefined,
      { emailAddress: email, vertical: window.ltPhoenix.Vertical }
    );
  }

  static verifyPhone(phone) {
    return VerificationApi.process(
      phone,
      config.apiConfig.phoneValidatePath,
      undefined,
      { phoneNumber: phone }
    );
  }

  static verifyZip(zipcode) {
    return VerificationApi.process(
      zipcode,
      config.apiConfig.zipcodeValidatePath,
      undefined,
      { zipCode: zipcode }
    );
  }

  static verifyStateCodeFromZip(zipcode) {
    return VerificationApi.process(
      zipcode,
      config.apiConfig.stateCodeFromZipValidatePath,
      undefined,
      { zipCode: zipcode }
    );
  }

  static verifyServiceabilityFromZip(zipcode) {
    return VerificationApi.process(
      zipcode,
      config.apiConfig.servicableFromZipValidationPath,
      undefined,
      { zipCode: zipcode }
    );
  }

  static verifyEquifaxPasscode(passcode, validationerrortext) {
    return VerificationApi.process(
      passcode,
      config.apiConfig.equifaxPasscodeValidatePath,
      validationerrortext
    );
  }

  static verifyNmlsId(type = 'corporate', nmlsid) {
    return VerificationApi.process(
      type + '/' + nmlsid,
      config.apiConfig.nmlsIdValidatePath,
      'wth'
    );
  }

  static verifyIndirectSupport(zipcode) {
    return VerificationApi.process(
      zipcode,
      config.apiConfig.indirectSupportValidationPath
    );
  }

  static writeResultsToState(stateChanges) {
    for (let [key, value] of Object.entries(stateChanges)) {
      let statePath = key.split('.')[0];
      switch (statePath) {
        case 'formMeta':
          getStore().dispatch(
            changeMeta(objectMapper.setKeyValue({}, key, value))
          );
          break;
        case 'formData':
          getStore().dispatch(actions.change(key, value));
          break;
        default:
          break;
      }
    }
  }

  static setDynamicErrorMessage(errorMessage, attribute) {
    if (attribute[0] == '{' && attribute[attribute.length - 1] == '}') {
      let attributeToLookFor = attribute.replace(/{|}/g, '');
      let { formMeta } = getStore().getState();
      if (formMeta[`${attributeToLookFor}`]) {
        getStore().dispatch(
          changeMeta({ formMeta: { [attributeToLookFor]: errorMessage } })
        );
      } else {
        getStore().dispatch(
          actions.change(`formData.${attributeToLookFor}`, errorMessage)
        );
      }
    }
  }
}

export default VerificationApi;
