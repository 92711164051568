import React from 'react';
import { Errors, Control, actions } from 'react-redux-form';
import { transform } from '../../../utils/stepFunctions';
import Subtext from '../subText';
import PropTypes from 'prop-types';
import { updateMultipleAttributesMap } from '../../../actions/formActions';
import { getFormData } from '../../../selectors/formSelector';
import { connect } from 'react-redux';
/*eslint-disable react/no-multi-comp,react/no-danger*/

const errorComponentClassName = 'ltErrorText';
const selectedWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.children;
  delete inputProps.submitted;
  delete inputProps.touched;
  delete inputProps.modelValue;
  return (
    <label
      htmlFor={props.htmlFor}
      tabIndex="0"
      className={
        props.autoFocus &&
        (props.submitted ||
          (props.modelValue + props.name === props.htmlFor && props.touched))
          ? 'selected'
          : null
      }
    >
      <input {...inputProps} />
      {props.children}
    </label>
  );
};
const controlHasError = ({ valid, retouched, touched, pristine }) =>
  (touched || retouched) && !pristine && !valid;

selectedWrapper.propTypes = {
  htmlFor: PropTypes.string,
  autoFocus: PropTypes.bool,
  submitted: PropTypes.bool,
  children: PropTypes.array,
  modelValue: PropTypes.string,
  touched: PropTypes.bool,
  name: PropTypes.string,
};

class VehicleDealerList extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { fetchedVehicleDealerList } = this.props;
    return (
      <div>
        <div className="vehicleTrimScrollbar">
          {fetchedVehicleDealerList.map((choice) => {
            let stepClasses =
              this.props.parentProps.step.attributeid +
              (choice.classnames ? ` ${choice.classnames}` : '');

            return choice.displayOrder < 0 ? null : (
              <div
                className={`ltFormVehicleDealerListControl ltFormVehicleDealerListControl${choice.value} ${stepClasses}`}
                key={choice.value}
              >
                <div
                  className={`ltFormVehicleDealerListControlInner ${choice.value} ${this.props.parentProps.step.attributeid}`}
                >
                  <Control.radio
                    name={this.props.parentProps.step.attributeid}
                    id={`${choice.value}${this.props.parentProps.step.attributeid}`}
                    component={selectedWrapper}
                    validators={{
                      required: (val) => val,
                    }}
                    model={`formData.${this.props.parentProps.step.attributeid}`}
                    className={`ltFormRadioButton ${choice.value} ${this.props.parentProps.step.attributeid}`}
                    value={choice.value}
                    changeAction={(model, value, event) =>
                      (dispatch, getState) => {
                        if (event && value !== event.currentValue) {
                          return;
                        }
                        if (
                          getState().forms.formData[
                            this.props.parentProps.step.attributeid
                          ] !== undefined &&
                          getState().forms.formData[
                            this.props.parentProps.step.attributeid
                          ].pristine === false &&
                          event === undefined
                        ) {
                          return;
                        }
                        let enums = getState().formDefinition.stepSet.find(
                          (step) => step.attributeid === model.split('.')[1]
                        ).enumeration;
                        const selectedEnum = enums.find(
                          (e) => e.value === value
                        );
                        let modify = enums.find(
                          (item) =>
                            item.value == value &&
                            item.actionAttribute !== undefined
                        );
                        if (modify !== undefined) {
                          let currVal =
                            getState().formData[modify.actionAttribute];
                          model = `formData.${modify.actionAttribute}`;
                          value =
                            modify.action != undefined
                              ? transform(
                                  modify.action,
                                  getState().formData[modify.actionAttribute],
                                  modify.actionValue
                                ).toString()
                              : currVal || value;
                          event.currentValue = value;
                        }
                        if (this.props.parentProps.step.actionsafterchange) {
                          this.props.parentProps.step.actionsafterchange
                            .filter((action) => action.onselectmap)
                            .forEach((action) => {
                              return dispatch(
                                updateMultipleAttributesMap(
                                  action.onselectmap,
                                  selectedEnum
                                )
                              );
                            });
                        }
                        dispatch(actions.change(model, value));
                        if (
                          event &&
                          this.props.parentProps.step.length === 1 &&
                          event.currentValue === value
                        ) {
                          this.props.parentProps.handleNext();
                        }
                      }}
                    mapProps={{
                      autoFocus: (props) => props.modelValue === choice.value,
                      htmlFor: `${choice.value}${this.props.parentProps.step.attributeid}`,
                      submitted: ({ fieldValue }) => fieldValue.submitted,
                      modelValue: (props) => props.modelValue,
                      touched: ({ fieldValue }) => fieldValue.touched,
                      onClick: (props) => () => {
                        if (props.value === props.controlProps.value) {
                          props.dispatch(actions.setTouched(props.model));
                          props.dispatch(
                            props.changeAction(props.model, props.value, {
                              currentValue: props.controlProps.value,
                            })
                          );
                        }
                      },
                    }}
                  >
                    <i
                      className={`ltFormRadioIcon ${this.props.parentProps.step.attributeid}`}
                    />
                    <div className="distanceSection">
                      <div className="spotlight" />
                      <div className="dealerRadius">
                        {Math.round(choice.dealerDistanceInMiles) + ' mi'}
                      </div>
                    </div>
                    <div className="addressSection">
                      <div
                        className="dealerName"
                        dangerouslySetInnerHTML={{ __html: choice.label }}
                      />
                      <div className="dealerAddress">
                        {choice.dealerStreetAddressLine1}
                      </div>
                    </div>
                  </Control.radio>
                </div>
              </div>
            );
          })}
        </div>
        <Control.text
          model={`formData.${this.props.parentProps.step.attributeid}`}
          component={(props) => (
            <Subtext
              text={this.props.parentProps.step.help}
              forceShow={this.props.parentProps.step.persistHelpText}
              classExt={this.props.parentProps.step.attributeid}
            >
              {props}
            </Subtext>
          )}
          mapProps={{
            fieldValue: (props) => props.fieldValue,
          }}
        />
        <Errors
          className={`ltFormHelpBlock ltHasError ${this.props.parentProps.step.attributeid} ${errorComponentClassName}`}
          wrapper="div"
          show={(field) => controlHasError(field)}
          model={`formData.${this.props.parentProps.step.attributeid}`}
          messages={{ required: 'Please select a dealer option.' }}
        />
      </div>
    );
  }
}
/*eslint-enable */
VehicleDealerList.propTypes = {
  handleNext: PropTypes.func,
  fieldValue: PropTypes.object,
  modelValue: PropTypes.object,
  controlProps: PropTypes.object,
  viewValue: PropTypes.string,
  value: PropTypes.string,
  dispatch: PropTypes.func,
  model: PropTypes.object,
  changeAction: PropTypes.func,
  parentProps: PropTypes.object,
  fetchedVehicleDealerList: PropTypes.array,
  formData: PropTypes.object,
};
function mapStateToProps(state) {
  return {
    formData: getFormData(state),
  };
}
export default connect(mapStateToProps)(VehicleDealerList);
