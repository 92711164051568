import React from 'react';
import { Control, actions } from 'react-redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VehicleModelDiv from './vehicleModelDiv';
import {
  getFormData,
  getFormMetaController,
} from '../../../selectors/formSelector';
import FormApi from '../../../api/formApi';
import moment from 'moment';
import * as logtypes from '../../../common/logtypes';
import { ltLogger, reportFieldEngagement } from '../../../bootstrap';
import DataLoader from '../../common/DataLoader';
import storageFactory from '../../../utils/storageFactory';
/*eslint-disable react/no-multi-comp,react/no-danger*/

const storage = storageFactory();

const SelectedWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.children;
  delete inputProps.submitted;
  delete inputProps.touched;
  delete inputProps.modelValue;

  const isSelected =
    props.autoFocus &&
    (props.submitted || (props.modelValue === props.htmlFor && props.touched));

  return (
    <label
      htmlFor={props.htmlFor}
      tabIndex="0"
      className={isSelected ? 'selected' : null}
    >
      <input {...inputProps} />
      {props.children}
    </label>
  );
};

SelectedWrapper.propTypes = {
  htmlFor: PropTypes.number,
  autoFocus: PropTypes.bool,
  submitted: PropTypes.bool,
  children: PropTypes.object,
  modelValue: PropTypes.number,
  touched: PropTypes.bool,
};

class VehicleModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleModels: [],
      vehicleYears: [],
      isDataLoaded: false,
    };
  }

  componentDidMount() {
    if (this.props.formData && this.props.formMeta) {
      let selectedLoanSubType = this.props.formMeta.AutomobileLoanSubType;
      let selectedMakeId = this.props.formData.VehicleMakeId;
      let selectedYear = this.props.formData.VehicleYear || null;
      let appendDefaultVehicleYear = this.props?.step?.appenddefaultvehicleyear ?? false;
      this.fetchVehicleYears(selectedLoanSubType, selectedMakeId, selectedYear, appendDefaultVehicleYear);
    }
  }

  getVehicleCondition = (selectedLoanSubType) => {
    switch (selectedLoanSubType) {
      case 'NewCarPurchase':
        return 'new';
      case 'UsedCarPurchase':
      case 'REFINANCE':
      case 'BUYOUTLEASE':
        return 'used';
      default:
        return null;
    }
  };

  fetchVehicleYears = (selectedLoanSubType, selectedMakeId, selectedYear, appendDefaultVehicleYear) => {
    let currentComponent = this;
    let vehicleCondition = this.getVehicleCondition(selectedLoanSubType);
    let automobileLoanType = this.props?.formData?.AutomobileLoanType;
    const shouldAppendChooseYearOption = appendDefaultVehicleYear && (automobileLoanType === 'REFINANCE' || automobileLoanType === 'BUYOUTLEASE');
    return FormApi.fetchVehicleYears(
      vehicleCondition,
      selectedMakeId,
      storage.getItem('lt_sessionKey')
    )
      .then((response) => {
        if (response && response.response && response.response.error) {
          ltLogger(logtypes.ERROR, undefined, {}, undefined, {
            error: new Error(response.response.error),
          });
          return null;
        }
        let vehicleYears = response != null ? response : [];
        if (shouldAppendChooseYearOption) {
          vehicleYears = ['Choose Year', ...vehicleYears];
        }
        if (vehicleYears != null) {
          let currentYearIndex = vehicleYears.findIndex(
            (item) => item === moment().year()
          );
          let twoYearsBack = vehicleYears.findIndex(
            (item) => item === moment().year() - 2
          );

          if (
            selectedYear === null ||
            (selectedYear && !vehicleYears.includes(selectedYear))
          ) {
            const getLatestVehicleYear = () => {
              return [...vehicleYears].sort((a, b) => b - a)[0];
            };

            if (vehicleCondition === 'new') {
              selectedYear =
                vehicleYears[currentYearIndex] || getLatestVehicleYear();
            } else {
              selectedYear = shouldAppendChooseYearOption
                ? vehicleYears[0]
                : vehicleYears[twoYearsBack] || getLatestVehicleYear();
            }
          }

          this.props.dispatch(
            actions.change('formData.VehicleYear', selectedYear)
          );
          this.props.dispatch(actions.setTouched('formData.VehicleYear'));
        }
        if (this.props.formData.VehicleYear !== undefined) {
          this.fetchVehicleModels(
            selectedMakeId,
            selectedLoanSubType,
            this.props.formData.VehicleYear
          );
        }

        currentComponent.setState({
          vehicleYears:
            vehicleCondition === 'new'
              ? vehicleYears.slice(0, 2)
              : vehicleYears,
        });
      })
      .catch((error) => {
        ltLogger(logtypes.ERROR, undefined, {}, undefined, { error: error });
      });
  };

  fetchVehicleModels = (selectedMakeId, selectedLoanSubType, selectedYear) => {
    let currentComponent = this;
    let vehicleCondition = this.getVehicleCondition(selectedLoanSubType);
    currentComponent.setState({ isDataLoaded: false });

    if (selectedYear === 'Choose Year') {
      currentComponent.setState({
        vehicleModels: [],
        isDataLoaded: true
      });
      return;
    }

    return FormApi.fetchVehicleModels(
      selectedMakeId,
      vehicleCondition,
      selectedYear,
      storage.getItem('lt_sessionKey')
    )
      .then((response) => {
        if (response && response.response && response.response.error) {
          ltLogger(logtypes.ERROR, undefined, {}, undefined, {
            error: new Error(response.response.error),
          });
          currentComponent.setState({ vehicleModels: [], isDataLoaded: true });
          return null;
        }
        let modelCollection = response !== undefined ? response : [];
        currentComponent.setState({
          vehicleModels: modelCollection,
          isDataLoaded: true,
        });
      })
      .catch((error) => {
        currentComponent.setState({ isDataLoaded: true });
        ltLogger(logtypes.ERROR, undefined, {}, undefined, { error: error });
      });
  };

  render() {
    const { isDataLoaded } = this.state;
    return !isDataLoaded ? (
      <DataLoader />
    ) : (
      <div>
        {this.props.formMeta.AutomobileLoanSubType === 'NewCarPurchase' ? (
          <div>
            {this.state.vehicleYears.map((item) => (
              <div
                key={Math.random()}
                className={`ltFormRadioControl ltFormRadioControl${item}`}
              >
                <Control.radio
                  name={item}
                  id={item}
                  model={`formData.VehicleYear`}
                  className={`ltFormRadioButton ${item}`}
                  component={SelectedWrapper}
                  value={item}
                  changeAction={(model, value, event) => (dispatch) => {
                    if (event && value !== event.currentValue) {
                      return;
                    }
                    dispatch(actions.change(model, value));
                    if (
                      event &&
                      this.props.steps.length === 1 &&
                      event.currentValue === value
                    ) {
                      this.fetchVehicleModels(
                        this.props.formData.VehicleMakeId,
                        this.props.formMeta.AutomobileLoanSubType,
                        value
                      );
                    }
                  }}
                  mapProps={{
                    autoFocus: (props) => props.modelValue === item,
                    htmlFor: item,
                    submitted: ({ fieldValue }) => fieldValue.submitted,
                    modelValue: (props) => props.modelValue,
                    touched: ({ fieldValue }) => fieldValue.touched,
                    onClick: (props) => () => {
                      if (props.value === props.controlProps.value) {
                        reportFieldEngagement('VehicleYear', props.value);
                        props.dispatch(actions.setTouched(props.model));
                        props.dispatch(
                          props.changeAction(props.model, props.value, {
                            currentValue: props.controlProps.value,
                          })
                        );
                      }
                    },
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: item }} />
                </Control.radio>
              </div>
            ))}
          </div>
        ) : (
          <div
            className={'ltFormControlSelect'}
          >
            <Control.select
              model={`formData.VehicleYear`}
              className="ltFormControl"
              updateOn={['change']}
              changeAction={(model, value, event) => (dispatch) => {
                reportFieldEngagement('VehicleYear', value);
                dispatch(actions.change(model, parseInt(value, 10)));
                if (event && this.props.steps.length === 1) {
                  this.fetchVehicleModels(
                    this.props.formData.VehicleMakeId,
                    this.props.formMeta.AutomobileLoanSubType,
                    value
                  );
                }
              }}
              mapProps={{
                value: (props) => (props.viewValue ? props.viewValue : ''),
              }}
            >
              {this.state.vehicleYears.map((item) => (
                <option key={Math.random()} value={item}>
                  {item}
                </option>
              ))}
            </Control.select>
          </div>
        )}
        <VehicleModelDiv
          fetchedVehicleModels={this.state.vehicleModels}
          handleNext={this.props.handleNext}
          parentProps={this.props}
        />
      </div>
    );
  }
}

/*eslint-enable */
VehicleModel.propTypes = {
  step: PropTypes.object,
  steps: PropTypes.array,
  handleNext: PropTypes.func,
  fieldValue: PropTypes.object,
  modelValue: PropTypes.object,
  controlProps: PropTypes.object,
  viewValue: PropTypes.string,
  value: PropTypes.string,
  dispatch: PropTypes.func,
  model: PropTypes.object,
  changeAction: PropTypes.func,
  formData: PropTypes.object,
  formMeta: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    formData: getFormData(state),
    formMeta: getFormMetaController(state),
  };
}

export default connect(mapStateToProps)(VehicleModel);
