import config from '../reducers/config';
import objectMapper from 'object-mapper';
import * as logtypes from '../common/logtypes';
import { ltLogger, getFetchFunction } from '../bootstrap';
import {
  analyticsIsLoaded,
  getMappedTrackProperties,
} from '../utils/ltAnalyticsService';
import { getStore } from '../store';
import { buildCorsHeaders, hasAcceptableHostName } from '../utils/url';
import { abortableFetch } from '../utils/aborter';

class FormApi {
  static getStep(formId, data, stackAction, sessionKey) {
    let headers = {
      'content-type': 'application/json; charset=utf-8',
      Accept: 'application/json',
      'phoenix-package': `${phoenixname};${phoenixversion};${
        process.env.BUILD_NUMBER
          ? process.env.BUILD_NUMBER.substring(0, 8)
          : 'none'
      };`,
    };
    if (sessionKey) headers['x-lt-phoenix'] = sessionKey;
    let trackProperties =
      window.ltPhoenixConfig && window.ltPhoenixConfig.clientTrackingData
        ? window.ltPhoenixConfig.clientTrackingData
        : {};
    //trackProperties = { ...getParamsFromUrl(), ...trackProperties };
    if (analyticsIsLoaded()) {
      trackProperties = Object.assign(
        trackProperties,
        getMappedTrackProperties()
      );
    }
    if (window.ltPhoenixConfig && window.ltPhoenixConfig.marketingOverrides) {
      trackProperties = {
        ...trackProperties,
        ...window.ltPhoenixConfig.marketingOverrides,
      };
    }

    const url = `${config.apiConfig.rootApiServerPath}${config.apiConfig.formStepPath}${formId}`;
    const acceptableHostName = hasAcceptableHostName(url);
    return getFetchFunction()(url, {
      method: 'post',
      body: JSON.stringify({
        ...data,
        trackProperties: trackProperties,
        ...{ action: stackAction },
      }),
      credentials: acceptableHostName ? 'include' : 'omit',
      referrerPolicy: 'no-referrer-when-downgrade',
      headers: { ...headers, ...buildCorsHeaders(url) },
    })
      .then(function (response) {
        if (response.status >= 400) {
          ltLogger(logtypes.ERROR, undefined, {}, undefined, {
            error: new Error('GetStep Bad Response From Server'),
            metaData: { formId: formId, statusCode: response.status },
          });
          return null;
        }
        let sessionKey;
        //get session from header and return
        if (response && response.headers) {
          for (const pair of response.headers.entries()) {
            if (pair[0] === 'x-lt-phoenix') sessionKey = pair[1];
          }
        }

        return response.json().then((res) => {
          if (response && response.headers) {
            for (let entry of response.headers.entries()) {
              if (entry[0] === 'lt-phoenix-token') res.utoken = entry[1];
            }
            res.sessionKey = sessionKey;
          }

          // This is a temporary code after test will move this logic at form level
          if (
            res.vertical === 'BUSINESSLOAN' &&
            res.stepSet !== undefined &&
            res.stepSet != null &&
            res.stepSet.length > 0 &&
            res.stepSet[0].name === 'LoanAmount' &&
            res.stepSet[0].rangecontrol !== undefined
          ) {
            const variableIncrementsObject = [
              {
                lowerbound: '0',
                upperbound: '50000',
                increment: '5000',
              },
              {
                lowerbound: '50001',
                upperbound: '100000',
                increment: '10000',
              },
              {
                lowerbound: '100000',
                upperbound: '250000',
                increment: '25000',
              },
              {
                lowerbound: '250000',
                upperbound: '1000000',
                increment: '50000',
              },
            ];

            res.stepSet[0].rangecontrol.variableIncrements =
              variableIncrementsObject;
          }

          return res;
        });
      })
      .catch((err) => {
        let localerr;
        if (err.stack && /[\n\r]/g.test(err.stack)) {
          localerr = err;
          localerr.message = `FormStep Error: ${
            localerr && localerr.message
              ? localerr.message
              : 'No error provided'
          }`;
        } else {
          localerr = new Error(
            `FormStep Error: ${
              err && err.message ? err.message : 'No error provided'
            }`
          );
        }

        ltLogger(logtypes.ERROR, undefined, {}, undefined, {
          error: localerr,
          metaData: { formId: formId },
        });
      });
  }

  static saveForm(data) {
    const url = `${config.apiConfig.rootApiServerPath}${config.apiConfig.formSavePath}`;
    const acceptableHostName = hasAcceptableHostName(url);
    const httpHeaders = {
      'content-type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    };
    return getFetchFunction()(url, {
      method: 'post',
      body: JSON.stringify(data),
      credentials: acceptableHostName ? 'include' : 'omit',
      headers: { ...httpHeaders, ...buildCorsHeaders(url) },
    })
      .then(function (response) {
        if (response.status >= 400) {
          ltLogger(logtypes.ERROR, undefined, {}, undefined, {
            error: new Error('Error Saving Form'),
          });
          return null;
        }
        return response.json();
      })
      .catch((err) =>
        ltLogger(logtypes.ERROR, undefined, {}, undefined, {
          error: new Error(
            `FormSave Error: ${
              err && err.message ? err.message : 'No error provided'
            }`
          ),
        })
      );
  }

  static processmemo = (url, data, postBody, sessionKey) => {
    let input = url.substring(url.lastIndexOf('/') + 1, url.length);
    if (
      (data.processDefinition &&
        data.processDefinition.minimumCharactersRequired &&
        !isNaN(parseInt(data.processDefinition.minimumCharactersRequired)) &&
        (input.length ===
          parseInt(data.processDefinition.minimumCharactersRequired) ||
          input.length >
            parseInt(data.processDefinition.minimumCharactersRequired))) ||
      !data.processDefinition.minimumCharactersRequired
    ) {
      const targetStep = data.processDefinition
        ? data.processDefinition.targetStep
        : '';
      const includeOther = data.processDefinition
        ? data.processDefinition.includeOther === true
        : false;
      const signal = abortableFetch(url, data.processDefinition.isApiAbortable);
      const fullUrl = `${config.apiConfig.rootApiServerPath}${url}`;
      const acceptableHostName = hasAcceptableHostName(fullUrl);
      const httpHeaders = {
        'content-type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        'phoenix-package': `${phoenixname};${phoenixversion};${
          process.env.BUILD_NUMBER
            ? process.env.BUILD_NUMBER.substring(0, 8)
            : 'none'
        };`,
      };
      if (sessionKey) {
        httpHeaders['x-lt-phoenix'] = sessionKey;
      }
      return getFetchFunction()(fullUrl, {
        method: data.processDefinition.httpmethod,
        body:
          data.processDefinition.httpmethod === 'POST'
            ? JSON.stringify(postBody)
            : undefined,
        credentials: acceptableHostName ? 'include' : 'omit',
        headers: { ...httpHeaders, ...buildCorsHeaders(fullUrl) },
        signal,
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 404)
              //invalid and not found
              return response;
            let { formMeta, formDefinition } = getStore().getState();
            url = url
              .split('/')
              .slice(0, url.split('/').length - 1)
              .join('/');
            ltLogger(logtypes.ERROR, undefined, {}, undefined, {
              error: new Error(`url "${url}" Failed running process`),
              metaData: Object.assign({}, formMeta, {
                displayedSteps: JSON.parse(
                  JSON.stringify(formDefinition.stepSet)
                ),
              }),
            });
          }
          return response;
        })
        .then((response) =>
          response.json().then((data) => {
            if (
              data &&
              (targetStep === 'VehicleMake' || targetStep === 'VehicleModel') &&
              includeOther
            ) {
              data.push({
                CorolaryApiId: 'Other',
                DisplayName: 'Other',
                MakeId: 'Other',
                Value: 'Other',
              });
            }
            return response.status === 404 ? undefined : data;
          })
        )
        .catch(function (error) {
          url = url
            .split('/')
            .slice(0, url.split('/').length - 1)
            .join('/');
          let localerr;
          if (error.stack && /[\n\r]/g.test(error.stack)) {
            localerr = error;
            localerr.message = `Process Error: ${url}: ${
              localerr && localerr.message
                ? localerr.message
                : 'No error provided'
            }`;
          } else {
            localerr = new Error(
              `Process Error: ${url}: ${
                error && error.message ? error.message : 'No error provided'
              }`
            );
          }

          ltLogger(logtypes.ERROR, undefined, {}, undefined, {
            error: localerr,
          });
          return undefined;
        });
    } else {
      return Promise.resolve(null);
    }
  };

  static process(data, sessionKey) {
    let url = data.processDefinition.urlpath;
    let postBody = {};

    if (data.processDefinition.httpmethod === 'POST') {
      data.processDefinition.requestMap.forEach((param) => {
        let dataObject = objectMapper.getKeyValue(data, param.attributename);

        if (
          dataObject !== null &&
          typeof dataObject === 'object' &&
          !Array.isArray(dataObject)
        ) {
          postBody = { ...postBody, ...dataObject };
        } else {
          postBody[param.paramname] = objectMapper.getKeyValue(
            data,
            param.attributename
          );
        }
      });
    } else {
      const isTokenized = url.indexOf('{') !== -1;
      if (isTokenized) {
        data.processDefinition.requestMap.forEach((param) => {
          url = url.replace(
            `{${param.paramname}}`,
            encodeURIComponent(
              objectMapper.getKeyValue(data, param.attributename)
            )
          );
        });
      } else {
        url = `${url}?`;
        data.processDefinition.requestMap.forEach((param) => {
          url =
            url +
            `${param.paramname}=${objectMapper.getKeyValue(
              data,
              param.attributename
            )}&`;
        });
      }
    }

    return FormApi.processmemo(url, data, postBody, sessionKey);
  }

  static fetchVehicleMakes(selectedLoanType, sessionKey) {
    let headers = {
      'content-type': 'application/json; charset=utf-8',
      Accept: 'application/json',
      'phoenix-package': `${phoenixname};${phoenixversion};${
        process.env.BUILD_NUMBER
          ? process.env.BUILD_NUMBER.substring(0, 8)
          : 'none'
      };`,
    };
    if (sessionKey) headers['x-lt-phoenix'] = sessionKey;
    return getFetchFunction()(
      `${config.apiConfig.rootApiServerPath}/api/enrich/auto/getMakes/${selectedLoanType}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: headers,
      }
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .catch((err) => {
        let localerr;
        if (err.stack && /[\n\r]/g.test(err.stack)) {
          localerr = err;
          localerr.message = `Fetch Vehicle Make Error: ${
            localerr && localerr.message
              ? localerr.message
              : 'No error provided'
          }`;
        } else {
          localerr = new Error(
            `Fetch Vehicle Make Error: ${
              err && err.message ? err.message : 'No error provided'
            }`
          );
        }

        ltLogger(logtypes.ERROR, undefined, {}, undefined, {
          error: localerr,
        });
      });
  }

  static fetchVehicleYears(loanType, makeId, sessionKey) {
    let headers = {
      'content-type': 'application/json; charset=utf-8',
      Accept: 'application/json',
      'phoenix-package': `${phoenixname};${phoenixversion};${
        process.env.BUILD_NUMBER
          ? process.env.BUILD_NUMBER.substring(0, 8)
          : 'none'
      };`,
    };
    if (sessionKey) headers['x-lt-phoenix'] = sessionKey;
    return getFetchFunction()(
      `${config.apiConfig.rootApiServerPath}/api/enrich/auto/getYears/${loanType}/${makeId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: headers,
      }
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .catch((err) => {
        let localerr;
        if (err.stack && /[\n\r]/g.test(err.stack)) {
          localerr = err;
          localerr.message = `Fetch Vehicle GetYears Error: ${
            localerr && localerr.message
              ? localerr.message
              : 'No error provided'
          }`;
        } else {
          localerr = new Error(
            `Fetch Vehicle GetYears Error: ${
              err && err.message ? err.message : 'No error provided'
            }`
          );
        }

        ltLogger(logtypes.ERROR, undefined, {}, undefined, {
          error: localerr,
        });
      });
  }

  static fetchVehicleModels(makeId, loanType, year, sessionKey) {
    let headers = {
      'content-type': 'application/json; charset=utf-8',
      Accept: 'application/json',
      'phoenix-package': `${phoenixname};${phoenixversion};${
        process.env.BUILD_NUMBER
          ? process.env.BUILD_NUMBER.substring(0, 8)
          : 'none'
      };`,
    };
    if (sessionKey) headers['x-lt-phoenix'] = sessionKey;
    return getFetchFunction()(
      `${config.apiConfig.rootApiServerPath}/api/enrich/auto/getModels/${makeId}/${loanType}/${year}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: headers,
      }
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .catch((err) => {
        let localerr;
        if (err.stack && /[\n\r]/g.test(err.stack)) {
          localerr = err;
          localerr.message = `Fetch Vehicle GetModels Error: ${
            localerr && localerr.message
              ? localerr.message
              : 'No error provided'
          }`;
        } else {
          localerr = new Error(
            `Fetch Vehicle GetModels Error: ${
              err && err.message ? err.message : 'No error provided'
            }`
          );
        }

        ltLogger(logtypes.ERROR, undefined, {}, undefined, {
          error: localerr,
        });
      });
  }

  static fetchVehicleTrims(modelId, baseTrimOnly) {
    return getFetchFunction()(
      `${config.apiConfig.rootApiServerPath}/api/enrich/auto/getTrims/${modelId}?baseTrimOnly=${baseTrimOnly}`,
      {}
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .catch((err) => {
        let localerr;
        if (err.stack && /[\n\r]/g.test(err.stack)) {
          localerr = err;
          localerr.message = `Fetch Vehicle GetTrims Error: ${
            localerr && localerr.message
              ? localerr.message
              : 'No error provided'
          }`;
        } else {
          localerr = new Error(
            `Fetch Vehicle GetTrims Error: ${
              err && err.message ? err.message : 'No error provided'
            }`
          );
        }

        ltLogger(logtypes.ERROR, undefined, {}, undefined, {
          error: localerr,
        });
      });
  }

  static translateTrim(chromeStyleId) {
    return getFetchFunction()(
      `${config.apiConfig.rootApiServerPath}/api/enrich/auto/translateTrim/${chromeStyleId}`,
      {}
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .catch((err) => {
        let localerr;
        if (err.stack && /[\n\r]/g.test(err.stack)) {
          localerr = err;
          localerr.message = `TranslateTrim Error: ${
            localerr && localerr.message
              ? localerr.message
              : 'No error provided'
          }`;
        } else {
          localerr = new Error(
            `TranslateTrim Error: ${
              err && err.message ? err.message : 'No error provided'
            }`
          );
        }

        ltLogger(logtypes.ERROR, undefined, {}, undefined, {
          error: localerr,
        });
      });
  }

  static getPrices(trimId) {
    return getFetchFunction()(
      `${config.apiConfig.rootApiServerPath}/api/enrich/auto/getPrices/${trimId}`,
      {}
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .catch((err) => {
        let localerr;
        if (err.stack && /[\n\r]/g.test(err.stack)) {
          localerr = err;
          localerr.message = `GetPrices Error: ${
            localerr && localerr.message
              ? localerr.message
              : 'No error provided'
          }`;
        } else {
          localerr = new Error(
            `GetPrices Error: ${
              err && err.message ? err.message : 'No error provided'
            }`
          );
        }

        ltLogger(logtypes.ERROR, undefined, {}, undefined, {
          error: localerr,
        });
      });
  }

  static updateAbandonedEmail(data) {
    let headers = {
      'content-type': 'application/json; charset=utf-8',
      Accept: 'application/json',
      'phoenix-package': `${phoenixname};${phoenixversion};${
        process.env.BUILD_NUMBER
          ? process.env.BUILD_NUMBER.substring(0, 8)
          : 'none'
      };`,
    };
    const url = `${config.apiConfig.rootApiServerPath}${config.apiConfig.updateAbandonEmail}`;
    const acceptableHostName = hasAcceptableHostName(url);
    return getFetchFunction()(url, {
      method: 'post',
      body: JSON.stringify({ ...data }),
      credentials: acceptableHostName ? 'include' : 'omit',
      headers: { ...headers, ...buildCorsHeaders(url) },
    })
      .then(function (response) {
        if (response.status >= 400) {
          ltLogger(logtypes.ERROR, undefined, {}, undefined, {
            error: new Error('updateAbandonedEmail Bad Response From Server'),
            metaData: { formId: data.formId, statusCode: response.status },
          });
          return null;
        }
      })
      .catch((err) => {
        let localerr;
        if (err.stack && /[\n\r]/g.test(err.stack)) {
          localerr = err;
          localerr.message = `updateAbandonedEmail Error: ${
            localerr && localerr.message
              ? localerr.message
              : 'No error provided'
          }`;
        } else {
          localerr = new Error(
            `updateAbandonedEmail Error: ${
              err && err.message ? err.message : 'No error provided'
            }`
          );
        }
        ltLogger(logtypes.ERROR, undefined, {}, undefined, {
          error: localerr,
          metaData: { formId: data.formId },
        });
      });
  }
}

export default FormApi;
