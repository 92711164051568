import React from 'react';
import { Control, actions } from 'react-redux-form';
import PropTypes from 'prop-types';
import { currencyFormatter } from '../../../utils/formatters';
import { reportFieldEngagement } from '../../../bootstrap';
import GenericError from './genericError';
/*eslint-disable react/no-multi-comp,react/no-danger*/

const SelectedWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.children;
  delete inputProps.submitted;
  delete inputProps.touched;
  delete inputProps.modelValue;

  const hasValue =
    props.autoFocus &&
    (props.submitted || (props.modelValue === props.htmlFor && props.touched));

  let optimizeImageurl = props.imageurl !== null
    ? (props.imageurl + '?background=transparent&size='+ (props.imagesize || 'small'))
    : '';

  return (
    <label
      htmlFor={props.htmlFor}
      tabIndex="0"
      className={hasValue ? 'selected' : null}
    >
      {props.imageurl == null ? (
        <div className="defaultImage" />
      ) : (
        <img src={optimizeImageurl} className="modelContinueIcon" />
      )}
      <input {...inputProps} />
      {props.children}
    </label>
  );
};

SelectedWrapper.propTypes = {
  htmlFor: PropTypes.number,
  autoFocus: PropTypes.bool,
  submitted: PropTypes.bool,
  children: PropTypes.array,
  modelValue: PropTypes.number,
  touched: PropTypes.bool,
  imageurl: PropTypes.string,
  imagesize: PropTypes.string
};

class vehicleModelDiv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesize: props.parentProps.step.imagesize
    };
  }
  render() {
    let { fetchedVehicleModels } = this.props;
    let errorMsg = this.props.parentProps.step.vehiclemodelerrormsg;
    return (
      <div>
        {(fetchedVehicleModels &&
          fetchedVehicleModels.length &&
          fetchedVehicleModels.map((item) => (
            <div
              key={item.model.modelId}
              className={`ltFormVehicleModelControl ltFormVehicleModelControl${
                item.model.modelId
              } ${
                this.props.parentProps.formData.AutomobileLoanType !==
                'NewCarPurchase'
                  ? 'vehicleUsedModel'
                  : ''
              }`}
            >
              <Control.radio
                name={item.model.modelName}
                id={item.model.modelId}
                model={`formData.VehicleModelId`}
                className={`ltFormRadioButton ${item.model.modelName}${
                  window.LT5theme === true ? ' ltFormRadioIcon' : ''
                }`}
                component={SelectedWrapper}
                value={item.model.modelName}
                changeAction={(model, value, event) => (dispatch) => {
                  if (event && value !== event.currentValue) {
                    return;
                  }
                  reportFieldEngagement('VehicleModel', item.model.modelName);
                  dispatch(
                    actions.change(
                      `formData.VehicleModel`,
                      item.model.modelName
                    )
                  );
                  dispatch(actions.change(model, item.model.modelId));
                  this.props.parentProps.handleNext();
                }}
                mapProps={{
                  autoFocus: (props) => props.modelValue === item.model.modelId,
                  htmlFor: item.model.modelId,
                  imageurl: item.imageUrl,
                  submitted: ({ fieldValue }) => fieldValue.submitted,
                  modelValue: (props) => props.modelValue,
                  touched: ({ fieldValue }) => fieldValue.touched,
                  onClick: (props) => () => {
                    if (props.value === props.controlProps.value) {
                      props.dispatch(actions.setTouched(props.model));
                      props.dispatch(
                        props.changeAction(props.model, props.value, {
                          currentValue: props.controlProps.value,
                        })
                      );
                    }
                  },
                  imagesize: this.state.imagesize,
                }}
              >
                <div
                  className="labelText"
                  dangerouslySetInnerHTML={{ __html: item.model.modelName }}
                />
                <div className="labelSubText">
                  {this.props.parentProps.formData.AutomobileLoanType ===
                    'NewCarPurchase' && item.evaluation.msrp !== 0 ? (
                    <div>starting at</div>
                  ) : null}
                </div>
                <div className="msrpText">
                  {this.props.parentProps.formData.AutomobileLoanType ===
                    'NewCarPurchase' && item.evaluation.msrp !== 0 ? (
                    <div>
                      {currencyFormatter(item.evaluation.msrp)}
                      <span className="labelSubText"> MSRP</span>
                    </div>
                  ) : null}
                </div>
              </Control.radio>
            </div>
          ))) ||
          (
            errorMsg && errorMsg !== '' ?
            (
              <div>
              <GenericError
                errorMessage={
                <div className="VehicleModelsNotFound VehicleDataNotFound"
                  dangerouslySetInnerHTML={{ __html: errorMsg }}
                />
                }
              />
              </div>
            )
            : null
            )}
      </div>
    );
  }
}

/*eslint-enable */
vehicleModelDiv.propTypes = {
  handleNext: PropTypes.func,
  fieldValue: PropTypes.object,
  modelValue: PropTypes.object,
  controlProps: PropTypes.object,
  value: PropTypes.string,
  model: PropTypes.object,
  changeAction: PropTypes.func,
  fetchedVehicleModels: PropTypes.array,
  parentProps: PropTypes.object,
};
export default vehicleModelDiv;
