import React from 'react';
import PropTypes from 'prop-types';
const StepSubHeader = ({content, classExt}) => {

  return (
    // eslint-disable-next-line react/no-danger
    <div className={`ltFormQuestionSubHeader ${classExt}`} dangerouslySetInnerHTML={{__html: content}}/>
  );
};

StepSubHeader.propTypes = {
  content: PropTypes.string,
  classExt: PropTypes.string
};

export default StepSubHeader;
