export const PHONE = 'phone';
export const EMAIL = 'email';
export const ZIP = 'zip';
export const STATE_CODE_FROM_ZIP = 'stateCodeFromZip';
export const SERVICEABLE_STATE_FROM_ZIP_CODE = 'serviceableStateFromZipCode';
export const EQUIFAX_PASSCODE = 'equifaxPasscode';
export const CORPORATE_NMLS_ID = 'isCorporateNmlsId';
export const PERSONAL_NMLS_ID = 'isPersonalNmlsId';
export const REQUIRED = 'required';
export const AUTO_SUGGEST_VALIDATION = 'autoSuggestValidation';
export const DATA_VALUE_LENGTH = 'dataValueLength';
export const DATA_MAX_LENGTH = 'maxLength';
export const PRECISION_AND_SCALE = 'precisionAndScale';
export const RANGE_LOWER_BOUND = 'rangeLowerBound';
export const LOWER_BOUND = 'lowerBound';
export const RANGE_UPPER_BOUND = 'rangeUpperBound';
export const DISALLOW_DATES_BEFORE = 'disallowdatesbefore';
export const DISALLOW_DATES_AFTER = 'disallowdatesafter';
export const DISALLOW_SPECIFIC_DATES_BEFORE = 'disallowspecificdatesbefore';
export const DISALLOW_SPECIFIC_DATES_AFTER = 'disallowspecificdatesafter';
export const UPPER_BOUND = 'upperBound';
export const REGEX = 'regEx';
export const DATE = 'date';
export const MIN_NUMBER_OF_UPLOADS = 'minNumberOfUploads';
export const MAX_NUMBER_OF_UPLOADS = 'maxNumberOfUploads';
export const MIN_FILE_SIZE_BYTES = 'minFileSizeBytes';
export const MAX_FILE_SIZE_BYTES = 'maxFileSizeBytes';
export const INLIST = 'inList';
export const VALID_CODE_TYPE = 'validationTypeCode';
export const ASYNC_VERIFICATION = 'verification';
export const ASYNC_VERIFICATIONS = 'verifications';
export const CHECKBOX_MIN_COUNT = 'checkboxmincount';
export const CHECKBOX_MAX_COUNT = 'checkboxmaxcount';
export const INDIRECT_SUPPORT = 'indirectSupport';
