import config from '../reducers/config';

function getHostname(url) {
  const el = document.createElement('a');
  el.href = url;
  return el.hostname;
}

const isSameHost = (url) => getHostname(url) === window.location.hostname;

const acceptableHostNames = [
  `spring.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com`,
  'spring.lendingtree.com',
  'my.lendingtree.com',
  `mylt.local.lendingtree.com`,
  `mylt.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com`,
  `mylt.lendingtree.com`,
  `business.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com`,
  'business.lendingtree.com',
  'myblock.local.lendingtree.com',
  'taxhawk.local.lendingtree.com',
  'express1040.local.lendingtree.com',
  'freetaxusa.local.lendingtree.com',
  'myblock2.local.lendingtree.com',
  'taxhawk2.local.lendingtree.com',
  'express10402.local.lendingtree.com',
  'freetaxusa2.local.lendingtree.com',
  `myblock.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com`,
  `taxhawk.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com`,
  `express1040.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com`,
  `freetaxusa.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com`,
  `myblock2.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com`,
  `taxhawk2.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com`,
  `express10402.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com`,
  `freetaxusa2.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com`,
  'myblock.lendingtree.com',
  'taxhawk.lendingtree.com',
  'express1040.lendingtree.com',
  'freetaxusa.lendingtree.com',
  'myblock2.lendingtree.com',
  'taxhawk2.lendingtree.com',
  'express10402.lendingtree.com',
  'freetaxusa2.lendingtree.com',
];

const acceptableAuthHosts = [
  ...acceptableHostNames,
  `www.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com`,
  `www.lendingtree.com`,
];

export function hasAcceptableHostName(url) {
  return (
    getHostname(url) === window.location.hostname ||
    acceptableHostNames.includes(window.location.hostname)
  );
}

export function isAcceptableAuthHost() {
  return acceptableAuthHosts.includes(window.location.hostname);
}

export function buildCorsHeaders(url) {
  let headers = {};
  // If we're on phoenix domain, no extra headers
  if (isSameHost(url)) return headers;
  // We're not on Phoenix domain, add x-referrer to
  // capture full URL w/ path and query params
  headers['x-referrer'] = window.location.href;
  // Only if we're truly cross domain do we add x-cookie
  if (!hasAcceptableHostName) headers['x-cookie'] = document.cookie;
  return headers;
}

export function createURL(endpoint, hostname = '') {
  hostname =
    !hostname || !acceptableAuthHosts.includes(hostname)
      ? config.apiConfig.rootApiServerPath
      : hostname;

  endpoint = endpoint[0] !== '/' ? `/${endpoint}` : endpoint;

  return `${hostname}${endpoint}`;
}
