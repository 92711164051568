import React from 'react';
import { Errors, Control } from 'react-redux-form';
import Subtext from '../subText';
import * as stackactiontypes from '../../../common/stackactiontypes';
import {
  runActionsAfterStep,
  handleNextSteps,
} from '../../../actions/formActions';
import {
  getValidations,
  getValidationMessages,
} from '../../../validators/validators';
import PropTypes from 'prop-types';

const errorComponentClassName = 'ltErrorText';
const ButtonControl = ({ steps, step, handleNext, disabled }) => {
  return (
    <div>
      <Control.button
        model={`formData.${step.attributeid}`}
        validators={getValidations(step.validation, step.formatter)}
        className={`${step.btnclass} ${disabled ? ' disabled' : ''}`}
        type={step.noactiononcontinue ? 'button' : 'submit'}
        mapProps={{
          onClick: (props) => (e) => {
            if (step.skipsteponclick) {
              props.dispatch(
                handleNextSteps(steps, stackactiontypes.PUSH, true)
              );
            } else {
              props.dispatch(runActionsAfterStep(steps, step));
              if (step.continue) {
                //TODO (skanigiri): Set this based response actions after.
                handleNext();
              }
              e.preventDefault();
            }
          },
        }}
      >
        {' '}
        {step.btntext}
      </Control.button>
      <Errors
        component={(props) => (
          <div
            className={`ltHasError ${step.attributeid} ${errorComponentClassName}`}
          >
            {props.children}
          </div>
        )}
        wrapper="div"
        show={(field) => field.touched && !field.pristine && !field.validating}
        model={`formData.${step.attributeid}`}
        messages={getValidationMessages(step.validation)}
      />
      <Control.text
        model={`formData.${step.attributeid}`}
        component={(props) => (
          <Subtext
            text={step.help}
            forceShow={step.persistHelpText}
            classExt={step.attributeid}
          >
            {props}
          </Subtext>
        )}
        mapProps={{
          fieldValue: (props) => props.fieldValue,
        }}
      />
    </div>
  );
};

ButtonControl.propTypes = {
  steps: PropTypes.array,
  step: PropTypes.object,
  handleNext: PropTypes.func,
  fieldValue: PropTypes.object,
  children: PropTypes.array,
  dispatch: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ButtonControl;
