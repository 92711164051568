export const regex = (input, regex) =>{
  let reg =null;
  let match = regex.match(/.*\/([gimy]*)$/); //Parse regex flags from regular expression
  if(match && match[1]){
    let flags = match[1];
    let regexNoFlags = regex.replace(new RegExp('^(.*?)/'+flags+'$'), '$1');
    reg = new RegExp(regexNoFlags, flags);
  }else{ //No flags
    reg = new RegExp(regex);
  }
  return reg.test(input);
};
