import { conformToMask } from 'react-text-mask';
import * as formatTypes from './formatTypes';
import * as autoCompleteTypes from './autoCompleteTypes';
import * as nodeTypes from '../components/forms/stepTypes';
import * as transformTypes from './transformTypes';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { getFormatter, noopFormatter } from './formatters';
import { getStore } from '../store/index';

export function getCombinedSteps(stepArray, startStepName) {
  let currentSteps = [];
  if (startStepName === undefined) return [stepArray[0].attributeid];
  let startIndex = stepArray.findIndex((item) => item.name === startStepName);
  startIndex = startIndex < 0 ? 0 : startIndex;
  for (const [index, step] of stepArray.entries()) {
    if (index < startIndex) continue;
    if (step.nodetype) currentSteps.push(step.attributeid);
    if (step.addnext === undefined || step.addnext === false) {
      break;
    }
  }
  return currentSteps;
}

export function getStepId(stepName, steps) {
  let stepDef = steps.find((step) => step.name === stepName);
  return steps.length > 0 && stepDef !== undefined
    ? stepDef.attributeid
    : undefined;
}

export function updateStepLabelVisibility(steps) {
  for (const [index, step] of steps.entries()) {
    if (step.shownextlabel && index + 1 <= steps.length - 1) {
      steps[index + 1].showLabel = true;
    }
  }
  return steps;
}

export function hydrateActionSteps(steps, allsteps) {
  let actionData = [];
  for (const [_index, step] of steps.entries()) {
    if (
      step.enumeration !== undefined &&
      step.enumeration.find((item) => item.actionAttribute !== undefined)
    ) {
      let actionDefId = step.enumeration.find(
        (item) => item.actionAttribute !== undefined
      ).actionAttribute;
      let actionStepDef =
        allsteps.find((_step) => _step.attributeid === actionDefId) ||
        steps.find((_step) => _step.attributeid === actionDefId);

      if (actionStepDef !== undefined) {
        actionData.push({
          actionStep: step.attributeid,
          actionAttribute: step.enumeration.find(
            (item) => item.actionAttribute !== undefined
          ).actionAttribute,
          actionFormatter: getFormatter(actionStepDef.formatter),
        });
      }
    }
  }

  if (actionData.length > 0) {
    for (const [_index, step] of steps.entries()) {
      let action = actionData.find(
        (item) => item.actionStep === step.attributeid
      );
      if (action !== undefined) {
        step.actionAttribute = action.actionAttribute;
        step.actionFormatter = action.actionFormatter;
      }
    }
  }
  return steps;
}

export function getControlDefForMultiPart(format, controlDef) {
  let { displayLanguage = 'en' } = getStore().getState().formMeta;

  let dateEnums = getDateEnumerations(format, displayLanguage);
  const dateInitialMap = {
    M: 'months',
    D: 'days',
    Y: 'years',
  };
  const { arrangement = 'MDY' } = controlDef;
  if (controlDef.box1.type === nodeTypes.FORM_SELECT) {
    controlDef.box1.enumeration = dateEnums[dateInitialMap[arrangement[0]]];
  }

  if (controlDef.box2.type === nodeTypes.FORM_SELECT) {
    controlDef.box2.enumeration = dateEnums[dateInitialMap[arrangement[1]]];
  }

  if (controlDef.box3.type === nodeTypes.FORM_SELECT) {
    controlDef.box3.enumeration = dateEnums[dateInitialMap[arrangement[2]]];
  }

  if (format === formatTypes.MONTH_YEAR) {
    controlDef.box2.type = 'text';
    controlDef.box2.style = { display: 'none' };
    controlDef.box2.value = '01';
  }

  return controlDef;
}

export function getDateEnumerations(format, lang) {
  // prettier-ignore
  const monthsByLanguage = {
    en: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
    es: [ 'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre' ],
  };
  // prettier-ignore
  const months = [ '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12' ]
    .map((value, index) => ({ label: monthsByLanguage[lang][index], value }));

  return {
    months,
    days: [
      { label: '1', value: '01' },
      { label: '2', value: '02' },
      { label: '3', value: '03' },
      { label: '4', value: '04' },
      { label: '5', value: '05' },
      { label: '6', value: '06' },
      { label: '7', value: '07' },
      { label: '8', value: '08' },
      { label: '9', value: '09' },
      { label: '10', value: '10' },
      { label: '11', value: '11' },
      { label: '12', value: '12' },
      { label: '13', value: '13' },
      { label: '14', value: '14' },
      { label: '15', value: '15' },
      { label: '16', value: '16' },
      { label: '17', value: '17' },
      { label: '18', value: '18' },
      { label: '19', value: '19' },
      { label: '20', value: '20' },
      { label: '21', value: '21' },
      { label: '22', value: '22' },
      { label: '23', value: '23' },
      { label: '24', value: '24' },
      { label: '25', value: '25' },
      { label: '26', value: '26' },
      { label: '27', value: '27' },
      { label: '28', value: '28' },
      { label: '29', value: '29' },
      { label: '30', value: '30' },
      { label: '31', value: '31' },
    ],
    years: (() => {
      const delta = format === formatTypes.DOB ? 18 : 0;
      const curYear = new Date().getFullYear() - delta;
      let years = [];
      for (let i = curYear; i <= curYear && i >= curYear - (99 - delta); i--) {
        years.push({ label: i, value: i });
      }
      return years;
    })(),
  };
}

export function getDividerForFormat(format) {
  switch (format) {
    case formatTypes.SSN:
    case formatTypes.PHONE:
      return '-';
    case formatTypes.DATE:
    case formatTypes.DOB:
    case formatTypes.MONTH_YEAR:
      return '/';
    default:
      return undefined;
  }
}

export function getJumpRulesForFormat(format) {
  switch (format) {
    case formatTypes.SSN:
      return { box1: 3, box2: 2, box3: 4 };
    case formatTypes.Last4SSN:
      return { box1: 0, box2: 0, box3: 4 };
    case formatTypes.PHONE:
      return { box1: 3, box2: 3, box3: 4 };
    case formatTypes.DOB:
    case formatTypes.DATE:
    case formatTypes.MONTH_YEAR:
      return { box1: 2, box2: 2, box3: 4 };
    default:
      return undefined;
  }
}

export function isActionStep(step) {
  return (
    step.enumeration !== undefined &&
    step.enumeration.find((item) => item.actionAttribute !== undefined)
  );
}

export function getType(format) {
  switch (format) {
    case formatTypes.DOB:
    case formatTypes.DATE:
    case formatTypes.PHONE:
    case formatTypes.NUMBER:
    case formatTypes.CURRENCY:
    case formatTypes.MONTH_YEAR:
    case formatTypes.ZIPCODE:
    case formatTypes.SSN:
      return formatTypes.PHONE;
    case formatTypes.PERCENT:
      return formatTypes.TEXTCHAR;
    case formatTypes.EMAIL:
      return formatTypes.EMAIL;
    case formatTypes.PASSWORD:
    case formatTypes.NEWPASSWORD:
      return formatTypes.PASSWORD;
    default:
      return formatTypes.TEXT;
  }
}

export function getMask(format) {
  switch (format) {
    case formatTypes.DOB:
    case formatTypes.DATE:
      // prettier-ignore
      return [/[0-9*]/, /[0-9*]/, "/", /[0-9*]/, /[0-9*]/, "/", /[0-9*]/, /[0-9*]/, /[0-9*]/, /[0-9*]/];
    case formatTypes.PHONE:
      // prettier-ignore
      return ['(', /[2-9*]/, /[0-9*]/, /[0-9*]/, ')', ' ', /[0-9*]/, /[0-9*]/, /[0-9*]/, '-', /[0-9*]/, /[0-9*]/, /[0-9*]/, /[0-9*]/];
    case formatTypes.SSN:
      // prettier-ignore
      return [/[0-9*]/, /[0-9*]/, /[0-9*]/, "-", /[0-9*]/, /[0-9*]/, "-", /[0-9*]/, /[0-9*]/, /[0-9*]/, /[0-9*]/];
    case formatTypes.PERCENT:
      return createNumberMask({
        prefix: '',
        suffix: '%',
        allowDecimal: true,
        decimalLimit: 4,
        integerLimit: 3,
      });
    case formatTypes.ZIPCODE:
      return [/[0-9*]/, /[0-9*]/, /[0-9*]/, /[0-9*]/, /[0-9*]/];
    default:
      return undefined;
  }
}

export function getAutoFillTag(format) {
  switch (format) {
    case formatTypes.DOB:
      return autoCompleteTypes.DOB;
    case formatTypes.PHONE:
      return autoCompleteTypes.PHONE;
    case formatTypes.EMAIL:
      return autoCompleteTypes.EMAIL;
    case formatTypes.FIRSTNAME:
      return autoCompleteTypes.FIRSTNAME;
    case formatTypes.LASTNAME:
      return autoCompleteTypes.LASTNAME;
    case formatTypes.CITY:
      return autoCompleteTypes.CITY;
    case formatTypes.STATE:
      return autoCompleteTypes.STATE;
    case formatTypes.ZIPCODE:
      return autoCompleteTypes.ZIPCODE;
    case formatTypes.NEWPASSWORD:
      return autoCompleteTypes.NEWPASSWORD;
    default:
      return undefined;
  }
}

export function getPlaceHolderChar(format) {
  switch (format) {
    case formatTypes.DOB:
    case formatTypes.DATE:
      return '_';
    case formatTypes.PHONE:
      return '_';
    case formatTypes.SSN:
      return '_';
    default:
      return '_';
  }
}

export function transform(transform, value, arg) {
  switch (transform) {
    case transformTypes.MULTIPLY:
      return parseInt(value) * parseInt(arg);
    case transformTypes.DIVIDE:
      return parseInt(value) / parseInt(arg);
    case transformTypes.ADD:
      return parseInt(value) + parseInt(arg);
    default:
      return undefined;
  }
}

export function untransform(mod, currValue) {
  switch (mod.action) {
    case transformTypes.MULTIPLY:
      return parseInt(currValue) / parseInt(mod.actionArg);
    case transformTypes.DIVIDE:
      return parseInt(currValue) * parseInt(mod.actionArg);
    case transformTypes.ADD:
      return parseInt(currValue) - parseInt(mod.actionArg);
    default:
      return undefined;
  }
}

export function formatSummaryValue(value, format) {
  let mask = getMask(format);
  let formatHandler = getFormatter(format);

  if (mask) {
    // If a mask was found, use the same mask we use for
    // the MaskedControl component to display in read only
    let { conformedValue } = conformToMask(value, mask, { guide: false });
    if (conformedValue) {
      return conformedValue;
    }
  }

  if (formatHandler && formatHandler !== noopFormatter) {
    // Next, check if we have a pre-defined format, and,
    // that format is not the passthrough noop formatter
    return formatHandler(value);
  }

  // If we don't have a mask or formatter defined, but still
  // need to do some manipulations, we can do them here.
  switch (format) {
    case formatTypes.NUMBER:
      return new Intl.NumberFormat().format(value);
    default:
      return value; // Nothing found, pass through value
  }
}
