import React from 'react';
import PropTypes from 'prop-types';

/*eslint-disable react/no-danger*/
const TokenizedHtml = (props) => {

  let {value} = props.children;

  return (
    (props.text !== undefined) ?
      <div className={props.className} dangerouslySetInnerHTML={{__html: props.text.replace(`{${props.token}}`, props.formatter(value))}}/> :
      null
  );
};
/*eslint-enable */
TokenizedHtml.propTypes = {
  children: PropTypes.object,
  text:PropTypes.string,
  className:PropTypes.string,
  token:PropTypes.string,
  formatter:PropTypes.func
};

export default TokenizedHtml;

