import { getStore } from '../store/index';

const FORMDATA_KEY = 'TuDiCorrelationId';
const hasLoadedTUDigitalIdentityScripts = {
  current: false,
};

const isStateValid = (state) => {
  return (
    state &&
    state.formData &&
    state.formMeta &&
    state.formData[FORMDATA_KEY] == null
  );
};

const init = async () => {
  if (hasLoadedTUDigitalIdentityScripts.current === false) {
    await require('./nsr_handshake.js');
    hasLoadedTUDigitalIdentityScripts.current = true;
  }
  const store = getStore();
  const state = store.getState();
  if (isStateValid(state)) {
    await callNsrHandshake();
  }
};

const onSuccess = (result) => {
  if (result) {
    getStore().dispatch(
      window.formActions.change(
        `formData.extraDataFields.${FORMDATA_KEY}`,
        result
      )
    );
  }
};

const onFail = (error) => {
  console.log(error);
};

const callNsrHandshake = async () => {
  try {
    const result = await new Promise((resolve, reject) => {
      window._nsr_handshake(undefined, resolve, reject);
    });
    onSuccess(result);
  } catch (error) {
    onFail(error);
  }
};

window.initTUDigitalIdentity = Object.freeze(init);
