import React from 'react';
import PropTypes from 'prop-types';
import EditButton from "./editButton";
import ConfirmationCheckbox from "./controls/confirmationCheckbox";

const SummarySteps = ({ step, attributeId, action }) => {
  return (
    <div className={`ltSummaryParent ${attributeId}`}>
      {step.individualSummaries}
      {step.showConfirmationlabel === true && (
        <div className="checkBoxContainer">{<ConfirmationCheckbox step={step}/>}</div>
      )}
      {step.showEditButton && (
        <div className="ltSummaryEditContainer">
          <EditButton
            action={action}
            label={step.editButtonLabel}
          />
        </div>
      )}
    </div>
  );
};

SummarySteps.propTypes = {
  attributeId: PropTypes.string,
  step: PropTypes.object,
  action: PropTypes.func
};

export default SummarySteps;
