// Chat History

const chatHistoryReducerDefaultState = [];

export default function chatHistoryReducer(
  state = chatHistoryReducerDefaultState,
  action
) {
  switch (action.type) {
    case 'ADD_CHAT_BUBBLE':
      return [...state, action.chatBubble];
    default:
      return state;
  }
}
