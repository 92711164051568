import React from 'react';
import PropTypes from 'prop-types';
const TokenizedLabel = (props) => {

  let {value} = props.children;

  return (
    (props.text !== undefined) ?
      <div >
        {props.text.replace(`{${props.token}}`, props.formatter(value))}
      </div> : null
  );
};

TokenizedLabel.propTypes = {
  children: PropTypes.object,
  text:PropTypes.string,
  token:PropTypes.string,
  formatter:PropTypes.func
};

export default TokenizedLabel;

