import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import PropTypes from 'prop-types';
import getComponent from './componentServer';
import * as stackactiontypes from '../../common/stackactiontypes';
import { handleNextSteps } from '../../actions/formActions';
import { getStore } from '../../store';

class ComponentWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handledResult: false,
    };
  }

  handleSuccess = (result) => {
    if (!this.state.handledResult) {
      if (result) {
        if (result.data && Array.isArray(result.data)) {
          const currentClientRelevantData =
            getStore().getState().formMeta.clientRelevantData;
          let newClientRelevantData = [];
          if (currentClientRelevantData) {
            newClientRelevantData = [...currentClientRelevantData];
          }
          newClientRelevantData = [...newClientRelevantData, ...result.data];
          this.props.dispatch(
            actions.change('formMeta.clientRelevantData', newClientRelevantData)
          );
        }
      }
      this.props.dispatch(
        handleNextSteps(this.props.steps, stackactiontypes.PUSH, true)
      );
      this.setState({ handledResult: true });
    }
  };

  handleFailure = () => {
    if (!this.state.handledResult) {
      this.props.dispatch(
        handleNextSteps(this.props.steps, stackactiontypes.PUSH, true)
      );
      this.setState({ handledResult: true });
    }
  };

  render() {
    let { step } = this.props;
    let { componentConfig } = step;

    return (
      <div>
        {componentConfig && componentConfig.name ? (
          getComponent(componentConfig, this.handleSuccess, this.handleFailure)
        ) : (
          <p>no web component</p>
        )}
      </div>
    );
  }
}

ComponentWrapper.propTypes = {
  steps: PropTypes.array,
  dispatch: PropTypes.func,
};

export default connect()(ComponentWrapper);
