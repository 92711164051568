export const inList = (val, collection) => {
  const isInCollection = (val, collection) => {
    if (collection && Array.isArray(collection)) {
      let filtered = collection.find((item) => {
        if (typeof item === 'object' && item.value) {
          return item.value.toLowerCase() === val.toLowerCase();
        } else if (typeof item === 'string') {
          return item.toLowerCase() === val.toLowerCase();
        }
        return false;
      });
      return filtered !== undefined;
    }
    return false;
  };
  if (Array.isArray(val)) {
    let found = val.filter(choice => isInCollection(choice, collection));
    return found.length === val.length;
  } else {
    return isInCollection(val, collection);
  }
};