import React from 'react';
import * as types from './stepTypes';
import TextControl from './controls/textControl';
import ButtonControl from './controls/buttonControl';
import SoftOffer from './controls/softOffer';
import DropDown from './controls/dropDown';
import RadioList from './controls/radioList';
import ComboBox from './controls/comboBox';
import Range from './controls/range';
import ScrollDate from './controls/scrollDateRrf';
import MultiPart from './controls/multiPartControlRrf';
import CheckBoxes from './controls/checkBoxes';
import Uploader from './controls/DocumentUpload';
import PropTypes from 'prop-types';
import Interstitial from './controls/interstitial';
import VehicleMake from './controls/vehicleMake';
import VehicleModel from './controls/vehicleModel';
import VehicleTrim from './controls/vehicleTrim';
import VehicleDealership from './controls/vehicleDealership';
import ProductSelection from './controls/productSelection';
import LabelNode from './controls/labelNode';
import ComponentWrapper from '../dynamicComponents/componentWrapper';

const StepsComposer = (props) => {
  switch (props.step.nodetype) {
    case types.FORM_TEXT:
      return <TextControl {...props} />;
    case types.FORM_SELECT:
      return <DropDown {...props} />;
    case types.FORM_RADIO:
      if (props.step.attributeid === 'VehicleDealerList') {
        return <VehicleDealership {...props} />;
      }
      return props.step.optionclickable ? (
        <Interstitial {...props} />
      ) : (
        <RadioList {...props} />
      );
    case types.FORM_COMBOBOX:
      return <ComboBox {...props} />;
    case types.FORM_RANGE:
      return <Range {...props} />;
    case types.FORM_DATE_SCROLL:
      return <ScrollDate {...props} />;
    case types.FORM_MULTIPART:
      return <MultiPart {...props} />;
    case types.FORM_UPLOAD:
      return <Uploader {...props} />;
    case types.FORM_CHECKBOXES:
    case types.FORM_TOGGLE:
      return <CheckBoxes {...props} />;
    case types.FORM_BUTTON:
      return <ButtonControl {...props} />;
    case types.SOFT_OFFER:
      return <SoftOffer {...props} />;
    case types.PRODUCT_SELECTION:
      return <ProductSelection {...props} />;
    case types.LABEL_NODE:
      return <LabelNode {...props} />;
    case types.FORM_VEHICLE_MAKE:
      return <VehicleMake {...props} />;
    case types.FORM_VEHICLE_MODEL:
      return <VehicleModel {...props} />;
    case types.FORM_VEHICLE_TRIM:
      return <VehicleTrim {...props} />;
    case types.FORM_COMPONENT:
      return <ComponentWrapper {...props} />;
    default:
      return null;
  }
};

StepsComposer.propTypes = {
  steps: PropTypes.array,
  modelParser: PropTypes.func,
  formatter: PropTypes.func,
  step: PropTypes.object,
  handleNext: PropTypes.func,
};

export default StepsComposer;
