export const DOB = 'bday';
export const PHONE = 'tel';
export const EMAIL = 'email';
export const FIRSTNAME = 'given-name';
export const LASTNAME =  'family-name';
export const STREETADDRESS = "street-address";
export const CITY = "address-level1";
export const STATE = "address-level2";
export const ZIPCODE = "postal-code";
export const NEWPASSWORD = "new-password";
