import { getStore } from '../store/index';
import { changeFormStepProps, changeFormData } from '../actions/formActions';
import { getFormatter } from './formatters';
import { ltLogger } from '../bootstrap/index';
import * as logtypes from '../common/logtypes';

// eslint-disable-next-line no-unused-vars
let tokenizeText = function (tokenizeString, combinedSessionData, formatter) {
  let re = '{([a-zA-Z\\d]+)}';
  return tokenizeString.replace(new RegExp(re, 'g'), function () {
    return (
      formatter(combinedSessionData[arguments[1]]) || '{' + arguments[1] + '}'
    );
  });
};

export const runRules = function (rules, ruleResult, step) {
  if (Array.isArray(rules)) {
    rules.forEach((rule) => {
      if (
        rule.condition &&
        rule.condition.type &&
        condition[rule.condition.type](
          getStore().getState().formData[rule.attributeId],
          rule.condition.value,
          rule
        )
      ) {
        actions[rule.action.type](ruleResult, rule, step);
      }
    });
  }
};

const condition = {
  Equal: function (a, b) {
    return a == b;
  },
  'Not Equal': function (a, b) {
    return a != b;
  },
  '>': function (a, b) {
    return parseInt(a) > parseInt(b);
  },
  '<': function (a, b) {
    return parseInt(a) < parseInt(b);
  },
  '>=': function (a, b) {
    return parseInt(a) >= parseInt(b);
  },
  '<=': function (a, b) {
    return parseInt(a) <= parseInt(b);
  },
  'Within Range': function (a, b, rule) {
    return (
      parseInt(a) >= parseInt(rule.condition.lowerBound) &&
      parseInt(a) <= parseInt(rule.condition.upperBound)
    );
  },
  'Outside Range': function (a, b, rule) {
    return !(
      parseInt(a) >= parseInt(rule.condition.lowerBound) &&
      parseInt(a) <= parseInt(rule.condition.upperBound)
    );
  },
  Undefined: function (a) {
    return typeof a === 'undefined';
  },
};

const actions = {
  Show: function (ruleResult) {
    ruleResult.showStep = true;
  },
  Hide: function (ruleResult) {
    ruleResult.showStep = false;
  },
  'Update Help Text': function (ruleResult, rule, step) {
    // Zach James :: PHX-3467
    let formatter = getFormatter(rule.action.formatter);
    let newHelpText = tokenizeText(
      rule.action.updateText,
      getStore().getState().formData,
      formatter
    );
    if (step.help !== newHelpText) {
      getStore().dispatch(
        changeFormStepProps({
          targetProp: 'help',
          targetValue: newHelpText,
          targetStepId: step.attributeid,
        })
      );
    }
  },
  'Update Attribute': function (ruleResult, rule) {
    try {
      const { attributeToUpdate, updatedAttributeValue } = rule.action;
      const { formData } = getStore().getState().forms;
      let attributeValue = new Function(updatedAttributeValue)();
      let attributeToUpdateObject = formData[attributeToUpdate];
      let attributeRuleObject = formData[rule.attributeId];
      const isAttributeToUpdateInFocus = () => attributeToUpdateObject && attributeToUpdateObject.focus;
      if ((attributeRuleObject && !attributeRuleObject.focus)) return;
      if (isAttributeToUpdateInFocus() || isNaN(parseInt(attributeValue))) {
        attributeValue = getStore().getState().formData[attributeToUpdate];
      }
      getStore().getState().formData[attributeToUpdate] ===
      attributeValue ||
        getStore().dispatch(
          changeFormData(attributeToUpdate, attributeValue)
        );
    } catch (error) {
      ltLogger(logtypes.ERROR, undefined, {}, undefined, {
        error: error,
      });
    }
  },
};
