import React from 'react';
import { connect } from 'react-redux';
import Form from './components/forms/form';
import ChatForm from './components/forms/formChat';

class App extends React.Component {
  componentDidMount() {
    document.getElementsByTagName('body')[0].classList.remove('bodyReset');
  }

  render() {
    return window.chatform ? <ChatForm /> : <Form />;
  }
}

export default connect()(App);
