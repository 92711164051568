import React from 'react';
import PropTypes from 'prop-types';

const backdropStyles = {
  zIndex: -1,
  backgroundColor: 'rgba(0 0 0 / 50%)',
  backdropFilter: 'blur(2px)',
};

const backdropClassName = 'ltModalBackdrop';

const ModalBackdrop = ({ className, style, ...props }) => {
  const combinedClassNames =
    (className != null) === true
      ? `${backdropClassName} ${className}`
      : backdropClassName;

  const combinedStyles = {
    ...backdropStyles,
    ...(style || {}),
  };

  return (
    <div {...props} className={combinedClassNames} style={combinedStyles} />
  );
};

ModalBackdrop.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ModalBackdrop;
