import * as stepFuncs from '../../../../utils/stepFunctions';

const getDividerChar = ({ formatter }) => stepFuncs.getDividerForFormat(formatter);

const usePhoneMultipartFormat = ({ step }) => {
  const dividerChar = getDividerChar(step);

  return {
    handleValueCombine: () => (val) => `${val.box1}${val.box2}${val.box3}`,
    splitFunction: () => (val) => {
      if (val.length !== 10) return;

      return {
        box1value:
          val.indexOf(dividerChar) > -1
            ? val.split(dividerChar)[0]
            : val.substring(0, 3),
        box2value:
          val.indexOf(dividerChar) > -1
            ? val.split(dividerChar)[1]
            : val.substring(3, 6),
        box3value:
          val.indexOf(dividerChar) > -1
            ? val.split(dividerChar)[2]
            : val.substring(6, 10),
      };

    }
  };
};

export default usePhoneMultipartFormat;
