import { combineReducers } from 'redux';
import configReducer from './configReducer';
import formReducer from './formReducer';
import formMetaReducer from './formMetaReducer';
import formStateReducer from './formStateReducer';
import chatHistoryReducer from './chatHistory';
import { createForms } from 'react-redux-form';

const rootReducer = combineReducers({
  config: configReducer,
  formDefinition: formReducer,
  formState: formStateReducer,
  chatHistory: chatHistoryReducer,
  ...createForms({ formData: {}, formMeta: formMetaReducer }), //,
  //routing: routerReducer
});
export default rootReducer;
