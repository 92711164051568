import React from 'react';
import PropTypes from 'prop-types';
const ProgressOverlay = (props) => {
  return (
    <div className="ltFormOverlay">
      <div className="image-wrapper">
        <div className="graphic" />
        <div className="indicator" />
      </div>
      <h4>
        <strong>
          {props.displayText ? props.displayText : 'Searching Lender Network'}
        </strong>
      </h4>
      <h6>
        Thank you for your patience <br /> as this could take up to a minute
      </h6>
    </div>
  );
};

ProgressOverlay.propTypes = {
  displayText: PropTypes.string,
};
export default ProgressOverlay;
