import React from 'react';
import PropTypes from 'prop-types';

const FormProgress = ({percentComplete}) => {

  return (
    <div className="ltProgressBarWrapper">
      <div className="ltProgressBar">
        <div className="ltProgressBarProgress" style={{width: percentComplete + '%'}}/>
      </div>
    </div>
  );
};

FormProgress.propTypes = {
  percentComplete: PropTypes.number
};

export default FormProgress;
