import React, { useState, useEffect } from 'react';
import { getStore } from '../../../../store';
import { getComponentFormData } from './helper';
import config from '../../../../reducers/config';
import loadScript from './loadScript';

let eventListenersSet = false;
let failStateTimeout;

// PHX-5917 :: Tech-Debt :: Zach James
// OOW events
// Sync Data / PII change events -- I don't see any pii events in the component repo, so we'd have to use a selector
// Handle success / fail -- currently skips to next step

// Handles all events that would continue the form
const continueEvents = [
  'ltAccountCreationSkipped',
  'ltCfdFailed',
  'ltComponentError',
  'ltCreditFileNotFound',
  'ltEmailConflicted',
  'ltSignUpComplete',
  'ltUnauthorized',
];

const TU_FRAUD_EVENT = 'ltCfdFailed';

/* eslint-disable-next-line no-unused-vars */
const handleContinueEvents = (eventName, handleSuccess, handleFailure) => {
  // PHX-5917 :: TECH-DEBT :: Zach James
  // We should be checking the event name here and deciding whether to consider it a success or failure,
  // and decide on what data to send back to the API (if any) in relation to those
  // For now, we only handle suspicious leads events separately and default to success
  if (eventName === TU_FRAUD_EVENT) {
    // PHX-5961 :: INFO :: Zach James
    // Considering this a success for the component as the component is acting as expected
    // Data gets passed back up to componentWrapper, where we should handle meta stuff for components
    handleSuccess({
      data: [{ stateName: 'formMeta.tuSuspiciousLead', value: true }],
    });
  } else {
    handleSuccess();
  }
};

/* eslint-disable-next-line react/display-name*/
export default function SignUpComponent({
  componentConfig,
  handleSuccess,
  handleFailure,
}) {
  const { loaded, error } = loadScript(config.apiConfig.sdkLoaderUri);
  const [validatedData, setValidatedData] = useState({});
  const [nextStepRequested, setNextStepRequested] = useState(false);
  const [qFormUid, setQFormUid] = useState('');

  useEffect(() => {
    // PHX-5917 :: TECH-DEBT :: Zach James
    // Potentially replace with use selector?
    // We don't currently have other fields on the page
    // So relevant store updates (e.g. pii) shouldn't take place
    // after mounting
    // If we allow other fields, this may need to change
    const store = getStore();
    let formData = store.getState().formData;
    let formMeta = store.getState().formMeta;
    if (formMeta) {
      setQFormUid(formMeta.formUuid);
    }
    let filteredFormData = formData;
    if (
      componentConfig &&
      componentConfig.options &&
      componentConfig.options.prepopFields
    ) {
      filteredFormData = {};
      const prepopFields = componentConfig.options.prepopFields;
      for (let i = 0; i < prepopFields.length; i++) {
        if (typeof formData[prepopFields[i]] !== 'undefined') {
          filteredFormData[prepopFields[i]] = formData[prepopFields[i]];
        }
      }
    }
    const mappedFormData = getComponentFormData(filteredFormData);
    if (mappedFormData) {
      setValidatedData(mappedFormData);
    }
    if (!loaded || error) {
      if (!failStateTimeout) {
        failStateTimeout = setTimeout(() => {
          if (!loaded || error) {
            if (!nextStepRequested) {
              setNextStepRequested(true);
              handleFailure();
            }
          }
        }, 1000);
      }
    } else {
      if (failStateTimeout) {
        clearTimeout(failStateTimeout);
        failStateTimeout = null;
      }
    }
  }, [loaded, error]);

  if (!loaded || error) return null;

  if (!eventListenersSet) {
    for (let i = 0; i < continueEvents.length; i++) {
      window.addEventListener(continueEvents[i], () => {
        handleContinueEvents(continueEvents[i], handleSuccess, handleFailure);
      });
    }
    eventListenersSet = true;
  }
  const phxEnv = process.env.PHOENIX_ENVIRONMENT
    ? process.env.PHOENIX_ENVIRONMENT
    : window.ltPhoenix.env;
  const componentEnv = ((phxEnv) => {
    switch (phxEnv) {
      case 'dev':
        return 'development';
      case 'prod':
        return 'production';
      default:
        return phxEnv;
    }
  })(phxEnv);

  if (typeof componentConfig !== 'undefined') {
    let optionsToPass = {
      key: componentConfig.componentId,
      'form-data': JSON.stringify(validatedData),
      'return-url': '/',
      active: '',
      env: componentEnv,
      'acquisition-source': 'QFormOptIn',
      partner: 'MyLendingTree',
      'skip-acc-creation-btn-on': true,
      'hide-on-email-conflict': true,
      'hide-when-credit-file-not-found': true,
      'hide-when-oow-is-complete': true,
      'silent-auth-off': true,
    };
    if (qFormUid.length > 0) {
      optionsToPass['partner-tracking'] = JSON.stringify({
        qformuid: qFormUid,
      });
    }
    if (componentConfig.options.length !== 0) {
      const componentOptions = componentConfig.options;
      if (componentOptions['showSkipAccountCreationButton']) {
        optionsToPass['skip-acc-creation-btn-on'] =
          componentOptions['showSkipAccountCreationButton'];
      }
      if (componentOptions['isOtpEnabled']) {
        optionsToPass['is-otp-enabled'] = componentOptions['isOtpEnabled'];
      }
    }
    return React.createElement(componentConfig.name, optionsToPass);
  }
}
