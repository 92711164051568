import React from 'react';
import PropTypes from 'prop-types';
const ValidationIndicator = (props) => {

  let {fieldValue} = props.children;
  let {classExt} = props;
  let show = fieldValue.validating;

  return (

    <div className={`ltFormValidating ${classExt}`}>
      {show ?
        'Validating...' : ''
      }</div>
  );
};

ValidationIndicator.propTypes = {
  fieldValue: PropTypes.object,
  children: PropTypes.object,
  classExt: PropTypes.string
};

export default ValidationIndicator;
