export const createAndSumbitForm = ({data, target, method = 'post', enctype}) => {
  const form = document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("action", target);
  form.setAttribute("enctype", method === 'post' ? enctype || "application/x-www-form-urlencoded" : '');

  Object.keys(data).forEach(key => {
    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", key);
    hiddenField.setAttribute("value", typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key]);
  
    form.appendChild(hiddenField);
  });

  document.body.appendChild(form);
  form.submit();
};
