import React from 'react';
import { getStore } from '../../store';

/* eslint-disable-next-line react/display-name */
export default (componentConfig) => {
  const store = getStore();
  const formData = store.getState().formData;
  const formDataObject = {
    firstName: formData['FirstName'],
    lastName: formData['LastName'],
    streetAddress: formData['StreetAddress'],
    zipCode: formData['ZipCode'],
    city: formData['City'],
    state: formData['State'],
    birthdate: formData['DOB'],
  };

  if (typeof componentConfig !== 'undefined') {
    return React.createElement(componentConfig.name, {
      key: componentConfig.componentId,
      'form-data': `${JSON.stringify(formDataObject)}`,
      'return-url': '/',
      active: '',
      env: `${process.env.PHOENIX_ENVIRONMENT}`,
      partner: 'MyLendingTree',
      'skip-acc-creation-btn-on': '',
    });
  }
};
