import * as types from '../actions/formActionTypes';
import objectMapper from 'object-mapper';

export default function formReducer(
  state = {
    formId: '',
    stepSet: [],
    allsteps: [],
    buttonText: 'Continue',
    scripts: {},
  },
  action
) {
  switch (action.type) {
    case types.FORM_LOADING:
      return Object.assign({}, state, { formId: action.formId });
    case types.STEP_LOADED:
      return Object.assign({}, state, {
        stepSet: action.stepSet,
        formId: action.formId,
        components: action.components,
        allsteps: state.allsteps.concat(
          action.allsteps.filter(function (item) {
            return (
              state.allsteps.findIndex((step) => step.name === item.name) === -1
            );
          })
        ),
        submitButtonText: action.submitButtonText,
        formScripts: action.formScripts,
      });
    case types.FORM_PROCESSING:
      return Object.assign({}, state, {
        processNodeDefinition: action.processNodeDefinition,
        processNodeFormDefinition:
          action.processNodeFormDefinition !== undefined
            ? action.processNodeFormDefinition
            : state.processNodeFormDefinition,
      });
    case types.FORM_STEP_UPDATE: {
      let newSteps = JSON.parse(JSON.stringify(state.stepSet));
      let stepIndex = newSteps.findIndex(
        (step) => step.attributeid === action.item.targetStepId
      );
      if (stepIndex === -1) return state;
      objectMapper.setKeyValue(
        newSteps[stepIndex],
        action.item.targetProp.toString(),
        action.item.targetValue
      );
      return Object.assign({}, state, { stepSet: newSteps });
    }
    case types.FORM_DEFINITION_UPDATE: {
      return Object.assign({}, state, action.formDefinition);
    }
    default:
      return state;
  }
}
