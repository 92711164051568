/**
 * PHX-6180: send track event to detect if styles are being loaded.
 */

import { isMyLTHost, ltLogger, getFetchFunction } from '../bootstrap';
import { buildCorsHeaders, createURL } from './url';
import * as logtypes from '../common/logtypes';

export function detectStyles() {
  // We are not logging for inside flow.
  if (!isMyLTHost(window.location.host)) {
    // Detect new or old repo
    let repo;
    if (window.location.href.includes('lendingtree.com/forms/')) {
      repo = 'Old repo';
    } else if (window.location.href.includes('lendingtree.com/form/')) {
      repo = 'New repo';
    } else {
      repo = 'None detected';
    }

    // Check old repo styles
    let oldRepoClass =
      repo === 'Old repo' && document.querySelector('.Template');
    let oldRepoStyle = oldRepoClass && window.getComputedStyle(oldRepoClass);
    let oldStyling = oldRepoStyle
      ? oldRepoStyle.backgroundColor === 'rgb(16, 31, 48)' ||
        oldRepoStyle.backgroundColor === 'rgb(245, 245, 245)'
      : false;

    // Check new repo styles
    let newRepoBody = repo === 'New repo' ? document.body : undefined;
    let newRepoStyle = newRepoBody && window.getComputedStyle(newRepoBody);
    let newStyling = newRepoStyle
      ? newRepoStyle.backgroundColor === 'rgb(16, 31, 48)' ||
        newRepoStyle.backgroundColor === 'rgb(245, 245, 245)' ||
        newRepoStyle.backgroundColor === 'rgb(246, 247, 247)'
      : false;

    const headers = {
      'content-type': 'application/json; charset=utf-8',
      Accept: 'application/json',
      'phoenix-package': `${phoenixname};${phoenixversion};${
        process.env.BUILD_NUMBER
          ? process.env.BUILD_NUMBER.substring(0, 8)
          : 'none'
      };`,
    };

    const url = createURL('/api/track/StylesLoading');

    const body = JSON.stringify({
      NewStyling: newStyling.toString(),
      OldStyling: oldStyling.toString(),
      FormId: window.ReduxStore.getState().formMeta?.formId || '',
      FormUuid: window.ReduxStore.getState().formMeta?.formUuid || '',
      ClickSessionId: window.localStorage.getItem('lt_sessionKey') || '',
      Repo: repo,
      UserAgent: navigator.userAgent.toString(),
    });

    getFetchFunction()(url, {
      method: 'POST',
      body: body,
      headers: { ...headers, ...buildCorsHeaders(url) },
    })
      .then(() => {
        ltLogger(logtypes.INFO, undefined, {}, undefined, {
          message: 'Sent StylesLoading track event successfully',
        });
      })
      .catch((err) => {
        ltLogger(logtypes.ERROR, undefined, {}, undefined, {
          error: err,
        });
      });
  }
}
