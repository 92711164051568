import React from 'react';
import { Control } from 'react-redux-form';
import Subtext from '../subText';
import ScrollDate from '../controls/scrollDate';
import PropTypes from 'prop-types';
import { titleCase } from '../../../utils/titleCase';

const ScrollDateRrf = ({ step }) => {
  return (
    <div className={`ltFormGroupContent${titleCase(step.nodetype, 0)}`}>
      <Control
        id={`formData.${step.attributeid}`}
        model={`formData.${step.attributeid}`}
        component={ScrollDate}
        mapProps={{
          onupdate: (props) => props.onChange,
          attributeid: step.attributeid,
          controlFormat: step.formatter,
        }}
      />
      <Control.text
        model={`formData.${step.attributeid}`}
        component={(props) => (
          <Subtext
            text={step.help}
            forceShow={step.persistHelpText}
            classExt={step.attributeid}
          >
            {props}
          </Subtext>
        )}
        mapProps={{
          fieldValue: (props) => props.fieldValue,
        }}
      />
    </div>
  );
};

ScrollDateRrf.propTypes = {
  onupdate: PropTypes.func,
  step: PropTypes.object,
  onChange: PropTypes.func,
  fieldValue: PropTypes.object,
};

export default ScrollDateRrf;
