import { useCallback } from 'react';

// A helper that can safely be used to generate a className
// that includes the step's attributeId
export default function useWithAttributeId(attributeId) {
  function generateClassName(...items) {
    const names = items
      .filter((i) => i != null)
      .map((i) => i.trim())
      .join(' ');

    return `${names} ${attributeId}`;
  }

  return useCallback(generateClassName, [attributeId]);
}
