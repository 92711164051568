import React from 'react';
import PropTypes from 'prop-types';
import VehicleDealerList from '../../forms/controls/vehicleDealerList';
import { connect } from 'react-redux';
import { getFormData } from '../../../selectors/formSelector';
import { actions } from 'react-redux-form';
import { getStore } from '../../../store';
import GoogleMap from '../../GoogleMap';
import { runActionsAfterStep } from '../../../actions/formActions';
import GenericError from './genericError';
import { createBrowserHistory } from 'history';

// Map defaults to Charlotte
const mapDefaultCenter = {
  lat: 35.2271,
  lng: -80.8431,
};

const areEqual = (prevProps, nextProps) => {
  if (
    prevProps.fetchedVehicleDealerList.length !==
    nextProps.fetchedVehicleDealerList.length
  )
    return false;
  if (
    prevProps.fetchedVehicleDealerList.some(
      (item, index) =>
        item.value !== nextProps.fetchedVehicleDealerList[index].value
    )
  )
    return false;
  if (nextProps.dealerId === undefined) return true;
  if (prevProps.dealerId !== nextProps.dealerId) return false;
  return true;
};

const GoogleMapMemo = React.memo(GoogleMap, areEqual);

class VehicleDealership extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded:
        props.formData && props.formData.expanded !== undefined
          ? props.formData.expanded
          : false,
      pageSize: 5,
      hideMap: 'hideMap',
    };
    this.loadMoreOrLess = this.loadMoreOrLess.bind(this);
    this.history = createBrowserHistory();
    this.goBack = this.goBack.bind(this);
  }

  componentWillMount() {
    const steps = this.props && this.props.steps;
    const step = steps && steps.find((e) => e.attributeid === 'ZipCode');
    if (step && step.actionsafterchange.length > 0) {
      this.props.dispatch(
        runActionsAfterStep(null, step, (val) =>
          this.props.formatter(val, true)
        )
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.step.enumeration !== nextProps.step.enumeration) {
      this.setState({
        hideMap: nextProps.step.enumeration.length > 0 ? 'showMap' : 'hideMap',
      });
      if (
        nextProps.formData.expanded === undefined ||
        nextProps.step.enumeration.length <= 5
      ) {
        this.setState({
          expanded: false,
        });
      } else if (nextProps.formData.expanded === true) {
        this.setState({
          expanded: true,
        });
      }
    }
  }

  loadMoreOrLess() {
    const { dispatch } = this.props;
    const { expanded } = this.state;

    this.setState({
      expanded: !expanded,
    });

    dispatch(actions.change('formData.expanded', !this.state.expanded));
  }

  goBack = () => this.history.goBack();
  render() {
    const { formData } = this.props;
    const { formMeta } = getStore().getState();
    const { expanded } = formData && formData.expanded ? formData : this.state;
    const { pageSize, hideMap } = this.state;
    const itemsToShow = !expanded
      ? pageSize
      : this.props.step.enumeration.length;

    let enumCollection = this.props.step.enumeration
      .slice(0, itemsToShow)
      .sort((a, b) => {
        return a.displayOrder - b.displayOrder;
      });

    if (enumCollection && enumCollection.length < 1) {
      let back = (
        <a href={'#'} onClick={this.goBack}>
          go back
        </a>
      );
      return (
        <div>
          <GenericError
            errorMessage={
              <div>
                <span>We could not retrieve any results. Please </span>
                {back}
                <span> or try with different inputs.</span>
              </div>
            }
          />
        </div>
      );
    }
    return formMeta.zipcodeIsValid ? (
      <React.Fragment>
        <div className="row">
          <div className="vehicleDealerList">
            <VehicleDealerList
              fetchedVehicleDealerList={enumCollection}
              handleNext={this.props.handleNext}
              parentProps={this.props}
            />
          </div>
          <div className="seeMoreDealerList" onClick={this.loadMoreOrLess}>
            {this.props.step.enumeration.length > this.state.pageSize ? (
              this.state.expanded ? (
                <span>SEE LESS</span>
              ) : (
                <span>SEE MORE</span>
              )
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={`${hideMap} ltFormGroup VehicleDealerMap`}>
          <GoogleMapMemo
            fetchedVehicleDealerList={enumCollection}
            center={mapDefaultCenter}
            dealerId={formData.DealerId}
          />
        </div>
      </React.Fragment>
    ) : (
      <div className="ltHasError">
        Dealership options are currently only available in California, but
        don&apos;t worry - we are working on finding a lending match for you.
      </div>
    );
  }
}
/*eslint-enable */
VehicleDealership.propTypes = {
  step: PropTypes.object,
  handleNext: PropTypes.func,
  dispatch: PropTypes.func,
  formData: PropTypes.object,
  steps: PropTypes.array,
  formatter: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    formData: getFormData(state),
  };
}
export default connect(mapStateToProps)(VehicleDealership);
