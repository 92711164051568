import React from 'react';
import PropTypes from 'prop-types';
const StepHeader = ({content, classExt}) => {

  return (
    // eslint-disable-next-line react/no-danger
    <div className={`ltFormQuestionHeader ${classExt}`} dangerouslySetInnerHTML={{__html: content}}/>
  );
};

StepHeader.propTypes = {
  content: PropTypes.string,
  classExt: PropTypes.string
};

export default StepHeader;
