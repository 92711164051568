import React from 'react';
import PropTypes from 'prop-types';

const Navigation = ({direction, action, text}) => {

  return (
    <div className={`ltFormNavigation ${direction}`} onClick={action}>
      <span className="ltFormNavigationIcon"/>
      <span className="ltFormNavigationText">{text}</span>
    </div>
  );
};

Navigation.propTypes = {
  direction: PropTypes.string,
  action: PropTypes.func,
  text: PropTypes.string
};

export default Navigation;
