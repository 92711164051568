import * as enviro from '../../environments';
import {createStore, compose, applyMiddleware} from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunkMiddleware from 'redux-thunk';
import { persistStore } from 'redux-persist';
import configurePersistStore from './configurePersistStore';

const environment = process.env.PHOENIX_ENVIRONMENT || enviro.LOCAL;
const middlewares = environment === enviro.PRODUCTION ? [thunkMiddleware] : [reduxImmutableStateInvariant(), thunkMiddleware];
const composeEnhancers = environment === enviro.PRODUCTION ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default (formId) => {
  const wrappedRootReducer = configurePersistStore(formId);

  return (initialState) => {
    const enhancers = composeEnhancers(applyMiddleware(...middlewares));
    const store = createStore(wrappedRootReducer, initialState, enhancers);
    const persistor = persistStore(store);

    return {
      store,
      persistor
    };
  };
};
