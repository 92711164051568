import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Errors, Control, actions } from 'react-redux-form';
import Subtext from '../subText';
import {
  getValidations,
  getValidationMessages,
} from '../../../validators/validators';
import PropTypes from 'prop-types';
import { transform, formatSummaryValue } from '../../../utils/stepFunctions';
import * as formatTypes from '../../../utils/formatTypes';
import { getFormData } from '../../../selectors/formSelector';
import {
  processAndChangeStepProperty,
  updateMultipleAttributesMap,
  resetValidityAndFormDataFields,
  changeFormStepProps,
} from '../../../actions/formActions';
import { titleCase } from '../../../utils/titleCase';
import { reportFieldEngagement } from '../../../bootstrap';

/*eslint-disable react/no-multi-comp*/

const errorComponentClassName = 'ltErrorText';
const ErrorWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.hasError;
  delete inputProps.step;
  return (
    <div
      className={`ltFormControlSelect${props.hasError ? ' ltHasError ' : ' '}${
        props.step.attributeid
      }`}
    >
      <select {...inputProps}>{props.children}</select>
    </div>
  );
};
ErrorWrapper.propTypes = {
  children: PropTypes.array,
  hasError: PropTypes.bool,
  step: PropTypes.object,
};

const buildDynamicDropdown = (step, formData) => {
  if (!step.dyanmicdropdownsettings) return [];
  let currentStep = step.attributeid;
  const optionArray = [];
  if (
    [
      'FirstMortgageBalance',
      'SecondMortgageBalance',
      'EstimatedPropertyValue',
      'CashoutAmount',
    ].includes(currentStep)
  ) {
    let firstMortgageBalance = Number(formData.FirstMortgageBalance);
    let secondMortgageBalance = Number(formData.SecondMortgageBalance);
    let estimatedPropertyValue = Number(formData.EstimatedPropertyValue);
    let maxFirstMortgageBalance = estimatedPropertyValue;
    let maxSecondMortgageBalance = estimatedPropertyValue;
    let maxCashoutAmount = estimatedPropertyValue;
    if (
      typeof estimatedPropertyValue !== 'undefined' &&
      typeof firstMortgageBalance !== 'undefined'
    ) {
      maxSecondMortgageBalance =
        estimatedPropertyValue - (firstMortgageBalance || 0);
      maxCashoutAmount =
        estimatedPropertyValue -
        firstMortgageBalance -
        (secondMortgageBalance || 0);
    }

    let dropdownupperbound,
      dropdownlowerbound = 0;
    let dropdownincrement = 10000;

    switch (currentStep) {
      case 'EstimatedPropertyValue':
        dropdownupperbound = 2000000;
        dropdownlowerbound = 80000;
        break;
      case 'FirstMortgageBalance':
        dropdownupperbound = maxFirstMortgageBalance
          ? maxFirstMortgageBalance === 2000001
            ? 2000000
            : maxFirstMortgageBalance
          : 2000000;
        dropdownlowerbound = 10000;
        break;
      case 'SecondMortgageBalance':
        dropdownupperbound = maxSecondMortgageBalance
          ? maxSecondMortgageBalance
          : 2000000;
        dropdownlowerbound = 10000;
        break;
      case 'CashoutAmount':
        dropdownupperbound =
          typeof maxCashoutAmount !== 'undefined' && maxCashoutAmount <= 400000
            ? maxCashoutAmount === 400000
              ? 380000
              : maxCashoutAmount
            : 400000;
        dropdownlowerbound = 0;
        dropdownincrement = 5000;
        break;
    }

    // let {dropdownupperbound, dropdownlowerbound, dropdownincrement} = step;
    // dropdownupperbound = Number(dropdownupperbound);
    // dropdownlowerbound = Number(dropdownlowerbound);
    // dropdownincrement = Number(dropdownincrement);

    if (dropdownlowerbound >= dropdownupperbound || dropdownincrement === 0) {
      if (currentStep === 'CashoutAmount' && optionArray.length === 0) {
        optionArray.push({ value: 0, label: '$0 No Cash' });
        optionArray.push({ value: 5000, label: '$1 - $5,000' });
      }
      return optionArray;
    }

    while (dropdownupperbound > dropdownlowerbound) {
      if (
        currentStep === 'CashoutAmount' &&
        dropdownlowerbound + dropdownincrement * 2 > maxCashoutAmount
      ) {
        if (optionArray.length === 0) {
          optionArray.push({ value: 0, label: '$0 No Cash' });
        }
        // Break since two entries will push cashout amount past max value
        break;
      }
      let option = {
        // The value for mortgage related fields depends on the fields themselves
        // MortgageBalances have +1 to value from lower bound, estimated property value and cashout amount are equal to upper bound
        value:
          currentStep === 'EstimatedPropertyValue' ||
          currentStep === 'CashoutAmount'
            ? dropdownlowerbound + dropdownincrement
            : dropdownlowerbound + 1,
        label: `$${formatSummaryValue(
          dropdownlowerbound + 1,
          formatTypes.NUMBER
        )} - $${formatSummaryValue(
          dropdownlowerbound + dropdownincrement,
          formatTypes.NUMBER
        )}`,
      };
      if (optionArray.length === 0) {
        // We don't need to adjust the value for the starting entry in all mortgage dropdowns like we do for the intermediate ones
        let zerothEntry = {
          value: dropdownlowerbound,
          label: `$${formatSummaryValue(
            dropdownlowerbound,
            formatTypes.NUMBER
          )} or less`,
        };
        if (currentStep === 'CashoutAmount') {
          zerothEntry.label = '$0 No Cash';
        }
        optionArray.push(zerothEntry);
        if (currentStep !== 'EstimatedPropertyValue') {
          // Estimated Property Value doesn't have an intermediate option between the "or less" and the next bracket up
          optionArray.push(option);
        }
      } else {
        optionArray.push(option);
      }
      dropdownlowerbound += dropdownincrement;
      if (currentStep !== 'CashoutAmount') {
        switch (dropdownlowerbound) {
          case 10000:
            dropdownincrement = 10000;
            break;
          case 200000:
            dropdownincrement = 25000;
            break;
          case 500000:
            dropdownincrement = 50000;
            break;
          case 1000000:
            dropdownincrement = 500000;
            break;
          default:
            break;
        }
      } else {
        switch (dropdownlowerbound) {
          case 130000:
            dropdownincrement = 10000;
            break;
          case 200000:
            dropdownincrement = 20000;
            break;
          default:
            break;
        }
      }
    }
    if (
      dropdownupperbound >= 2000000 ||
      (dropdownupperbound >= 400000 && currentStep === 'CashoutAmount')
    ) {
      if (currentStep !== 'FirstMortgageBalance') {
        optionArray.push({
          value: dropdownupperbound + 1,
          label: `Over $${formatSummaryValue(
            dropdownupperbound,
            formatTypes.NUMBER
          )}`,
        });
      }
    } else {
      if (
        currentStep !== 'FirstMortgageBalance' &&
        currentStep !== 'SecondMortgageBalance'
      ) {
        if (
          dropdownupperbound > dropdownincrement &&
          dropdownlowerbound + dropdownincrement < dropdownupperbound
        ) {
          optionArray.push({
            value:
              currentStep === 'EstimatedPropertyValue' ||
              currentStep === 'CashoutAmount'
                ? dropdownlowerbound + dropdownincrement
                : dropdownlowerbound + 1,
            label: `$${formatSummaryValue(
              dropdownlowerbound + 1,
              formatTypes.NUMBER
            )} - $${formatSummaryValue(
              dropdownlowerbound + dropdownincrement,
              formatTypes.NUMBER
            )}`,
          });
        }
      }
    }
  } else if (currentStep === 'PurchaseYear') {
    let maxYear = new Date().getFullYear();
    let minYearObj = new Date();
    minYearObj.setFullYear(minYearObj.getFullYear() - 100);
    let minYear = minYearObj.getFullYear();
    for (let i = maxYear; i >= minYear; i--) {
      optionArray.push({ label: `${i}`, value: i });
    }
  } else {
    let increment = isNaN(Number(step.dropdownincrement))
      ? 0
      : parseInt(step.dropdownincrement, 10);
    let upperBound = isNaN(Number(step.dropdownupperbound))
      ? 0
      : parseInt(step.dropdownupperbound, 10);
    let lowerBound = isNaN(Number(step.dropdownlowerbound))
      ? 0
      : parseInt(step.dropdownlowerbound, 10);

    if (increment > 0) {
      while (upperBound >= lowerBound) {
        optionArray.push({ label: `${upperBound}`, value: `${upperBound}` });
        upperBound -= increment;
      }
    }
  }

  return optionArray;
};
const DropDown = ({
  steps,
  step,
  handleNext,
  autoCompleteText,
  formData,
  dispatch,
}) => {
  const dropDownOption = buildDynamicDropdown(step, formData);
  // eslint-disable-next-line no-unused-vars
  const [enumeration, setEnumeration] = useState(
    dropDownOption.length > 0 ? dropDownOption : step.enumeration
  );
  const [lastReportedValue, setLastReportedValue] = useState('');
  useEffect(() => {
    dispatch(
      changeFormStepProps({
        targetProp: 'enumeration',
        targetValue: [...enumeration],
        targetStepId: step.name,
      })
    );
  }, []);
  const actionsAfterList = [];
  steps.forEach((step) =>
    step.actionsafterchange.forEach((e) => {
      if (e.changeStepProperty) actionsAfterList.push(e);
    })
  );
  const isDisabled = actionsAfterList.some(
    (item) => item.targetStep === step.attributeid && !step.enumeration.length
  );

  const resetDependentDropdowns = (model) => {
    const index = steps.findIndex(
      (step) => `formData.${step.attributeid}` === model
    );
    for (let i = index + 1; i < steps.length; i++) {
      actionsAfterList.forEach((e) => {
        if (e.targetStep === steps[i].attributeid) {
          dispatch(actions.reset(`formData.${steps[i].attributeid}`));
          dispatch(
            changeFormStepProps({
              targetProp: 'enumeration',
              targetValue: [],
              targetStepId: steps[i].name,
            })
          );
        }
      });
    }
  };
  useEffect(() => {
    if (
      !(
        actionsAfterList.length &&
        step.validation.validationTypeCode === 'INLST'
      )
    ) {
      return;
    }
    if (!step.enumeration.length) {
      dispatch(
        actions.resetValidity(`formData.${step.attributeid}`, [
          'validationTypeCode',
        ])
      );
    } else {
      dispatch(
        actions.setValidity(`formData.${step.attributeid}`, {
          validationTypeCode: 'INLST',
        })
      );
    }
  }, [
    step.enumeration,
    dispatch,
    actionsAfterList.length,
    step.validation.validationTypeCode,
    step.attributeid,
  ]);

  return (
    <div className={`ltFormGroupContent${titleCase(step.nodetype, 0)}`}>
      <Control.select
        model={`formData.${step.attributeid}`}
        component={ErrorWrapper}
        className={`ltFormControl ${step.attributeid}`}
        autoComplete={autoCompleteText}
        validateOn={['blur', 'change']}
        validators={getValidations(
          step.validation,
          step.formatter,
          step.enumeration,
          undefined,
          step.attributeid
        )}
        updateOn={['blur', 'change']}
        disabled={isDisabled}
        changeAction={(model, value, event) => (dispatch, getState) => {
          if (lastReportedValue !== value) {
            setLastReportedValue(value);
            reportFieldEngagement(step.attributeid, value);
          }
          if (value !== event.currentValue) resetDependentDropdowns(model);
          let enums = getState().formDefinition.stepSet.find(
            (step) => step.attributeid === model.split('.')[1]
          ).enumeration;
          let modify = enums.find(
            (item) => item.value == value && item.actionAttribute !== undefined
          );
          let curValue = getState().formData[model.split('.')[1]];
          if (modify !== undefined) {
            let currVal = getState().formData[modify.actionAttribute];
            model = `formData.${modify.actionAttribute}`;
            value =
              modify.action != undefined
                ? transform(
                    modify.action,
                    getState().formData[modify.actionAttribute],
                    modify.actionValue
                  ).toString()
                : currVal;
          }
          dispatch(actions.change(model, value));
          const selectedEnum = enums.find((e) => e.value === value);
          if (selectedEnum && selectedEnum.value !== curValue) {
            step.actionsafterchange.filter((action) =>
              dispatch(
                resetValidityAndFormDataFields(
                  action.targetStep,
                  step.attributeid
                )
              )
            );
            step.actionsafterchange
              .filter((action) => action.onselectmap)
              .forEach((action) =>
                dispatch(
                  updateMultipleAttributesMap(action.onselectmap, selectedEnum)
                )
              );
            step.actionsafterchange
              .filter((e) => e.changeStepProperty)
              .forEach((action) =>
                dispatch(
                  processAndChangeStepProperty(action, step, selectedEnum)
                )
              );
          }
          //set additional fields to Redux if present on step
          if (step.actionbeforerender && step.actionbeforerender.onselectmap) {
            dispatch(
              updateMultipleAttributesMap(
                step.actionbeforerender.onselectmap,
                selectedEnum
              )
            );
          }
          if (!step.disableAutoforward && event && steps.length === 1)
            handleNext();
        }}
        mapProps={{
          className: ({ fieldValue }) =>
            fieldValue.valid || fieldValue.pristine || !fieldValue.touched
              ? `ltFormControl ${step.attributeid}`
              : `ltFormControl ltHasError ${step.attributeid}`,
          value: (props) => (props.viewValue ? props.viewValue : ''),
          hasError: ({ fieldValue }) =>
            !(fieldValue.valid || fieldValue.pristine || !fieldValue.touched),
          step: step,
        }}
      >
        {step.placeholder.length > 0 ? (
          <option value="">{step.placeholder}</option>
        ) : null}
        {dropDownOption.length
          ? dropDownOption.map((choice) => (
              <option key={choice.value} value={choice.value}>
                {choice.label}
              </option>
            ))
          : step.enumeration
              .slice(0)
              .sort((a, b) => {
                return a.displayOrder - b.displayOrder;
              })
              .map((choice) =>
                choice.displayOrder < 0 ? null : (
                  <option key={choice.value} value={choice.value}>
                    {choice.label}
                  </option>
                )
              )}
      </Control.select>
      <Errors
        className={`ltFormHelpBlock ltHasError ${step.attributeid} ${errorComponentClassName}`}
        wrapper="div"
        show={(field) => {
          return !field.pristine && !field.validating && field.touched;
        }}
        model={`formData.${step.attributeid}`}
        messages={getValidationMessages(step.validation)}
      />
      <Control.text
        model={`formData.${step.attributeid}`}
        component={(props) => (
          <Subtext
            text={step.help}
            forceShow={step.persistHelpText}
            classExt={step.attributeid}
          >
            {props}
          </Subtext>
        )}
        mapProps={{
          fieldValue: (props) => props.fieldValue,
        }}
      />
    </div>
  );
};
/*eslint-enable */
DropDown.propTypes = {
  steps: PropTypes.array,
  step: PropTypes.object,
  handleNext: PropTypes.func,
  autoCompleteText: PropTypes.string,
  viewValue: PropTypes.string,
  fieldValue: PropTypes.object,
  formData: PropTypes.object,
  dispatch: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    formData: getFormData(state),
  };
}

export default connect(mapStateToProps)(DropDown);
