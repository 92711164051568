import accounting from 'accounting';
import * as formatTypes from './formatTypes';

export const dateFormatter = (val) => {
  if (val === undefined || val.length === 0) return;

  return val;
};

export const currencyFormatter = (val) => {
  if (val === undefined || val.length === 0) return;

  return accounting.formatMoney(val, '$', 0);
};

export const phoneFormatter = (val, extended) => {
  if (val === undefined || val.length === 0) return;
  if (extended) {
    const s2 = ('' + val).replace(/\D/g, '');
    const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !m ? null : '1 (' + m[1] + ') ' + m[2] + '-' + m[3];
  }
  return val;
};

export const ssnDashFormatter = (val) => {
  if (val === undefined || val.length === 0) return;

  return val;
};

export const percentFormatter = (val) => {
  if (val === undefined || val.length === 0) return;

  return `${val}%`;
};

export const noopFormatter = (val) => {
  return val;
};

export const fileSizeFormatter = (number) => {
  if (number < 1024) {
    return number + ' bytes';
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + ' Kb';
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + ' Mb';
  }
};

const numberWithCommasFormatter = (number) => {
  if (number === undefined || number.length === 0) return;

  return accounting.formatNumber(number);
};

export const getFormatter = (type) => {
  switch (type) {
    case formatTypes.DATE:
    case formatTypes.DOB:
      return dateFormatter;
    case formatTypes.CURRENCY:
      return currencyFormatter;
    case formatTypes.PHONE:
      return phoneFormatter;
    case formatTypes.SSN:
      return ssnDashFormatter;
    case formatTypes.PERCENT:
      return percentFormatter;
    case formatTypes.NUMBERWITHCOMMAS:
      return numberWithCommasFormatter;
    default:
      return noopFormatter;
  }
};
