import React, { useEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';

import ModalContainer from './ModalContainer';

/**
 * To avoid infinite render loops, it's extremely important
 * that the onHide and onShow callbacks are wrapped in a
 * useCallback, or some other method that guarantees their
 * references won't change between renders. You've been warned!
 */
const Modal = ({
  children,
  show = false,
  onHide,
  onShow,
  role = 'dialog',
  container = document.body,
  ...props
}) => {
  const containerNode = parseContainer(container);
  const initialRenderRef = useRef(true);

  useEffect(() => {
    if (show === true) {
      callCallback(onShow);
    } else if (initialRenderRef.current !== true) {
      callCallback(onHide);
    }
  }, [show, onShow, onHide]);

  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
    }
  }, [initialRenderRef.current]);

  const handleBackdropClick = useCallback(() => {
    if (role === 'dialog') {
      callCallback(onHide);
    }
  });

  const modal = (
    <FocusLock>
      <ModalContainer
        backdropProps={{ onClick: handleBackdropClick }}
        {...props}
      >
        {children}
      </ModalContainer>
    </FocusLock>
  );

  return show === true ? createPortal(modal, containerNode) : null;
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  role: PropTypes.oneOf(['modal', 'dialog']),
  container: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Element),
  ]),
};

const callCallback = (callback) => {
  if (typeof callback === 'function') {
    callback.call(callback);
  }
};

const parseContainer = (container) => {
  if (typeof container === 'string') {
    return document.querySelector(container);
  }

  return container;
};

export default Modal;
