import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as formActions from '../../actions/formActions';
import Steps from './steps';
import TermsAndConditions from './termsAndConditions';
import { Form, actions } from 'react-redux-form';
import ProgressOverlay from '../common/ProgressOverlay';
import FormBusy from '../common/FormBusy';
import ContinueButton from '../common/CtaButton';
import CrossSell from './crossSell';
import FormAutoSubmit from '../common/FormAutoSubmit';
import SecurityMessages from '../common/SecurityMessages';
import * as stackAction from '../../common/stackactiontypes';
import Navigation from '../common/FormNavigation';
import PropTypes from 'prop-types';
import {
  getCurrentStepInfo,
  getFormDefinition,
  getFormData,
  getFormController,
  getFormStateController,
  getFormMetaController,
} from '../../selectors/formSelector';
import * as statusType from '../../actions/formActionTypes';
import { getAsyncValidations } from '../../validators/validators';
import { createBrowserHistory } from 'history';
import * as logtypes from '../../common/logtypes';
import { isInViewport, ltLogger } from '../../bootstrap';
import * as trackingtypes from '../../common/trackingeventnames';
import * as nodetypes from '../forms/stepTypes';
import { sanitizer } from '../../common/sanitizer';
import { getContainer } from '../../index';
import { detectStyles } from '../../utils/detectStyles';
import HiddenFields from '../autoFill/hiddenfields';
import { getStore } from '../../store';
import { sendErrorDataToKafka } from '../../utils/errorReportingService';

const history = createBrowserHistory();

const errorReporting = (inputReq) => {
  sendErrorDataToKafka(inputReq);
};

class CurrentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      firedFirstStepRendered: false,
    };
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleBeforeSubmit = this.handleBeforeSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitFailed = this.handleSubmitFailed.bind(this);
    this.handleAutoSubmit = this.handleAutoSubmit.bind(this);
  }

  componentDidUpdate() {
    const { formState } = this.props;
    if (
      formState.formStatus &&
      formState.formStatus !== statusType.FORM_LOADING
    ) {
      if (!this.state.firedFirstStepRendered) {
        setTimeout(function () {
          requestAnimationFrame(() => {
            window.dispatchEvent(new CustomEvent('ltPhoenixFormReady'));
            detectStyles(); // PHX-6180: detect if styles are being loaded
            if (window.initTUBlackbox) {
              window.initTUBlackbox();
            }
            if (window.initTUDigitalIdentity) {
              window.initTUDigitalIdentity();
            }
          });
        });
        // Allowable componentDidUpdate state update, as we're updating sentinel value
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ firedFirstStepRendered: true });
      }
    }
  }

  async handleNext(data) {
    try {
      if (this.state.processing) return;
      let {
        dispatch,
        displayedSteps,
        formState: { warnings },
      } = this.props;
      this.setState({ processing: true });
      //get step validations for each step
      let asyncValidations = [];
      let stepsFailed = [];

      for (let step of displayedSteps) {
        // if(formController[step.attributeid] && formController[step.attributeid].validating)
        //   return;
        const externalValidations = getAsyncValidations(step.validation);

        if (
          (step.required ||
            (data[step.attributeid] && data[step.attributeid].length)) &&
          externalValidations
        ) {
          for (let i = 0; i < externalValidations.length; i++) {
            asyncValidations.push({
              attributeid: step.attributeid,
              validationtype: externalValidations[i].validationtype,
              validationlevel: externalValidations[i].validationlevel,
              validationcallback: externalValidations[i].validationcallback,
            });
          }
        }
      }

      for (let validator of asyncValidations) {
        if (stepsFailed.includes(validator.attributeid)) {
          continue;
        }

        // If there is an existing warning for an attribute, this means that it's been validated once
        // already and failed as a warning.  If the field has not been updated since the last submission
        // attempt (retouched), then we can skip this validation step for the attribute.  The enter key
        // triggers retouched, in this case need to check if valid and validated are true - this indicates
        // no value update and can be submitted.
        let { retouched, valid, validated } =
          this.props.formController[validator.attributeid];
        if (
          warnings[validator.attributeid] &&
          (!retouched || (valid && validated))
        ) {
          continue;
        }

        dispatch(
          actions.resetValidity(`formData.${validator.attributeid}`, [
            validator.validationtype,
          ])
        );
        dispatch(actions.setValidating(`formData.${validator.attributeid}`));
        const result = await validator.validationcallback(
          data[validator.attributeid]
        );

        if (!result) {
          errorReporting({
            modelValue: data[validator.attributeid],
            key: `External validation ${validator.validationtype}`,
          });
          let dispatchObject = {};
          dispatchObject[validator.validationtype] = false;

          if (validator.validationlevel === 'warn') {
            // Update state with new warning
            dispatch(
              formActions.setFormWarning({ [validator.attributeid]: true })
            );
          }

          dispatch(
            actions.setValidity(
              `formData.${validator.attributeid}`,
              dispatchObject
            )
          );
          dispatch(
            actions.setValidating(`formData.${validator.attributeid}`, false)
          );

          stepsFailed.push(validator.attributeid);
        }
        dispatch(
          actions.setValidating(`formData.${validator.attributeid}`, false)
        );
      }

      let isRadioListOrMultipart;
      displayedSteps.forEach(function (item) {
        isRadioListOrMultipart =
          item.validation &&
          (item.nodetype === nodetypes.FORM_RADIO ||
            item.nodetype === nodetypes.FORM_MULTIPART);
        dispatch(actions.setSubmitted(`formData.${item.attributeid}`));
      });
      if (isRadioListOrMultipart) {
        this.props.formDefinition.allsteps.forEach((step) => {
          if (
            !this.props.displayedSteps.find(
              (item) => item.attributeid === step.attributeid
            )
          )
            dispatch(actions.setSubmitted(`formData.${step.attributeid}`));
        });
      }
      window.ltPhoenix.stepSubmitFailed = false;
      dispatch(formActions.handleNextSteps(displayedSteps, stackAction.PUSH));
      this.setState({ processing: false });
    } catch (error) {
      this.setState({ processing: false });
      ltLogger(logtypes.ERROR, undefined, {}, undefined, {
        error: error,
      });
      if (
        window.ltPhoenix.callbacks &&
        window.ltPhoenix.callbacks.onCriticalError
      ) {
        window.ltPhoenix.callbacks.onCriticalError();
      }
    }
  }

  handlePrevious() {
    //temporary tracking code //todo: remove this after data gathered
    ltLogger(
      logtypes.TRACK,
      trackingtypes.FORM_NAVIGATION_BACK_CLICKED,
      this.props.formMeta
    );

    let { dispatch, displayedSteps, formData } = this.props;

    // When going back in the form, if an input has been touched but
    // not filled out - we want to reset touched so when the user
    // moves forward again the input doesn't display as an error
    displayedSteps.forEach(({ attributeid }) => {
      if (!formData[attributeid]) {
        dispatch(actions.setUntouched(`formData.${attributeid}`));
      }
    });

    //end temp
    if (window.ltPhoenix && window.ltPhoenix.hasPreviousStep) history.goBack();
    else {
      dispatch(formActions.handleNextSteps(displayedSteps, stackAction.POP));
    }
  }

  handleBeforeSubmit() {
    let { displayedSteps, dispatch } = this.props;
    displayedSteps.forEach(function (item) {
      if (item.nodetype === nodetypes.FORM_UPLOAD) {
        dispatch(
          actions.resetValidity(`formData.${item.attributeid}`, [
            'maxNumberOfUploads',
          ])
        );
      }
    });

    // If a field hasn't been updated/touched, hitting enter will not fire handleNext,
    // instead, the form is submitted.  To allow submission of the attribute in warning
    // state, the validity of the field needs to be reset so the submit will fire.
    if (this.props.formState.warnings) {
      for (let item in this.props.formState.warnings) {
        let value = this.props.formState.warnings[item];
        if (value) {
          dispatch(actions.resetValidity(`formData.${item}`));
        }
      }
    }
  }

  handleSubmit() {
    //get all reduxformsteps not on this step and reset validity
    this.props.formDefinition.allsteps.forEach((step) => {
      if (
        !this.props.displayedSteps.find(
          (item) => item.attributeid === step.attributeid
        )
      )
        this.props.dispatch(
          actions.resetValidity(`formData.${step.attributeid}`)
        );
    });
    if (document.getElementById('phoenixform'))
      document.getElementById('phoenixform').submit();
  }

  handleAutoSubmit() {
    this.handleSubmit();
  }

  handleSubmitFailed() {
    let { dispatch, displayedSteps, formMeta, formController } = this.props;
    if (formMeta.autoSubmitStatus) {
      dispatch({ type: statusType.FORM_AUTO_SUBMIT_FAIL, status: false });
    }
    let invalidStep = null;
    window.ltPhoenix.stepSubmitFailed = true;
    if (window.ltDeadclickQueue) {
      window.ltDeadclickQueue = [];
    }
    displayedSteps.forEach(function (item) {
      if (
        !invalidStep &&
        item.attributeid &&
        formController[item.attributeid] &&
        !formController[item.attributeid].valid
      ) {
        try {
          invalidStep = document.querySelector(
            `div.ltFormAppContainer .ltFormGroup.${CSS.escape(
              item.attributeid
            )}`
          );
          if (
            !window.disableAutoScroll &&
            invalidStep &&
            invalidStep.scrollIntoView &&
            !isInViewport(invalidStep)
          ) {
            invalidStep.scrollIntoView();
          }
        } catch (err) {
          ltLogger(logtypes.ERROR, undefined, {}, undefined, {
            error: err,
          });
        }
      }
      dispatch(actions.setDirty(`formData.${item.attributeid}`));
    });

    //check if there's a confirmation checkbox which needs to brought into view
    if (
      !invalidStep &&
      formController['confirmInfo'] &&
      !formController['confirmInfo'].valid
    ) {
      invalidStep = document.querySelector(
        `div.ltFormAppContainer .ltSummaryParent .checkBoxContainer`
      );
      if (
        !window.disableAutoScroll &&
        invalidStep &&
        invalidStep.scrollIntoView &&
        !isInViewport(invalidStep)
      ) {
        invalidStep.scrollIntoView();
      }
    }
  }

  // The HTML being injected is from the server and piped through
  // DOMPurify, we are safe to disable the no-danger linter rule
  /*eslint-disable react/no-danger*/
  render() {
    const {
      formDefinition,
      formState,
      displayedSteps,
      formController,
      formData,
      formMeta: {
        vertical = '',
        autoSubmit,
        autoSubmitStatus,
        autoSubmitMissingProperties,
      },
    } = this.props;
    const { components: { header, footer } = {} } = formDefinition;
    const { formStatus, displayText } = formState;
    if (autoSubmit && autoSubmitStatus) {
      if (
        formStatus === statusType.FORM_LOADING ||
        formStatus === statusType.FORM_SUBMITTING
      ) {
        return (
          <div className="ltFormContainer">
            <FormAutoSubmit
              showModal={true}
              formStatus={formStatus}
              autoSubmitStatus={autoSubmitStatus}
            />
          </div>
        );
      }
    }
    if (formDefinition.formId === null || formDefinition.formId.length === 0)
      return null;

    const currentStep = displayedSteps[0];
    if (
      formStatus ===
      statusType.FORM_LOADING /*|| formStatus === statusType.FORM_PROCESSING*/
    ) {
      if (!window.disableAutoScroll) {
        if (getContainer() && !isInViewport(getContainer())) {
          getContainer().scrollIntoView();
        } else {
          window.scrollTo(0, 0);
        }
      }
      let contentOverride = undefined;
      if (
        formData.processingTextOverride &&
        typeof formData.processingTextOverride === 'string'
      ) {
        try {
          let result = eval(formData.processingTextOverride);
          if (typeof result === 'string') {
            contentOverride = result;
          }
        } catch (err) {
          contentOverride = undefined;
        }
      }
      const content = contentOverride
        ? contentOverride
        : currentStep && currentStep.processingtext
        ? currentStep.processingtext
        : null;
      return (
        <div className="ltFormContainer">
          <FormBusy content={content} />
        </div>
      );
    }

    if (currentStep === undefined) return null;

    // PHX-6506: Check if step has usesnewterms, and requiresJornaya //
    if (
      currentStep &&
      currentStep?.usesnewterms &&
      currentStep?.requiresJornaya
    ) {
      delete window.LeadIdDataCaptured;
      delete window.trustedJFormCallback;
    }
    const preBtnText = displayedSteps.slice(-1)[0].preCTAText;
    const actionBtnText = displayedSteps.slice(-1)[0].buttontext || 'Continue';
    const disabled = displayedSteps.some(
      (_step) =>
        (formController[_step.attributeid] &&
          (formController[_step.attributeid].validating === true ||
            formStatus === statusType.FORM_BUSY)) ||
        formStatus === statusType.FORM_LOADING ||
        formStatus === statusType.FORM_SUBMITTING
    );
    if (
      formDefinition.formScripts &&
      Array.isArray(formDefinition.formScripts)
    ) {
      formDefinition.formScripts.forEach((scriptElem) => {
        const existingScript = document.getElementById(scriptElem.name);
        if (!existingScript) {
          let formscript = document.createElement('script');
          formscript.innerHTML = scriptElem.text;
          formscript.id = scriptElem.name;
          document.body.appendChild(formscript);
        }
      });
    }

    return (
      <div
        className={vertical.length ? vertical.split('.').join(' ') : null}
        data-tf-element-role={
          currentStep.requiresActiveProspect ? 'offer' : undefined
        }
      >
        {header && (
          <header
            className="ltFormHeader"
            dangerouslySetInnerHTML={{ __html: sanitizer(header) }}
          />
        )}
        <div className="ltFormNavWrapper">
          <div className="ltFormBack">
            {displayedSteps[0].disableBack ? null : (
              <Navigation
                direction="back"
                text=""
                action={this.handlePrevious}
              />
            )}
          </div>
          <Form
            id="phoenixform"
            model="formData"
            className={`ltFormContainer${
              formStatus == statusType.FORM_SHOW_EXPLAIN_SIDEBAR
                ? ' explainSideBar'
                : ''
            } ${currentStep.attributeid} ${currentStep.name}`}
            onBeforeSubmit={() => this.handleBeforeSubmit()}
            onSubmit={(data) => this.handleNext(data)}
            onSubmitFailed={(data) => this.handleSubmitFailed(data)}
          >
            {!autoSubmit && formStatus === statusType.FORM_SUBMITTING ? (
              vertical.toLowerCase().indexOf('personal') > -1 &&
              (!displayText || displayText.length <= 0) ? (
                <div className="ltFormOverlay">
                  <FormBusy content="" />
                </div>
              ) : (
                <ProgressOverlay
                  displayText={displayText}
                  vertical={vertical}
                />
              )
            ) : null}
            {(autoSubmit && autoSubmitStatus) ||
            formStatus === statusType.FORM_AUTO_SUBMIT_FAIL ? (
              <FormAutoSubmit
                showModal={true}
                formStatus={formStatus}
                displayText={displayText}
                autoSubmitStatus={autoSubmitStatus}
                missingProperties={autoSubmitMissingProperties}
              />
            ) : null}
            <Steps
              containerClass={`ltFormGroup ${currentStep.attributeid}`}
              steps={displayedSteps}
              handleNext={this.handleSubmit}
              showExplain={formStatus == statusType.FORM_SHOW_EXPLAIN_SIDEBAR}
              disabled={disabled}
              warnings={formState.warnings}
              AutomobileLoanType={formData.AutomobileLoanType}
            />
            {/* PHX-6484: Show error if no lenders are checked for SLC disclosure step */}
            {currentStep.hasSLCDisclosures &&
            currentStep.SLCLenderList?.length > 0 &&
            (!getStore().getState().formMeta.consentedToPartnerIds ||
              getStore().getState().formMeta.consentedToPartnerIds.length ===
                0) ? (
              <>
                <div className="ltFormHelpBlock ltHasError ltErrorText slcCheckboxesError">
                  <span>
                    You must select at least one lender below to receive rates.
                    The more lenders you select, the more you could save.
                  </span>
                </div>
              </>
            ) : null}
            {currentStep.terms &&
            currentStep.showcontinue &&
            currentStep.moveCtaBelowTerms ? (
              <TermsAndConditions
                markup={currentStep.terms_conditions}
                additionalClasses={['aboveCTATermsConditions']}
                SLCLenderList={
                  currentStep.SLCLenderList ? currentStep.SLCLenderList : null
                }
              />
            ) : null}
            {currentStep.showcontinue ? (
              <ContinueButton
                disabled={disabled}
                linkClass={`ltFormButton ${currentStep.attributeid}`}
                classExt={currentStep.attributeid}
                preText={preBtnText}
                text={actionBtnText}
                action={this.handleSubmit}
              />
            ) : null}
            {currentStep.securityMessages &&
            currentStep.securityMessages.length > 0 ? (
              <SecurityMessages messages={currentStep.securityMessages} />
            ) : null}
            {currentStep.xselltext ? (
              <CrossSell content={currentStep.xselltext} />
            ) : null}
            {currentStep.autofill ? (
              <HiddenFields currentAutofillStep={currentStep} />
            ) : null}
          </Form>
          <div className="ltFormForward">
            <Navigation
              direction="forward"
              text=""
              action={this.handleSubmit}
            />
          </div>
        </div>
        {currentStep.terms && !currentStep.moveCtaBelowTerms ? (
          <TermsAndConditions
            markup={currentStep.terms_conditions}
            SLCLenderList={
              currentStep.SLCLenderList ? currentStep.SLCLenderList : null
            }
          />
        ) : null}
        {footer && (
          <footer
            className="ltFormFooter"
            dangerouslySetInnerHTML={{ __html: sanitizer(footer) }}
          />
        )}
      </div>
    );
  }
}

CurrentForm.propTypes = {
  formModel: PropTypes.object,
  formDefinition: PropTypes.object,
  formData: PropTypes.object,
  formMeta: PropTypes.object,
  actions: PropTypes.object,
  formController: PropTypes.object,
  formState: PropTypes.object,
  displayedSteps: PropTypes.array,
  dispatch: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    formDefinition: getFormDefinition(state),
    formData: getFormData(state),
    displayedSteps: getCurrentStepInfo(state),
    formController: getFormController(state), //.forms.formData
    formState: getFormStateController(state),
    formMeta: getFormMetaController(state),
  };
}

function mapDispatchToProps(dispatch, getState) {
  return {
    actions: bindActionCreators(formActions, dispatch),
    dispatch: dispatch,
    getState: getState,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentForm);
