import React from 'react';
import { Control, actions, Errors } from 'react-redux-form';
import { transform } from '../../../utils/stepFunctions';
import TokenizedText from '../tokenizedText';
import TokenizedHtml from '../tokenizedHtml';
import {
  getValidations,
  getValidationMessages,
} from '../../../validators/validators';
import Subtext from '../subText';
import PropTypes from 'prop-types';
import { updateMultipleAttributesMap } from '../../../actions/formActions';
import { titleCase } from '../../../utils/titleCase';
import GenericError from './genericError';
import { createBrowserHistory } from 'history';
import { reportFieldEngagement } from '../../../bootstrap';

const errorComponentClassName = 'ltErrorText';
const history = createBrowserHistory();

const reportHandledClick = function (
  stepName,
  methodName,
  startOrFinish,
  timeStamp,
  message
) {
  if (
    stepName &&
    methodName &&
    startOrFinish &&
    window.ltPhoenix.handledClicks
  ) {
    if (!window.ltPhoenix.handledClicks[stepName]) {
      window.ltPhoenix.handledClicks[stepName] = [];
    }
    window.ltPhoenix.handledClicks[stepName].push({
      methodName,
      startOrFinish,
      timeStamp,
      message,
    });
  }
};

const controlHasError = ({ valid, retouched, touched, pristine }) =>
  (touched || retouched) && !pristine && !valid;

const isEmptyEnumeration = (enumeration) =>
  !enumeration || enumeration.length < 1;

const goBack = () => history.goBack();
/*eslint-disable react/no-multi-comp,react/no-danger*/
const selectedWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.children;
  delete inputProps.submitted;
  delete inputProps.touched;
  delete inputProps.modelValue;
  return (
    <label
      shouldforwarduser={props.shouldforwarduser}
      htmlFor={props.htmlFor}
      tabIndex="0"
      className={
        props.autoFocus &&
        (props.submitted ||
          (props.modelValue + props.name === props.htmlFor && props.touched))
          ? 'selected'
          : null
      }
    >
      <input {...inputProps} />
      {props.children}
    </label>
  );
};

selectedWrapper.propTypes = {
  htmlFor: PropTypes.string,
  autoFocus: PropTypes.bool,
  submitted: PropTypes.bool,
  children: PropTypes.array,
  modelValue: PropTypes.string,
  touched: PropTypes.bool,
  name: PropTypes.string,
  shouldforwarduser: PropTypes.string,
};

const RadioList = ({ step, steps, handleNext }) => {
  if (isEmptyEnumeration(step.enumeration)) {
    const clickHandler = (e) => {
      if (e.target.tagName === 'A') goBack();
    };

    const errorMsg = step.emptylistitemsmsg;

    return errorMsg && errorMsg !== '' ? (
      <div>
        <GenericError
          errorMessage={
            <div
              onClick={clickHandler}
              dangerouslySetInnerHTML={{ __html: errorMsg }}
            />
          }
        />
      </div>
    ) : null;
  }

  const ltFormGroupContentRadioIcon = step.radioButtonIconsSettings
    ? ' ltFormGroupContentRadioIcon'
    : '';

  return (
    <div
      className={`ltFormGroupContent${titleCase(
        step.nodetype,
        0
      )}${ltFormGroupContentRadioIcon}`}
    >
      {step.enumeration
        .slice(0)
        .sort((a, b) => {
          return a.displayOrder - b.displayOrder;
        })
        .map((choice) => {
          let stepClasses =
            step.attributeid +
            (choice.classnames ? ` ${choice.classnames}` : '');
          const ltIconClass =
            step.radioButtonIconsSettings && choice.buttonIcon
              ? ` ltIconBtn ${choice.buttonIcon}`
              : '';
          const radioButtonStyle =
            choice.radioButtonStyle && choice.radioButtonStyle !== 'ButtonIcon'
              ? ` ${choice.radioButtonStyle}`
              : '';
          return choice.displayOrder < 0 ? null : (
            <div
              className={`ltFormRadioControl ltFormRadioControl${choice.value} ${stepClasses}${radioButtonStyle}${ltIconClass}`}
              key={choice.value}
            >
              <div
                className={`ltFormRadioControlInner ${choice.value} ${step.attributeid}`}
              >
                {step.actionFormatter === undefined ? (
                  <div
                    className={`ltFormRadioControlEnumHeader ${choice.value} ${step.attributeid}`}
                    dangerouslySetInnerHTML={{ __html: choice.enumLabelHtml }}
                  />
                ) : (
                  <Control
                    model={`formData.${step.actionAttribute}`}
                    component={(props) => (
                      <TokenizedHtml
                        token={step.actionAttribute}
                        formatter={step.actionFormatter}
                        text={choice.enumLabelHtml}
                        className={`ltFormRadioControlEnumHeader${choice.value} ${step.attributeid}`}
                      >
                        {props}
                      </TokenizedHtml>
                    )}
                    mapProps={{
                      fieldValue: (props) => props.fieldValue,
                    }}
                  />
                )}
                <Control.radio
                  shouldforwarduser={
                    !step.disableAutoforward ? 'true' : 'false'
                  }
                  name={step.attributeid}
                  id={`${choice.value}${step.attributeid}`}
                  component={selectedWrapper}
                  model={`formData.${step.attributeid}`}
                  className={`ltFormRadioButton ${choice.value} ${step.attributeid}`}
                  value={choice.value}
                  validators={
                    step.validation
                      ? getValidations(step.validation, step.formatter)
                      : {}
                  }
                  changeAction={(model, value, event) =>
                    (dispatch, getState) => {
                      reportHandledClick(
                        step.attributeid,
                        'changeAction',
                        'start',
                        Date.now()
                      );
                      if (event && value !== event.currentValue) {
                        reportHandledClick(
                          step.attributeid,
                          'changeAction',
                          'finish',
                          Date.now(),
                          'value does not equal current value'
                        );
                        return;
                      }
                      if (
                        getState().forms.formData[step.attributeid] !==
                          undefined &&
                        getState().forms.formData[step.attributeid].pristine ===
                          false &&
                        event === undefined
                      ) {
                        reportHandledClick(
                          step.attributeid,
                          'changeAction',
                          'finish',
                          Date.now(),
                          'event undefined'
                        );
                        return;
                      }
                      let enums = getState().formDefinition.stepSet.find(
                        (step) => step.attributeid === model.split('.')[1]
                      ).enumeration;
                      const selectedEnum = enums.find((e) => e.value === value);
                      let modify = enums.find(
                        (item) =>
                          item.value == value &&
                          item.actionAttribute !== undefined
                      );
                      if (modify !== undefined) {
                        let currVal =
                          getState().formData[modify.actionAttribute];
                        model = `formData.${modify.actionAttribute}`;
                        value =
                          modify.action != undefined
                            ? transform(
                                modify.action,
                                getState().formData[modify.actionAttribute],
                                modify.actionValue
                              ).toString()
                            : currVal || value;
                        event.currentValue = value;
                      }
                      if (step.actionsafterchange) {
                        step.actionsafterchange
                          .filter((action) => action.onselectmap)
                          .forEach((action) => {
                            return dispatch(
                              updateMultipleAttributesMap(
                                action.onselectmap,
                                selectedEnum
                              )
                            );
                          });
                      }
                      if (value !== step.value) {
                        reportFieldEngagement(step.attributeid, value);
                      }
                      dispatch(actions.change(model, value));
                      if (
                        !step.disableAutoforward &&
                        event &&
                        steps.length === 1 &&
                        event.currentValue === value
                      ) {
                        reportHandledClick(
                          step.attributeid,
                          'changeAction',
                          'finish',
                          Date.now(),
                          'handleNext called'
                        );
                        handleNext();
                      } else {
                        reportHandledClick(
                          step.attributeid,
                          'changeAction',
                          'finish',
                          Date.now(),
                          'handleNext not called'
                        );
                      }
                    }}
                  mapProps={{
                    autoFocus: (props) => props.modelValue === choice.value,
                    htmlFor: `${choice.value}${step.attributeid}`,
                    submitted: ({ fieldValue }) => fieldValue.submitted,
                    modelValue: (props) => props.modelValue,
                    touched: ({ fieldValue }) => fieldValue.touched,
                    onClick: (props) => () => {
                      //hackery to get onchange to happen when value is same. React 15.6+ changed behavior
                      reportHandledClick(
                        step.attributeid,
                        'onClick',
                        'start',
                        Date.now()
                      );
                      if (props.value === props.controlProps.value) {
                        props.dispatch(actions.setTouched(props.model));
                        props.dispatch(
                          props.changeAction(props.model, props.value, {
                            currentValue: props.controlProps.value,
                          })
                        );
                      }
                      reportHandledClick(
                        step.attributeid,
                        'onClick',
                        'finish',
                        Date.now()
                      );
                    },
                  }}
                >
                  <i className={`ltFormRadioIcon ${step.attributeid}`} />
                  {step.actionFormatter === undefined ? (
                    <div dangerouslySetInnerHTML={{ __html: choice.label }} />
                  ) : (
                    <Control
                      model={`formData.${step.actionAttribute}`}
                      component={(props) => (
                        <TokenizedText
                          token={step.actionAttribute}
                          text={choice.label}
                          formatter={step.actionFormatter}
                        >
                          {props}
                        </TokenizedText>
                      )}
                      mapProps={{
                        fieldValue: (props) => props.fieldValue,
                      }}
                    />
                  )}
                </Control.radio>
              </div>
            </div>
          );
        })}{' '}
      <Control.text
        model={`formData.${step.attributeid}`}
        component={(props) => (
          <Subtext
            text={step.help}
            forceShow={step.persistHelpText}
            classExt={step.attributeid}
          >
            {props}
          </Subtext>
        )}
        mapProps={{
          fieldValue: (props) => props.fieldValue,
        }}
      />
      <Errors
        className={`ltFormHelpBlock ltHasError ${step.attributeid} ${errorComponentClassName}`}
        wrapper="div"
        show={(field) => controlHasError(field)}
        model={`formData.${step.attributeid}`}
        messages={getValidationMessages(step.validation)}
      />
    </div>
  );
};
/*eslint-enable */
RadioList.propTypes = {
  step: PropTypes.object,
  steps: PropTypes.array,
  handleNext: PropTypes.func,
  fieldValue: PropTypes.object,
  modelValue: PropTypes.object,
  controlProps: PropTypes.object,
  viewValue: PropTypes.string,
  value: PropTypes.string,
  dispatch: PropTypes.func,
  model: PropTypes.object,
  changeAction: PropTypes.func,
};

export default RadioList;
