import React from 'react';
import PropTypes from 'prop-types';
import useBodyScrollLock from '../../hooks/useBodyScrollLock';
import ModalBackdrop from './ModalBackdrop';

const modalClassName = 'ltModal';
const modalContainerClassName = 'ltModalContainer';

const commonModalStyle = {
  position: 'fixed',
  inset: 0,
};

const ModalContainer = ({
  children,
  className,
  backdropProps = {},
  ...props
}) => {
  useBodyScrollLock();

  const combinedClassName = (
    className ? [modalClassName, className] : [modalClassName]
  ).join(' ');

  const combinedBackdropStyle = {
    ...(backdropProps.style || {}),
    ...commonModalStyle,
  };

  return (
    <div className={modalContainerClassName} style={commonModalStyle}>
      <ModalBackdrop {...backdropProps} style={combinedBackdropStyle} />
      <div {...props} className={combinedClassName}>
        {children}
      </div>
    </div>
  );
};

ModalContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  backdropProps: PropTypes.object,
};

export default ModalContainer;
