import React, {Component} from 'react';
import PropTypes from 'prop-types';
class CrossSell extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    let {content} = this.props;
    let extractscript = /<script>([\s\S]*)<\/script>/gi.exec(content);
    if (extractscript !== null) {
      content = content.replace(extractscript[0], "");
      new Function(extractscript[1])();
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.content!==nextProps.content){
      let {content} = nextProps;
      let extractscript = /<script>([\s\S]*)<\/script>/gi.exec(content);
      if (extractscript !== null) {
        content = content.replace(extractscript[0], "");
        new Function(extractscript[1])();
      }
    }
  }

  /*eslint-disable react/no-danger*/
  render() {
    let {content} = this.props;
    return (
      <div className={`ltFormXSellText`} dangerouslySetInnerHTML={{__html: content}} />
    );
  }
  /*eslint-enable */
}
CrossSell.propTypes = {
  content: PropTypes.string
};

export default CrossSell;
