import React from 'react';
import { Control, actions } from 'react-redux-form';
import PropTypes from 'prop-types';
import Subtext from '../subText';
import { reportFieldEngagement } from '../../../bootstrap';
/*eslint-disable react/no-multi-comp,react/no-danger*/

const SelectedWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.children;
  delete inputProps.submitted;
  delete inputProps.touched;
  delete inputProps.modelValue;

  const hasValue =
    props.autoFocus &&
    (props.submitted || (props.modelValue === props.htmlFor && props.touched));

  return (
    <label
      htmlFor={props.htmlFor}
      tabIndex="0"
      className={hasValue ? 'selected' : null}
    >
      {props.imageurl == null ? (
        <div className="defaultImage" />
      ) : (
        <img src={props.imageurl} className="continueIcon" />
      )}
      <input {...inputProps} />
      {props.children}
    </label>
  );
};

SelectedWrapper.propTypes = {
  htmlFor: PropTypes.number,
  autoFocus: PropTypes.bool,
  submitted: PropTypes.bool,
  children: PropTypes.object,
  modelValue: PropTypes.string,
  touched: PropTypes.bool,
  imageurl: PropTypes.string,
};

class VehicleDiv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.loadMoreOrLess = this.loadMoreOrLess.bind(this);
  }
  loadMoreOrLess() {
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  }
  render() {
    const { expanded } = this.state;
    const { maxTopMakeOrder, fetchedVehicleMakes } = this.props;
    const itemsToShow = !expanded
      ? maxTopMakeOrder
      : fetchedVehicleMakes.length;
    return (
      <div>
        {fetchedVehicleMakes.slice(0, itemsToShow).map((item) => (
          <div
            key={item.makeName}
            className={`ltFormVehicleMakeControl ltFormVehicleMakeControl${item.makeId}`}
          >
            <Control.radio
              name={item.makeName}
              id={item.makeId}
              model={`formData.VehicleMake`}
              className={`ltFormRadioButton ${
                window.LT5theme === true ? 'ltFormRadioIcon ' : ''
              }${item.makeName}`}
              component={SelectedWrapper}
              value={item.makeName}
              changeAction={(model, value, event) => (dispatch) => {
                if (event && value !== event.currentValue) {
                  return;
                }
                reportFieldEngagement('VehicleMake', value);
                //Need to find better way to handle passing VehicleMakeId as model
                dispatch(actions.change(`formData.VehicleMakeId`, item.makeId));
                dispatch(actions.change(model, value));
                this.props.parentProps.handleNext();
              }}
              mapProps={{
                autoFocus: (props) => props.modelValue === item.makeName,
                htmlFor: item.makeId,
                imageurl: item.logoUrl,
                submitted: ({ fieldValue }) => fieldValue.submitted,
                modelValue: (props) => props.modelValue,
                touched: ({ fieldValue }) => fieldValue.touched,
                onClick: (props) => () => {
                  if (props.value === props.controlProps.value) {
                    props.dispatch(actions.setTouched(props.model));
                    props.dispatch(
                      props.changeAction(props.model, props.value, {
                        currentValue: props.controlProps.value,
                      })
                    );
                  }
                },
              }}
            >
              <div
                className="labelText"
                dangerouslySetInnerHTML={{ __html: item.makeName }}
              />
            </Control.radio>
          </div>
        ))}
        <div>
          <div className="seeMore" onClick={this.loadMoreOrLess}>
            {this.state.expanded ? (
              <span>{`${
                window.LT5theme === true ? 'See Less' : 'SEE LESS'
              }`}</span>
            ) : (
              <span>{`${
                window.LT5theme === true ? 'See More' : 'SEE MORE'
              }`}</span>
            )}
          </div>
        </div>
        <Control.text
          model={`formData.VehicleMake`}
          component={(props) => (
            <Subtext
              text={this.props.parentProps.step.help}
              classExt={this.props.parentProps.step.attributeid}
            >
              {props}
            </Subtext>
          )}
          mapProps={{
            fieldValue: (props) => props.fieldValue,
          }}
        />
      </div>
    );
  }
}

/*eslint-enable */
VehicleDiv.propTypes = {
  handleNext: PropTypes.func,
  fieldValue: PropTypes.object,
  modelValue: PropTypes.object,
  controlProps: PropTypes.object,
  value: PropTypes.string,
  model: PropTypes.object,
  changeAction: PropTypes.func,
  fetchedVehicleMakes: PropTypes.array,
  parentProps: PropTypes.object,
  maxTopMakeOrder: PropTypes.number,
};
export default VehicleDiv;
