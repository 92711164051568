export const FORM_TEXT = 'text';
export const SOFT_OFFER = 'SoftOffer';
export const PRODUCT_SELECTION = 'ProductSelection';
export const LABEL_NODE = 'LabelNode';
export const FORM_SELECT = 'select';
export const FORM_RADIO = 'radio';
export const FORM_RANGE = 'range';
export const FORM_DATE ='date';
export const FORM_CURRENCY ='currency';
export const FORM_PHONE = 'tel';
export const FORM_PROCESS ='process';
export const FORM_BRANCH = 'branch';
export const FORM_COMBOBOX = 'combobox';
export const FORM_DATE_SCROLL = 'scroll';
export const FORM_MULTIPART = "multipart";
export const FORM_ACTION = "action";
export const FORM_UPLOAD = "upload";
export const FORM_CHECKBOXES = "checkboxes";
export const FORM_BUTTON = "button";
export const FORM_VEHICLE_MAKE = "vehicleMake";
export const FORM_VEHICLE_MODEL = "vehicleModel";
export const FORM_VEHICLE_TRIM = "vehicleTrim";
export const FORM_TOGGLE = 'toggle';
export const FORM_COMPONENT = 'component';
