window._nsr_handshake = function(sessionId, onSuccess, onFail) {
    //let headerError, headerSuccess;
    try {
        if (!sessionId) {
            /*generate a unique session ID*/
            let d = new Date().getTime();
            if (window.performance && typeof window.performance.now === "function") {
                d += performance.now();
            }
            let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
                function(c) {
                    let r = (d + Math.random() * 16) % 16 | 0;
                    d = Math.floor(d / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
            sessionId = uuid;
        }
        let pixel = document.createElement("img");
        pixel.setAttribute('src', '//mver.agkn.com/dir/v1/?clientid=9212802438&sessionid=' + sessionId);
        pixel.setAttribute('alt', 'na');
        pixel.setAttribute('height', '0px');
        pixel.setAttribute('width', '0px');
        pixel.setAttribute("style", "display:none;");
        pixel.onload = function() {
            if (onSuccess) onSuccess(sessionId);
        };
        pixel.onerror = function(e) {
            if (onFail) onFail('nsr pixel failed to load: ' + e);
        };
        document.body.appendChild(pixel);
    } catch (e) {
        if (onFail) onFail('Error syncing NSR cookie:  ' + e);
    }
};