if (!Headers.prototype.entries) {
  const polybrowsersupport = {
    iterable: 'Symbol' in self && 'iterator' in Symbol
  };

  Headers.prototype.entries = function () {
    let items = [];
    this.forEach(function (value, name) {
      items.push([name, value]);
    });
    let iterator = {
      next: function () {
        let value = items.shift();
        return {done: value === undefined, value: value};
      }
    };

    if (polybrowsersupport.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator;
      };
    }

    return iterator;
  };

  if (polybrowsersupport.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }
}
