/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  analyticsIsLoaded,
  getMappedTrackProperties,
} from '../../utils/ltAnalyticsService';
import { getFormMetaController } from '../../selectors/formSelector';

// PHX-4647: A component to detect if the user has an ad blocker enabled
const DetectAdBlock = (props) => {
  const [adBlockDetected, setAdBlockDetected] = useState(false);
  const { pathname, formMeta } = props;

  useEffect(() => {
    // URL to test if ads are being blocked
    const adUrl = 'https://www3.doubleclick.net';

    if (formMeta.formUuid) {
      fetch(adUrl, {
        referrer: window.location.href,
        referrerPolicy: 'origin-when-cross-origin',
        method: 'HEAD',
        mode: 'no-cors',
        cache: 'no-store',
        credentials: 'same-origin',
        headers: {
          'x-referrer': window.location.href,
        },
      })
        .then(() => {
          setAdBlockDetected(false);
        })
        .catch(() => {
          adBlockDetected;
          setAdBlockDetected(true);
          // Ad blocker detected, send to Kafka
          fetch(
            `${window.location.origin}/path/api/track/FormAdblocked?logkafka=1`,
            {
              method: 'POST',
              mode: 'cors',
              cache: 'no-cache',
              credentials: 'same-origin',
              headers: {
                'Content-Type': 'application/json',
                'x-referrer': window.location.href + '?logkafka=1',
              },
              body: JSON.stringify({
                url: window.location.href,
                ClickSessionId: analyticsIsLoaded()
                  ? getMappedTrackProperties().ClickSessionId
                  : '',
                TreeAnonymousTransientUid: analyticsIsLoaded()
                  ? getMappedTrackProperties().TreeAnonymousTransientUid
                  : '',
                qformuid: formMeta.formUuid,
              }),
            }
          ).then(() => console.log('Ad Blocker: Detected'));
        });
    }
  }, [pathname, formMeta.formUuid]);

  // Currently not rendering a component.
  // Might do this later to indicate to the user that they have an ad blocker running.
  return null;
};

DetectAdBlock.propTypes = {
  pathname: PropTypes.string,
};
function mapStateToProps(state) {
  return {
    formMeta: getFormMetaController(state),
  };
}
export default connect(mapStateToProps)(DetectAdBlock);
