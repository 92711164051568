export const FORM_DEFAULT = 'FORM_DEFAULT';
export const FORM_NAVIGATION = 'FORM_NAVIGATION';
export const FORM_LOADING = 'FORM_LOADING';
export const STEP_LOADED = 'STEP_LOADED';
export const OUT_OF_WALLET = 'OUT_OF_WALLET';
export const FORM_SUBMITTING = 'FORM_SUBMITTING';
export const FORM_AUTO_SUBMIT_FAIL = 'FORM_AUTO_SUBMIT_FAIL';
export const FORM_SUBMIT_FAILURE = 'FORM_SUBMIT_FAILURE';
export const FORM_SAVING = 'FORM_SAVING';
export const FORM_SAVE_FAILURE = 'FORM_SAVE_FAILURE';
export const FORM_SAVED = 'FORM_SAVED';
export const FORM_VALIDATING = 'FORM_VALIDATING';
export const FORM_VALIDATED = 'FORM_VALIDATED';
export const FORM_PROCESSING = 'FORM_PROCESSING';
export const FORM_PROCESSED = 'FORM_PROCESSED';
export const FORM_PROCESS_FAILURE = 'FORM_PROCESS_FAILURE';
export const FORM_DEFINITION_UPDATE = 'FORM_DEFINITION_UPDATE';
export const FORM_STEP_UPDATE = 'FORM_STEP_UPDATE';
export const FORM_SHOW_EXPLAIN_SIDEBAR = 'FORM_SHOW_EXPLAIN_SIDEBAR';
export const FORM_STATE_UPDATE = 'FORM_STATE_UPDATE';
export const FORM_BUSY = 'FORM_BUSY';
export const FORM_SET_WARNING = 'FORM_SET_WARNING';
