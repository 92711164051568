import React from 'react';
import { Errors, Control } from 'react-redux-form';
import * as stepFuncs from '../../../utils/stepFunctions';
import Subtext from '../subText';
import ValidationIndicator from '../validationIndicator';
import {
  getValidations,
  getValidationMessages,
} from '../../../validators/validators';
import MultiPartControl from '../controls/multiPartControl';
import PropTypes from 'prop-types';

import useSSNMultipartFormat from './multipart-formats/useSSNMultipartFormat';
import usePhoneMultipartFormat from './multipart-formats/usePhoneMultipartFormat';
import useLast4SSNMultipartFormat from './multipart-formats/useLast4SSNMultipartFormat';

const errorComponentClassName = 'ltErrorText';
const useDefaultMappedProps = ({ steps, handleNext, step }) => {
  const { formatter, attributeid, multipartcontrol } = step;
  const dividerChar = stepFuncs.getDividerForFormat(formatter);
  // sample values of arrangement: 'MDY', 'DMY', 'YMD'
  const { arrangement = 'MDY' } = multipartcontrol;
  const combineParts = (val) => {
    const getPartValue = (initial) =>
      val['box' + (arrangement.indexOf(initial) + 1)];
    const month = getPartValue('M'),
      day = getPartValue('D'),
      year = getPartValue('Y');
    return `${month}${dividerChar}${day}${dividerChar}${year}`;
  };
  const splitIntoParts = (val) => {
    const partValuesMap = {
      M:
        val.indexOf(dividerChar) > -1
          ? val.split(dividerChar)[0]
          : val.substring(0, 3),
      D:
        val.indexOf(dividerChar) > -1
          ? val.split(dividerChar)[1]
          : val.substring(3, 6),
      Y:
        val.indexOf(dividerChar) > -1
          ? val.split(dividerChar)[2]
          : val.substring(6, 10),
    };
    return {
      box1value: partValuesMap[arrangement[0]],
      box2value: partValuesMap[arrangement[1]],
      box3value: partValuesMap[arrangement[2]],
    };
  };

  return {
    inputClass: ({ fieldValue }) =>
      fieldValue.valid || fieldValue.pristine || !fieldValue.touched
        ? `ltFormControlMulti`
        : `ltFormControlMulti ltHasError`,
    wrapperClass: ({ fieldValue }) =>
      fieldValue.valid || fieldValue.pristine || !fieldValue.touched
        ? `ltFormControlMultiWrapper`
        : `ltFormControlMultiWrapper ltHasError`,
    value: (props) => (props.viewValue ? props.viewValue : ''),
    onupdate: (props) => props.onChange,
    handleValueCombine: () => (val) => combineParts(val),
    splitFunction: () => (val) => splitIntoParts(val),
    submitFunc: () => (steps.length === 1 ? handleNext : () => {}),
    uniqueId: attributeid,
    jumpRules: stepFuncs.getJumpRulesForFormat(formatter),
    placeholders: {
      box1Placeholder: multipartcontrol.box1.placeholder,
      box2Placeholder: multipartcontrol.box2.placeholder,
      box3Placeholder: multipartcontrol.box3.placeholder,
    },
    controlDefs: multipartcontrol,
    mask: step.mask,
    disableAutoforward: step.disableAutoforward,
    validation: step.validation,
  };
};

const useMappedProps = (props) => {
  const { step } = props;
  const { formatter } = step;
  const defaultMappedProps = useDefaultMappedProps(props);

  switch (formatter) {
    case 'ssn':
      return {
        ...defaultMappedProps,
        ...useSSNMultipartFormat(props),
      };
    case 'tel':
      return {
        ...defaultMappedProps,
        ...usePhoneMultipartFormat(props),
      };
    case 'last4ssn':
      return {
        ...defaultMappedProps,
        ...useLast4SSNMultipartFormat(props),
      };
    default:
      return defaultMappedProps;
  }
};

const MultiPartControlRrf = ({ steps, step, handleNext, autoFocus }) => {
  const mappedProps = useMappedProps({ steps, step, handleNext });

  return (
    <div>
      <Control
        id={`formData.${step.attributeid}`}
        model={`formData.${step.attributeid}`}
        component={MultiPartControl}
        validators={getValidations(
          step.validation,
          step.formatter,
          step.enumeration
        )}
        validateOn={['change']}
        mapProps={mappedProps}
        autoFocus={autoFocus}
      />
      <Control.text
        model={`formData.${step.attributeid}`}
        component={(props) => (
          <ValidationIndicator classExt={step.attributeid}>
            {props}
          </ValidationIndicator>
        )}
        mapProps={{
          fieldValue: (props) => props.fieldValue,
        }}
      />
      <Errors
        component={(props) => (
          <div
            className={`ltHasError ${step.attributeid} ${errorComponentClassName}`}
          >
            {props.children}
          </div>
        )}
        wrapper="div"
        show={(field) => field.touched && !field.pristine && !field.validating}
        model={`formData.${step.attributeid}`}
        messages={getValidationMessages(step.validation)}
      />
      <Control.text
        model={`formData.${step.attributeid}`}
        component={(props) => (
          <Subtext
            text={step.help}
            forceShow={step.persistHelpText}
            classExt={step.attributeid}
          >
            {props}
          </Subtext>
        )}
        mapProps={{
          fieldValue: (props) => props.fieldValue,
        }}
      />
    </div>
  );
};

MultiPartControlRrf.propTypes = {
  type: PropTypes.string,
  onupdate: PropTypes.func,
  step: PropTypes.object,
  concatChar: PropTypes.string,
  steps: PropTypes.array,
  handleNext: PropTypes.func,
  viewValue: PropTypes.string,
  onChange: PropTypes.func,
  fieldValue: PropTypes.object,
  children: PropTypes.array,
  autoFocus: PropTypes.bool,
};

export default MultiPartControlRrf;
