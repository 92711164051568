export const PII_FIELDS = [
  'BusinessName',
  'BusinessStreetAddressLine1',
  'BusinessStreetAddressLine2',
  'BusinessTaxId',
  'city',
  'DealerAddressZipCode',
  'DealerName',
  'DealerSalesPhoneNumber',
  'DealerStreetAddressLine3',
  'DigitalSignature',
  'DOB',
  'email',
  'EmailAddress',
  'first-name',
  'FirstName',
  'home-phone',
  'last-name',
  'Last4SSN',
  'LastName',
  'loan-state',
  'Password',
  'PhoneNumber',
  'SSN',
  'state',
  'stateName',
  'street1',
  'StreetAddress',
  'StreetAddressLine1',
  'StreetAddressLine2',
  'zip-code',
  'ZipCode',
];

export const isPII = (attributeid) => {
  return PII_FIELDS.find(
    (item) => item.toLowerCase() === attributeid.toLowerCase()
  );
};

export const maskPII = (value) => {
  return value.replace(new RegExp('[a-zA-Z0-9]{1}', 'gi'), function ($1) {
    // Get a random number.
    if (Math.random() > 0.6) {
      return '*';
    } else {
      return $1;
    }
  });
};

export const concealPII = (value) => {
  let cleanValue = {};
  for (let i in value) {
    cleanValue[i] = isPII(i) ? 'pii-field' : value[i];
  }
  return cleanValue;
};
