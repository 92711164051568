import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  formStatusShowingExplainText,
  formStatusReset,
} from '../../actions/formActions';
import PropTypes from 'prop-types';
class Explain extends Component {
  constructor() {
    super();
    this.handleShow = this.handleShow.bind(this);
  }

  handleShow() {
    if (!this.props.show) this.props.dispatch(formStatusShowingExplainText());
    else this.props.dispatch(formStatusReset());
  }

  render() {
    let { content, show, classExt, buttonText = 'OK' } = this.props;
    const explainTextPositions = ['topRight', 'headline'];
    buttonText = buttonText === '' ? 'OK' : buttonText;
    if (content?.length === 0) {
      return (null);
    }
    return (
      <div
        className={(content && content.length > 0) ? `ltFormExplain ${classExt}` : null}
        style={window.LT5theme ? { display: 'inline' } : {}}
      >
        {window.ltExplainTextPosition && explainTextPositions.includes(window.ltExplainTextPosition) && content && content.length > 0 ? (
          <a onClick={this.handleShow}>
            <span className="ltFormExplainIcon">
              <i />
            </span>
          </a>
        ) : null}
        {show ? (
          <aside className="ltSidebar">
            <div className="content">
              {/*eslint-disable-next-line react/no-danger*/}
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <a className="next" onClick={this.handleShow}>
                {buttonText}
              </a>
            </div>
          </aside>
        ) : null}
      </div>
    );
  }
}
Explain.propTypes = {
  content: PropTypes.string,
  show: PropTypes.bool,
  dispatch: PropTypes.func,
  classExt: PropTypes.string,
  buttonText: PropTypes.string,
};
export default connect()(Explain);
