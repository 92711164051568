import React from 'react';
import { Errors, Control, actions } from 'react-redux-form';
import Subtext from '../subText';
import {
  getValidations,
  getValidationMessages,
} from '../../../validators/validators';
import { titleCase } from '../../../utils/titleCase';
import PropTypes from 'prop-types';
import { reportFieldEngagement } from '../../../bootstrap';
/*eslint-disable react/no-multi-comp,react/no-danger*/

const errorComponentClassName = 'ltErrorText';
const selectedWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.children;
  delete inputProps.submitted;
  return (
    <label tabIndex="0" className={props.checked ? 'selected' : null}>
      <input {...inputProps} />
      {props.children}
    </label>
  );
};

selectedWrapper.propTypes = {
  checked: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.array,
};

const Checkboxes = ({ step }) => {
  const isSingleCheckbox = step.nodetype === 'toggle';
  const model = `formData.${step.attributeid + (isSingleCheckbox ? '' : '[]')}`;

  return (
    <div className={`ltFormGroupContent${titleCase(step.nodetype, 0)}`}>
      {step.enumeration
        .slice(0)
        .sort((a, b) => {
          return a.displayOrder - b.displayOrder;
        })
        .map((choice) =>
          choice.displayOrder < 0 ? null : (
            <div
              className={`ltFormCheckBoxControl ltFormCheckBoxControl${choice.value} ${step.attributeid}`}
              key={choice.value}
            >
              <Control.checkbox
                component={selectedWrapper}
                model={model}
                value={choice.value}
                validators={getValidations(
                  step.validation,
                  step.formatter,
                  step.enumeration
                )}
                changeAction={(model, value) => (dispatch, getState) => {
                  // get current selections
                  let currentSelections =
                    getState().formData[`${step.attributeid}`];

                  // For toggle, currentSelections is a boolean. For checkboxes, it is an array.
                  let isRemoval = isSingleCheckbox
                    ? currentSelections
                    : currentSelections &&
                      currentSelections.indexOf(value) !== -1;

                  if (currentSelections && value) {
                    let reportValue;
                    // Zach James :: PHX-5081 :: INFO / TECH-DEBT
                    // This is based on WhoDoYouBankWith
                    // 0 is the value for "None", which clears the list, so we should only report the 0
                    // We also need to check if currentSelections contains 0 & is length 1
                    // This would indicate the array was previously cleared and will now only contain the incoming value
                    if (
                      value === '0' ||
                      (currentSelections.length === 1 &&
                        currentSelections[0] === '0')
                    ) {
                      reportValue = isRemoval ? [] : [value];
                    } else {
                      // Otherwise, we want to report the full array and the incoming value
                      reportValue = isRemoval
                        ? currentSelections.filter((entry) => entry !== value)
                        : [...currentSelections, value];
                    }
                    reportFieldEngagement(step.attributeid, reportValue);
                  } else if (value) {
                    reportFieldEngagement(step.attributeid, [value]);
                  }
                  // update the checkbox model
                  if (isSingleCheckbox) {
                    dispatch(actions.change(model, value));
                  } else {
                    dispatch(actions.xor(model, value));
                    dispatch(
                      actions.validate(
                        model,
                        getValidations(
                          step.validation,
                          step.formatter,
                          step.enumeration
                        )
                      )
                    );
                  }

                  if (
                    step.checkboxUnselectOthersValue &&
                    Array.isArray(currentSelections)
                  ) {
                    let isChecked = !(
                      currentSelections && currentSelections.includes(value)
                    );

                    if (isChecked) {
                      if (value === step.checkboxUnselectOthersValue) {
                        // user just checked a "None of the Above / Clear other selections" checkbox -> so clear the other checkboxes
                        dispatch(
                          actions.filter(
                            model,
                            (i) => i === step.checkboxUnselectOthersValue
                          )
                        );
                      } else {
                        // user clicked on a normal checkbox -> so uncheck the "None of the Above / Clear other selections" checkbox if it is checked
                        dispatch(
                          actions.filter(
                            model,
                            (i) => i !== step.checkboxUnselectOthersValue
                          )
                        );
                      }
                    }
                  }
                }}
              >
                <i className={`ltFormCheckBoxIcon ${step.attributeid}`} />
                <div dangerouslySetInnerHTML={{ __html: choice.label }} />
              </Control.checkbox>
            </div>
          )
        )}
      <Errors
        component={(props) => (
          <div
            className={`ltHasError ${step.attributeid} ${errorComponentClassName}`}
          >
            {props.children}
          </div>
        )}
        wrapper="div"
        show={(field) => field.touched && !field.pristine && !field.validating}
        model={`formData.${step.attributeid}[]`}
        messages={getValidationMessages(step.validation)}
      />
      <Control.text
        model={`formData.${step.attributeid}[]`}
        component={(props) => (
          <Subtext
            text={step.help}
            forceShow={step.persistHelpText}
            classExt={step.attributeid}
          >
            {props}
          </Subtext>
        )}
        mapProps={{
          fieldValue: (props) => props.fieldValue,
        }}
      />
    </div>
  );
};
/*eslint-enable */
Checkboxes.propTypes = {
  steps: PropTypes.array,
  parser: PropTypes.func,
  step: PropTypes.object,
  checked: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.array,
  fieldValue: PropTypes.object,
};

export default Checkboxes;
