import { useMemo, useEffect } from 'react';
import config from '../../../../reducers/config';
import { hasAcceptableHostName } from '../../../../utils/url';
import storageFactory from '../../../../utils/storageFactory';
import { ltLogger } from '../../../../bootstrap';
import * as logtypes from '../../../../common/logtypes';

const storage = storageFactory();

export default function useUpdateSession(files, formId, attributeId) {
  const url = useMemo(
    () =>
      `${config.apiConfig.rootApiServerPath}${config.apiConfig.updateSessionUrlPath}${attributeId}`,
    [attributeId]
  );

  useEffect(() => {
    const makeRequest = async () => {
      try {
        const response = await fetch(request);

        if (response.status >= 400) {
          ltLogger(logtypes.ERROR, undefined, {}, undefined, {
            error: new Error(`Error Updating Session for ${attributeId}`),
          });
        }
      } catch (err) {
        ltLogger(logtypes.ERROR, undefined, {}, undefined, {
          error: err,
          metaData: { formId },
        });
      }
    };

    const acceptableHostName = hasAcceptableHostName(url);

    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(files),
      credentials: acceptableHostName ? 'include' : 'omit',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        'x-lt-phoenix': storage.getItem('lt_sessionKey'),
      },
    });

    makeRequest();
  }, [files, formId, attributeId]);
}
