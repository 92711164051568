import React from 'react';
import { Control, actions } from 'react-redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VehicleDiv from '../../forms/controls/vehicleDiv';
import {
  getFormData,
  getFormMetaController,
} from '../../../selectors/formSelector';
import FormApi from '../../../api/formApi';
import * as logtypes from '../../../common/logtypes';
import {
  ltLogger,
  getQueryParams,
  reportFieldEngagement,
} from '../../../bootstrap';
import DataLoader from '../../common/DataLoader';
/*eslint-disable react/no-multi-comp,react/no-danger*/
import storageFactory from '../../../utils/storageFactory';

const storage = storageFactory();

const SelectedWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.children;
  delete inputProps.submitted;
  delete inputProps.touched;
  delete inputProps.modelValue;

  const isSelected =
    props.autoFocus &&
    (props.submitted || (props.modelValue === props.htmlFor && props.touched));

  return (
    <label
      htmlFor={props.htmlFor}
      tabIndex="0"
      className={isSelected ? 'selected' : null}
    >
      <input {...inputProps} />
      {props.children}
    </label>
  );
};

SelectedWrapper.propTypes = {
  htmlFor: PropTypes.string,
  autoFocus: PropTypes.bool,
  submitted: PropTypes.bool,
  children: PropTypes.object,
  modelValue: PropTypes.string,
  touched: PropTypes.bool,
};

let makeOrderOverride = [];
let getTopMakeCountAndMakeCollection = (
  makeResponse,
  makeVehicleOrderOverride
) => {
  makeResponse = Array.isArray(makeResponse) ? makeResponse : [];
  let actualTopmakesCount = 0;
  if (makeVehicleOrderOverride.length === 0) {
    return [undefined, makeResponse];
  }
  const makeCollection = makeResponse
    .map((item, index) => {
      const makeFound = makeVehicleOrderOverride.find(
        (makeItem) =>
          makeItem.make.toLowerCase() === item.makeName.toLowerCase()
      );
      const maxTopMakeOrder = makeVehicleOrderOverride.length;
      if (makeFound) {
        actualTopmakesCount++;
        return { ...item, ...{ order: makeFound.order } };
      }
      return { ...item, ...{ order: maxTopMakeOrder + (index + 1) } };
    })
    .sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
  return [actualTopmakesCount, makeCollection];
};

class VehicleMake extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      makes: [],
      topmakesCount: 6,
      purchaseLoanSubType: [
        { label: 'New', value: 'NewCarPurchase' },
        { label: 'Used', value: 'UsedCarPurchase' },
      ],
      isDataLoaded: false,
    };
  }

  componentWillMount() {
    const { formData, dispatch, formMeta } = this.props;
    let loanType =
      formData && formData.AutomobileLoanType
        ? formData.AutomobileLoanType
        : undefined;
    let loanSubType =
      formMeta && formMeta.AutomobileLoanSubType
        ? formMeta.AutomobileLoanSubType
        : undefined;
    if (loanSubType !== undefined) {
      dispatch(actions.setTouched('formMeta.AutomobileLoanSubType'));
      dispatch(actions.change('formMeta.AutomobileLoanSubType', loanSubType));
    }
    if (!loanType) {
      loanType =
        getQueryParams('AutomobileLoanType') ||
        getQueryParams('loan-type') ||
        undefined;
    }
    if (loanType && !loanSubType) {
      loanType = loanType.toUpperCase();
      switch (loanType) {
        case 'PURCHASE':
        case 'NEWCARPURCHASE':
          loanSubType = 'NewCarPurchase';
          dispatch(actions.setTouched('formMeta.AutomobileLoanSubType'));
          break;
        case 'USEDCARPURCHASE':
          loanSubType = 'UsedCarPurchase';
          dispatch(actions.setTouched('formMeta.AutomobileLoanSubType'));
          break;
        case 'REFINANCE':
          loanSubType = 'REFINANCE';
          dispatch(actions.setTouched('formMeta.AutomobileLoanSubType'));
          break;
        case 'BUYOUTLEASE':
          loanSubType = 'BUYOUTLEASE';
          dispatch(actions.setTouched('formMeta.AutomobileLoanSubType'));
          break;
      }
      dispatch(actions.change('formMeta.AutomobileLoanSubType', loanSubType));
    }

    this.setState({ loanSubType });
  }

  componentDidMount() {
    const { step } = this.props;
    let { topmakes } = step;
    let topMakesSequence = topmakes ? topmakes.split(',') : [];
    makeOrderOverride = topMakesSequence
      .reduce(
        (unique, item) =>
          unique.includes(item) ? unique : [...unique, item.trim()],
        []
      )
      .map((make, index) => {
        return { make, order: index + 1 };
      });
    this.fetchVehicleMakes(this.state.loanSubType, makeOrderOverride);
  }

  getVehicleCondition = (selectedLoanType) => {
    switch (selectedLoanType) {
      case 'NewCarPurchase':
        return 'new';
      case 'UsedCarPurchase':
      case 'REFINANCE':
      case 'BUYOUTLEASE':
        return 'used';
      default:
        return null;
    }
  };

  fetchVehicleMakes = (selectedLoanType, makeOrderOverride) => {
    let vehicleCondition = this.getVehicleCondition(selectedLoanType);
    let currentComponent = this;
    currentComponent.setState({ isDataLoaded: false });

    return FormApi.fetchVehicleMakes(
      vehicleCondition,
      storage.getItem('lt_sessionKey')
    )
      .then((data) => {
        if (data && data.response && data.response.error) {
          ltLogger(logtypes.ERROR, undefined, {}, undefined, {
            error: new Error(data.response.error),
          });
          return null;
        }
        let makeResponse = data ? (data.response ? data.response : data) : [];
        //If no makes is configured in formbuilder then show 6 top makes from top by default.
        const [topmakesCount = 6, orderedMakeCollection] =
          getTopMakeCountAndMakeCollection(makeResponse, makeOrderOverride);
        currentComponent.setState({
          makes: orderedMakeCollection,
          topmakesCount,
          isDataLoaded: true,
        });
      })
      .catch((error) => {
        ltLogger(logtypes.ERROR, undefined, {}, undefined, { error: error });
      });
  };

  render() {
    const { isDataLoaded, purchaseLoanSubType } = this.state;
    const { formMeta } = this.props;
    return !isDataLoaded ? (
      <DataLoader />
    ) : (
      <div>
        {(formMeta.AutomobileLoanSubType === 'NewCarPurchase' ||
          formMeta.AutomobileLoanSubType === 'UsedCarPurchase') &&
          purchaseLoanSubType.map((item) => (
            <div
              key={item.value}
              className={`ltFormRadioControl ltFormRadioControl${item.value}`}
            >
              <Control.radio
                name={item.value}
                id={item.value}
                model={`formMeta.AutomobileLoanSubType`}
                className={`ltFormRadioButton ${item.value}`}
                component={SelectedWrapper}
                value={item.value}
                changeAction={(model, value, event) => (dispatch) => {
                  if (event && value !== event.currentValue) {
                    return;
                  }
                  reportFieldEngagement('AutomobileLoanSubType', value);
                  dispatch(actions.change(model, value));
                  if (
                    event &&
                    this.props.steps.length === 1 &&
                    event.currentValue === value
                  ) {
                    this.fetchVehicleMakes(value, makeOrderOverride);
                  }
                }}
                mapProps={{
                  autoFocus: (props) => props.modelValue === item.value,
                  htmlFor: item.value,
                  submitted: ({ fieldValue }) => fieldValue.submitted,
                  modelValue: (props) => props.modelValue,
                  touched: ({ fieldValue }) => fieldValue.touched,
                  onClick: (props) => () => {
                    if (props.value === props.controlProps.value) {
                      props.dispatch(actions.setTouched(props.model));
                      props.dispatch(
                        props.changeAction(props.model, props.value, {
                          currentValue: props.controlProps.value,
                        })
                      );
                    }
                  },
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: item.label }} />
              </Control.radio>
            </div>
          ))}
        <VehicleDiv
          fetchedVehicleMakes={this.state.makes}
          maxTopMakeOrder={this.state.topmakesCount}
          handleNext={this.props.handleNext}
          parentProps={this.props}
        />
      </div>
    );
  }
}

/*eslint-enable */
VehicleMake.propTypes = {
  step: PropTypes.object,
  steps: PropTypes.array,
  handleNext: PropTypes.func,
  fieldValue: PropTypes.object,
  modelValue: PropTypes.object,
  controlProps: PropTypes.object,
  viewValue: PropTypes.string,
  value: PropTypes.string,
  dispatch: PropTypes.func,
  model: PropTypes.object,
  changeAction: PropTypes.func,
  formData: PropTypes.object,
  formMeta: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    formData: getFormData(state),
    formMeta: getFormMetaController(state),
  };
}

export default connect(mapStateToProps)(VehicleMake);
