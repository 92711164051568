/*
 * Checkboxes for SLC Disclosure Lender List. It is part of the T&Cs.
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Control, actions } from 'react-redux-form';
import { reportFieldEngagement } from '../../bootstrap';
import { getStore } from '../../store';

const selectedWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.children;
  delete inputProps.submitted;
  const paddedIndex = String(props.index + 1).padStart(2, '0');
  const consentedIds = getStore().getState().formMeta.consentedToPartnerIds;
  inputProps.checked = !consentedIds || inputProps.checked;
  return (
    <label
      tabIndex="0"
      className={props.checked ? 'selected' : null}
      data-tf-element-role={`consent-opted-advertiser-name-${props.index + 1}`}
    >
      <input
        {...inputProps}
        id={`leadid_tcpa_brandname_${paddedIndex}`}
        data-tf-element-role={`consent-opted-advertiser-input-${
          props.index + 1
        }`}
      />
      <label
        className="boxLabel"
        htmlFor={`leadid_tcpa_brandname_${paddedIndex}`}
      >
        {''}
      </label>
      <label htmlFor={`leadid_tcpa_brandname_${paddedIndex}`}>
        {props.children}
      </label>
    </label>
  );
};

selectedWrapper.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
};

const SlcDisclosureCheckboxes = ({ SLCLenderList }) => {
  let attributeid = 'consentedToPartnerIds';
  const model = `formMeta.${attributeid}[]`;

  // Checkboxes should be checked by default
  useEffect(() => {
    const currentSelections = getStore().getState().formMeta[attributeid];
    if (!currentSelections || currentSelections.length === 0) {
      const initiallenderExternalIds = SLCLenderList?.map(
        (lender) => lender.lenderExternalId
      );
      getStore().dispatch(
        actions.change(`formMeta.${attributeid}`, initiallenderExternalIds)
      );
    }
    getStore().dispatch(actions.validate(model, atLeastOneChecked));
  }, [SLCLenderList, attributeid]);

  // Validator function to check if at least one checkbox is checked
  const atLeastOneChecked = () => {
    return getStore().getState().formMeta.consentedToPartnerIds?.length > 0;
  };

  return (
    <div className={`ltFormGroupContentSlcDisclosureTextboxes`}>
      {SLCLenderList?.map((value, index) => (
        <div key={value.lenderExternalId} className={`slcDisclosure-item`}>
          <Control.checkbox
            component={selectedWrapper}
            value={value.lenderExternalId}
            index={index}
            model={model}
            validators={{ atLeastOneChecked }}
            changeAction={(model, value) => (dispatch, getState) => {
              let currentSelections = getState().formMeta[`${attributeid}`];
              if (currentSelections) {
                let isRemoval = currentSelections.indexOf(value) !== -1;
                reportFieldEngagement(
                  `${attributeid}[lenderExternalId: ${value}]`,
                  isRemoval ? 'unchecked' : 'checked'
                );
              }
              dispatch(actions.xor(model, value));
              dispatch(actions.validate(model, atLeastOneChecked));
            }}
          >
            {value.partnerName}
          </Control.checkbox>
        </div>
      ))}
    </div>
  );
};

SlcDisclosureCheckboxes.propTypes = {
  checked: PropTypes.bool,
  fieldValue: PropTypes.object,
};

export default SlcDisclosureCheckboxes;
