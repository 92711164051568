import rootReducer from '../reducers';
import { persistReducer, createTransform } from 'redux-persist';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

// PHX-5702 :: Zach James :: Info
// This file is used to configure the "persist:root" entry in localStorage
// Specifying a whitelist in persistConfig will cause redux-persist to only save those keys to localStorage
// A blacklist filter can be used to specify nested keys within the whitelisted keys which should be blocked

// Zach James :: TODO :: PII list, we should consolidate them all eventually

const formDataBlockList = [
  'StreetAddress',
  'ZipCode',
  'FirstName',
  'LastName',
  'PhoneNumber',
  'EmailAddress',
  'Password',
  'SSN',
  'Last4SSN',
  'DealerName',
  'DealerStreetAddressLine3',
  'DealerAddressZipCode',
  'DealerSalesPhoneNumber',
  'CreditScore',
  'DOB',
  'BusinessStreetAddressLine1',
  'BusinessStreetAddressLine2',
  'BusinessTaxId',
  'StreetAddressLine1',
  'StreetAddressLine2',
  'DigitalSignature',
  'BusinessName',
  'City',
  'Birthdate',
  'State',
  'EmploymentStatus',
  'PropertyAddressZipCode',
  'PropertyAddressCity',
  'PropertyAddressState',
  'CityState',
  'email',
  'email-address',
  'email_address',
  'first-name',
  'first_name',
  'home-phone',
  'home_phone',
  'homephone',
  'last-name',
  'last_name',
  'Last-4SSN',
  'Last_4SSN',
  'Phone-Number',
  'Phone_Number',
  'PostalCode',
  'primaryhome',
  'primary-home',
  'primary_home',
  'street1',
  'Street-Address',
  'Street_Address',
  'targusRequest',
  'targusResult',
  'tree-auth-name',
  'tree_auth_name',
  'treeAuthId',
];

// These are the parent objects which can contain FormData
// We need to ensure any PII nested in them is removed
const formBlockListParents = [
  '$form.value.formData',
  '$form.initialValue.formData',
  'formData.$form.value',
  'formData.$form.initialValue',
  'formData',
];

let formBlockList = [];
for (let i = 0; i < formBlockListParents.length; i++) {
  formBlockList = formBlockList.concat(
    formDataBlockList.map(
      (attribute) => `${formBlockListParents[i]}.${attribute}`
    )
  );
}
// PHX-5702 :: Zach James :: Tech Debt
// We may be able to make the blocklist above more generic
// e.g. exploring what happens if we remove $form entirely, then working our way into the nested keys

export default (formId) => {
  const transformInboundState = (inboundState) => ({ formId, ...inboundState });
  const transformOutboundState = (outboundState) => {
    for (let item in outboundState.formData) {
      if (item !== '$form') {
        if (
          !outboundState.formData[item].valid &&
          outboundState.formData[item].pristine
        ) {
          delete outboundState.formData[item];
        }
      }
    }
    return outboundState.formId === formId ? { ...outboundState } : {};
  };

  const formIdTransform = createTransform(
    transformInboundState,
    transformOutboundState,
    {
      whitelist: ['formMeta', 'forms'],
    }
  );

  const blockedFormDataFilter = createBlacklistFilter(
    'formData',
    formDataBlockList
  );
  const blockedFormFilter = createBlacklistFilter('forms', formBlockList);

  const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    transforms: [formIdTransform, blockedFormDataFilter, blockedFormFilter],
    whitelist: ['formData', 'formMeta', 'forms'],
  };

  return persistReducer(persistConfig, rootReducer);
};
