import React from 'react';
import { Control, actions } from 'react-redux-form';
import Subtext from '../subText';
import PropTypes from 'prop-types';
import TokenizedText from '../tokenizedText';
import TokenizedHtml from '../tokenizedHtml';
import { transform } from '../../../utils/stepFunctions';
import { titleCase } from '../../../utils/titleCase';
/*eslint-disable react/no-multi-comp,react/no-danger*/
const selectedWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.children;
  delete inputProps.submitted;
  delete inputProps.touched;
  delete inputProps.modelValue;
  return (
    <label
      htmlFor={props.htmlFor}
      tabIndex="0"
      className={
        props.autoFocus &&
        (props.submitted ||
          (props.modelValue === props.htmlFor && props.touched))
          ? 'selected'
          : null
      }
    >
      <input {...inputProps} />
      {props.children}
    </label>
  );
};

selectedWrapper.propTypes = {
  htmlFor: PropTypes.string,
  autoFocus: PropTypes.bool,
  submitted: PropTypes.bool,
  children: PropTypes.array,
  modelValue: PropTypes.string,
  touched: PropTypes.bool,
};

const Interstitial = ({ step, handleNext }) => {
  const ltFormGroupContent = step.optionclickable
  ? `ltFormGroupContent${titleCase(step.nodetype,0)}`
  : '';

  return (
    <div className={`${ltFormGroupContent}`}>
      {step.enumeration
        .slice(0)
        .sort((a, b) => {
          return a.displayOrder - b.displayOrder;
        })
        .map((choice) => {
          const ltIconClass = choice.buttonIcon
            ? ` ltIconBtn ${choice.buttonIcon}`
            : '';
          const radioButtonStyle =
            choice.radioButtonStyle && choice.radioButtonStyle !== 'ButtonIcon'
              ? ` ${choice.radioButtonStyle}`
              : '';
          return choice.displayOrder < 0 ? null : (
            <Control
              model={`formData.${step.attributeid}`}
              key={choice.value}
              value={choice.value}
              component={(props) => (
                <div
                  className={`ltFormRadioControl ltFormRadioControl${choice.value} ${step.attributeid}${radioButtonStyle}${ltIconClass}`}
                  onClick={choice.enumLabelHtml ? props.onClick : null}
                >
                  <div
                    className={`ltFormRadioControlInner ${choice.value} ${step.attributeid}`}
                  >
                    {step.actionFormatter === undefined ? (
                      <div
                        className={`ltFormRadioControlEnumHeader ${choice.value} ${step.attributeid}`}
                        dangerouslySetInnerHTML={{
                          __html: choice.enumLabelHtml,
                        }}
                      />
                    ) : (
                      <Control
                        model={`formData.${step.actionAttribute}`}
                        component={(props) => (
                          <TokenizedHtml
                            token={step.actionAttribute}
                            formatter={step.actionFormatter}
                            text={choice.enumLabelHtml}
                            className={`ltFormRadioControlEnumHeader${choice.value} ${step.attributeid}`}
                          >
                            {props}
                          </TokenizedHtml>
                        )}
                        mapProps={{
                          fieldValue: (props) => props.fieldValue,
                        }}
                      />
                    )}
                    <Control.radio
                      name={choice.value}
                      id={choice.value}
                      component={selectedWrapper}
                      model={`formData.${step.attributeid}`}
                      className={`ltFormRadioButton ${choice.value} ${step.attributeid}`}
                      value={choice.value}
                      changeAction={(model, value, event) =>
                        (dispatch, getState) => {
                          if (event && value !== event.currentValue) {
                            return;
                          }
                          if (
                            getState().forms.formData[step.attributeid] !==
                              undefined &&
                            getState().forms.formData[step.attributeid]
                              .pristine === false &&
                            event === undefined
                          ) {
                            return;
                          }
                          let enums = getState().formDefinition.stepSet.find(
                            (step) => step.attributeid === model.split('.')[1]
                          ).enumeration;
                          let modify = enums.find(
                            (item) =>
                              item.value == value &&
                              item.actionAttribute !== undefined
                          );
                          if (modify !== undefined) {
                            let currVal =
                              getState().formData[modify.actionAttribute];
                            model = `formData.${modify.actionAttribute}`;
                            value =
                              modify.action != undefined
                                ? transform(
                                    modify.action,
                                    getState().formData[modify.actionAttribute],
                                    modify.actionValue
                                  ).toString()
                                : currVal || value;
                            event.currentValue = value;
                          }
                          dispatch(actions.change(model, value));
                          if (event && event.currentValue === value) {
                            handleNext();
                          }
                        }}
                      mapProps={{
                        autoFocus: (props) => props.modelValue === choice.value,
                        htmlFor: choice.value,
                        submitted: ({ fieldValue }) => fieldValue.submitted,
                        modelValue: (props) => props.modelValue,
                        touched: ({ fieldValue }) => fieldValue.touched,
                        onClick: (props) => () => {
                          if (props.value === props.controlProps.value) {
                            props.dispatch(actions.setTouched(props.model));
                            props.dispatch(
                              props.changeAction(props.model, props.value, {
                                currentValue: props.controlProps.value,
                              })
                            );
                          }
                        },
                      }}
                    >
                      <i className={`ltFormRadioIcon ${step.attributeid}`} />
                      {step.actionFormatter === undefined ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: choice.label }}
                        />
                      ) : (
                        <Control
                          model={`formData.${step.actionAttribute}`}
                          component={(props) => (
                            <TokenizedText
                              token={step.actionAttribute}
                              text={choice.label}
                              formatter={step.actionFormatter}
                            >
                              {props}
                            </TokenizedText>
                          )}
                          mapProps={{
                            fieldValue: (props) => props.fieldValue,
                          }}
                        />
                      )}
                    </Control.radio>
                  </div>
                </div>
              )}
              mapProps={{
                fieldValue: (props) => props.fieldValue,
                onClick: (props) => () => {
                  if (
                    props.modelValue === props.viewValue ||
                    props.fieldValue.value === props.fieldValue.initialValue
                  ) {
                    props.dispatch(actions.change(props.model, props.value));
                    handleNext();
                  } else {
                    props.changeAction(props.model, props.value, {
                      currentValue: props.controlProps.value,
                    });
                  }
                },
              }}
            />
          );
        })}
      <Control.text
        model={`formData.${step.attributeid}`}
        component={(props) => (
          <Subtext
            text={step.help}
            forceShow={step.persistHelpText}
            classExt={step.attributeid}
          >
            {props}
          </Subtext>
        )}
        mapProps={{
          fieldValue: (props) => props.fieldValue,
        }}
      />
    </div>
  );
};
/*eslint-enable */
Interstitial.propTypes = {
  step: PropTypes.object,
  handleNext: PropTypes.func,
  fieldValue: PropTypes.object,
  controlProps: PropTypes.object,
  modelValue: PropTypes.object,
  viewValue: PropTypes.string,
  value: PropTypes.string,
  dispatch: PropTypes.func,
  model: PropTypes.object,
  onClick: PropTypes.func,
  changeAction: PropTypes.func,
};

export default Interstitial;
