import {
  /*minLength,*/ maxLength,
  precisionAndScale,
  isValidDecimalFormat,
} from './length';
import {
  greaterThanEqual as rangeLowerBound,
  lessThanEqual as rangeUpperBound,
} from './range';
import { regex as regEx } from './regex';
import { date } from './date';
import { inList } from './inList';
import verificationApi from '../api/verificationApi';
import * as valTypes from './validationTypes';
import { DATE_TYPE } from './dateTypes';
import moment from 'moment';
import { getStore } from '../store';

export const getValidations = (
  validations,
  formatter,
  enumeration,
  transform,
  attributeid
) => {
  let validators = {};
  let isDate = validations && validations.date;
  if (!transform) {
    transform = (it) => it;
  }

  for (let validation in validations) {
    switch (validation) {
      case valTypes.REQUIRED:
        if (
          validations[valTypes.REQUIRED] !== null &&
          validations[valTypes.REQUIRED]
        )
          validators[valTypes.REQUIRED] = (val) =>
            val && transform(`${val}`, validation).length > 0;
        break;
      case valTypes.AUTO_SUGGEST_VALIDATION:
        if (validations[valTypes.AUTO_SUGGEST_VALIDATION]) {
          validators[valTypes.AUTO_SUGGEST_VALIDATION] = (val) =>
            val &&
            enumeration &&
            enumeration.length > 0 &&
            enumeration.find(
              (item) => item.value.toLowerCase() === val.toLowerCase()
            ) !== undefined;
        }
        break;
      case valTypes.DATA_VALUE_LENGTH:
        if (validations[valTypes.DATA_VALUE_LENGTH] !== null) {
          const hasPrecision =
            validations[valTypes.DATA_VALUE_LENGTH].indexOf(',') > 0;
          //If decimal is allowed add one to maxLength for the dot
          let max = hasPrecision
            ? parseInt(validations[valTypes.DATA_VALUE_LENGTH].split(',')[0]) +
              1
            : validations[valTypes.DATA_VALUE_LENGTH];
          validators[valTypes.DATA_MAX_LENGTH] = (val) =>
            val === undefined ||
            `${val}`.length === 0 ||
            (val && checkDataType(val, max));
          if (hasPrecision)
            validators[valTypes.PRECISION_AND_SCALE] = (val) =>
              val === undefined ||
              `${val}`.length === 0 ||
              (val &&
                precisionAndScale(
                  transform(val, validation),
                  validations[valTypes.DATA_VALUE_LENGTH].split(',')[0],
                  validations[valTypes.DATA_VALUE_LENGTH].split(',')[1]
                ) &&
                isValidDecimalFormat(val));
        }
        break;
      case valTypes.DISALLOW_DATES_BEFORE:
        if (validations[valTypes.DISALLOW_DATES_BEFORE]) {
          validators[valTypes.DISALLOW_DATES_BEFORE] = (val) => {
            if (val && val.length > 9) {
              if (
                validations[valTypes.DISALLOW_DATES_BEFORE] ===
                'specific-date-before'
              ) {
                return !moment(val).isBefore(
                  moment(validations[valTypes.DISALLOW_SPECIFIC_DATES_BEFORE])
                );
              } else {
                return !moment(val).isBefore(
                  DATE_TYPE[validations[valTypes.DISALLOW_DATES_BEFORE]]
                );
              }
            }
          };
        }
        break;
      case valTypes.DISALLOW_DATES_AFTER:
        if (validations[valTypes.DISALLOW_DATES_AFTER])
          validators[valTypes.DISALLOW_DATES_AFTER] = (val) => {
            if (val && val.length > 9) {
              if (
                validations[valTypes.DISALLOW_DATES_AFTER] ===
                'specific-date-after'
              ) {
                return !moment(val).isAfter(
                  moment(validations[valTypes.DISALLOW_SPECIFIC_DATES_AFTER])
                );
              } else {
                return !moment(val).isAfter(
                  DATE_TYPE[validations[valTypes.DISALLOW_DATES_AFTER]]
                );
              }
            }
          };
        break;
      case valTypes.RANGE_LOWER_BOUND:
        if (validations[valTypes.RANGE_LOWER_BOUND] !== null) {
          if (isDate)
            validators[valTypes.LOWER_BOUND] = (val) =>
              val === undefined ||
              val.length === 0 ||
              !moment(
                transform(val, validation),
                'MM/DD/YYYY',
                true
              ).isValid() ||
              (val &&
                val.length > 0 &&
                rangeLowerBound(
                  moment().diff(
                    moment(transform(val, validation), 'MM/DD/YYYY', true),
                    'years',
                    true
                  ),
                  validations[valTypes.RANGE_LOWER_BOUND]
                ));
          else
            validators[valTypes.LOWER_BOUND] = (val) =>
              val === undefined ||
              `${val}`.length === 0 ||
              (val &&
                `${val}`.length > 0 &&
                rangeLowerBound(
                  transform(`${val}`, validation),
                  validations[valTypes.RANGE_LOWER_BOUND]
                ));
        }
        break;
      case valTypes.RANGE_UPPER_BOUND:
        if (validations[valTypes.RANGE_UPPER_BOUND] !== null) {
          if (isDate)
            validators[valTypes.UPPER_BOUND] = (val) =>
              val === undefined ||
              val.length === 0 ||
              !moment(
                transform(val, validation),
                'MM/DD/YYYY',
                true
              ).isValid() ||
              (val &&
                val.length > 0 &&
                rangeUpperBound(
                  moment().diff(
                    moment(transform(val, validation), 'MM/DD/YYYY', true),
                    'years',
                    true
                  ),
                  validations[valTypes.RANGE_UPPER_BOUND]
                ));
          else
            validators[valTypes.UPPER_BOUND] = (val) =>
              val === undefined ||
              `${val}`.length === 0 ||
              (val &&
                `${val}`.length > 0 &&
                rangeUpperBound(
                  transform(`${val}`, validation),
                  validations[valTypes.RANGE_UPPER_BOUND]
                ));
        }
        break;
      case valTypes.REGEX:
        if (validations[valTypes.REGEX] !== null)
          validators[valTypes.REGEX] = (val) =>
            val === undefined ||
            val.length === 0 ||
            (val &&
              val.length > 0 &&
              regEx(transform(val, validation), validations[valTypes.REGEX]));
        break;
      case valTypes.DATE:
        if (validations[valTypes.DATE] !== null)
          validators[valTypes.DATE] = (val) => {
            return (
              val === undefined ||
              val.length === 0 ||
              (val &&
                val.length > 0 &&
                date(transform(val, validation), formatter))
            );
          };
        break;
      case valTypes.MIN_FILE_SIZE_BYTES:
        if (validations[valTypes.MIN_FILE_SIZE_BYTES] !== null)
          validators[valTypes.MIN_FILE_SIZE_BYTES] = (val) => {
            return (
              val === undefined ||
              (val &&
                rangeLowerBound(
                  transform(val, validation),
                  validations[valTypes.MIN_FILE_SIZE_BYTES]
                ))
            );
          };
        break;
      case valTypes.MAX_FILE_SIZE_BYTES:
        if (validations[valTypes.MAX_FILE_SIZE_BYTES] !== null)
          validators[valTypes.MAX_FILE_SIZE_BYTES] = (val) => {
            return (
              val === undefined ||
              (val &&
                rangeUpperBound(
                  transform(val, validation),
                  validations[valTypes.MAX_FILE_SIZE_BYTES]
                ))
            );
          };
        break;
      case valTypes.INLIST:
        if (validations[valTypes.INLIST] !== null)
          validators[valTypes.INLIST] = (val) => {
            return (
              val === undefined ||
              (val &&
                inList(
                  transform(val, validation),
                  validations[valTypes.INLIST]
                ))
            );
          };
        break;
      case valTypes.VALID_CODE_TYPE:
        if (
          validations[valTypes.VALID_CODE_TYPE] !== null &&
          validations[valTypes.VALID_CODE_TYPE] === 'INLST'
        ) {
          if (attributeid === 'VehicleMake') {
            validators[valTypes.VALID_CODE_TYPE] = () => true;
          } else {
            validators[valTypes.VALID_CODE_TYPE] = (val) => {
              if (val !== '' && enumeration !== undefined) {
                return (
                  val === undefined ||
                  (val && inList(transform(val, validation), enumeration))
                );
              } else {
                return true;
              }
            };
          }
        }
        break;
      case valTypes.CHECKBOX_MIN_COUNT:
        if (validations[valTypes.CHECKBOX_MIN_COUNT] !== null)
          validators[valTypes.CHECKBOX_MIN_COUNT] = (val) => {
            return (
              typeof val !== 'string' &&
              ((!validations[valTypes.REQUIRED] && !val) ||
                (val &&
                  val.length >=
                    parseInt(validations[valTypes.CHECKBOX_MIN_COUNT])))
            );
          };
        break;
      case valTypes.CHECKBOX_MAX_COUNT:
        if (validations[valTypes.CHECKBOX_MAX_COUNT] !== null)
          validators[valTypes.CHECKBOX_MAX_COUNT] = (val) => {
            return (
              typeof val !== 'string' &&
              ((!validations[valTypes.REQUIRED] && !val) ||
                (val &&
                  val.length <=
                    parseInt(validations[valTypes.CHECKBOX_MAX_COUNT])))
            );
          };
        break;
      default:
        break;
    }
  }
  return validators;
};

export const getAsyncValidations = (verifications) => {
  let verifiers = [];
  for (let verifier in verifications) {
    switch (verifier) {
      case valTypes.ASYNC_VERIFICATIONS:
        {
          // Check if verifications is null...
          if (verifications[valTypes.ASYNC_VERIFICATIONS] !== null) {
            // Check if there's a legacy verification
            if (verifications[valTypes.ASYNC_VERIFICATION] !== null) {
              // if so, check if it's already part of verifications array
              // if it's not, add it
              if (
                !verifications[valTypes.ASYNC_VERIFICATIONS].some(
                  (verification) =>
                    verification.validationtype ===
                    verifications[valTypes.ASYNC_VERIFICATION]
                )
              ) {
                verifications[valTypes.ASYNC_VERIFICATIONS].push({
                  validationtype: verifications[valTypes.ASYNC_VERIFICATION],
                  validationerrortext:
                    verifications[
                      `${verifications[valTypes.ASYNC_VERIFICATION]}Message`
                    ],
                });
              }
            }

            // For every verification, check what type it is and add it to the verifiers
            for (
              let i = 0;
              i < verifications[valTypes.ASYNC_VERIFICATIONS].length;
              i++
            ) {
              let validationEntry = {
                validationtype:
                  verifications[valTypes.ASYNC_VERIFICATIONS][i].validationtype,
                validationlevel:
                  verifications[valTypes.ASYNC_VERIFICATIONS][i]
                    .validationerrorlevel || 'error',
                validationcallback: {},
              };
              // If verifiers doesn't have a validation of this type, create an entry
              if (
                !verifiers.some(
                  (entry) =>
                    entry.validationtype ===
                    verifications[valTypes.ASYNC_VERIFICATIONS][i]
                      .validationtype
                )
              ) {
                switch (
                  verifications[valTypes.ASYNC_VERIFICATIONS][i].validationtype
                ) {
                  case valTypes.ZIP:
                    validationEntry.validationcallback = (val) =>
                      verificationApi.verifyZip(val);
                    verifiers.push(validationEntry);
                    // If multiple validations present for zip then do the genral zip validation first.
                    if (
                      verifiers.length > 1 &&
                      verifiers[0].validationtype ===
                        valTypes.SERVICEABLE_STATE_FROM_ZIP_CODE
                    ) {
                      verifiers.reverse();
                    }
                    break;
                  case valTypes.PHONE:
                    validationEntry.validationcallback = (val) =>
                      verificationApi.verifyPhone(val);
                    verifiers.push(validationEntry);
                    break;
                  case valTypes.EMAIL:
                    validationEntry.validationcallback = (val) =>
                      verificationApi.verifyEmail(val);
                    verifiers.push(validationEntry);
                    break;
                  case valTypes.STATE_CODE_FROM_ZIP:
                    validationEntry.validationcallback = (val) =>
                      verificationApi.verifyStateCodeFromZip(val);
                    verifiers.push(validationEntry);
                    // If multiple validations present for zip then do the genral zip validation first.
                    if (
                      verifiers.length > 1 &&
                      verifiers[0].validationtype ===
                        valTypes.SERVICEABLE_STATE_FROM_ZIP_CODE
                    ) {
                      verifiers.reverse();
                    }
                    break;
                  case valTypes.EQUIFAX_PASSCODE:
                    validationEntry.validationcallback = (val) =>
                      verificationApi.verifyEquifaxPasscode(
                        val,
                        verifications[valTypes.ASYNC_VERIFICATIONS][i]
                          .validationerrortext
                      );
                    verifiers.push(validationEntry);
                    break;
                  case valTypes.CORPORATE_NMLS_ID:
                    validationEntry.validationcallback = (val) =>
                      verificationApi.verifyNmlsId('corporate', val);
                    verifiers.push(validationEntry);
                    break;
                  case valTypes.PERSONAL_NMLS_ID:
                    validationEntry.validationcallback = (val) =>
                      verificationApi.verifyNmlsId('personal', val);
                    verifiers.push(validationEntry);
                    break;
                  case valTypes.SERVICEABLE_STATE_FROM_ZIP_CODE:
                    validationEntry.validationcallback = (val) =>
                      verificationApi.verifyServiceabilityFromZip(val);
                    verifiers.push(validationEntry);
                    break;
                  case valTypes.INDIRECT_SUPPORT:
                    validationEntry.validationcallback = (val) =>
                      verificationApi.verifyIndirectSupport(val);
                    verifiers.push(validationEntry);

                    break;
                  case 'package':
                    break;
                  default:
                    break;
                }
              }
            }
          }
        }
        break;
      case valTypes.ASYNC_VERIFICATION: {
        // Only handle here if it's a legacy form.  i.e. if it doesn't have valTypes.ASYNC_VERIFICATIONS
        if (
          verifications[valTypes.ASYNC_VERIFICATION] !== null &&
          !verifications[valTypes.ASYNC_VERIFICATIONS]
        ) {
          let validationEntry = {
            validationtype: verifications[valTypes.ASYNC_VERIFICATION],
            validationcallback: {},
          };

          switch (verifications[valTypes.ASYNC_VERIFICATION]) {
            case valTypes.ZIP:
              validationEntry.validationcallback = (val) =>
                verificationApi.verifyZip(val);
              verifiers.push(validationEntry);
              break;
            case valTypes.PHONE:
              validationEntry.validationcallback = (val) =>
                verificationApi.verifyPhone(val);
              verifiers.push(validationEntry);
              break;
            case valTypes.EMAIL:
              validationEntry.validationcallback = (val) =>
                verificationApi.verifyEmail(val);
              verifiers.push(validationEntry);
              break;
            case valTypes.STATE_CODE_FROM_ZIP:
              validationEntry.validationcallback = (val) =>
                verificationApi.verifyStateCodeFromZip(val);
              verifiers.push(validationEntry);
              break;
            case 'package':
              break;
          }
        }
        break;
      }
      default:
        break;
    }
  }
  // return verifiers.verification ? verifiers : null;
  return verifiers.length > 0 ? verifiers : null;
};

export const tokenizeValidationMessages = (validationMessages) => {
  const keys = Object.keys(validationMessages);
  keys.forEach((validationMessage) => {
    let value = validationMessages[validationMessage];
    if (value[0] == '{' && value[value.length - 1] == '}') {
      let attributeToLookFor = value.replace(/{|}/g, '');
      let { formData, formMeta } = getStore().getState();
      if (formData[`${attributeToLookFor}`]) {
        validationMessages[validationMessage] =
          formData[`${attributeToLookFor}`];
      } else if (formMeta[`${attributeToLookFor}`]) {
        validationMessages[validationMessage] =
          formMeta[`${attributeToLookFor}`];
      } else {
        validationMessages[validationMessage] = '';
      }
    }
  });
  return validationMessages;
};

export const getValidationMessages = (validations, getVal) => {
  let validatorMessages = {};
  if (!getVal) {
    getVal = (it) => it;
  }
  for (let validation in validations) {
    switch (validation) {
      case valTypes.DATA_VALUE_LENGTH:
        if (
          validations[valTypes.DATA_VALUE_LENGTH] !== null &&
          validations[`${valTypes.DATA_VALUE_LENGTH}Message`] &&
          validations[`${valTypes.DATA_VALUE_LENGTH}Message`] !== null
        ) {
          const hasPrecision =
            validations[valTypes.DATA_VALUE_LENGTH].indexOf(',') > 0;

          if (hasPrecision)
            validatorMessages[valTypes.PRECISION_AND_SCALE] = `${
              validations[`${valTypes.DATA_VALUE_LENGTH}Message`]
            }`;
          else
            validatorMessages[valTypes.DATA_MAX_LENGTH] = `${
              validations[`${valTypes.DATA_VALUE_LENGTH}Message`]
            }`;
        } else {
          validatorMessages[valTypes.DATA_MAX_LENGTH] = '';
        }
        break;
      case valTypes.RANGE_LOWER_BOUND:
        if (
          validations[valTypes.RANGE_LOWER_BOUND] !== null &&
          validations[`${valTypes.RANGE_LOWER_BOUND}Message`] !== null
        )
          validatorMessages[valTypes.LOWER_BOUND] = `${
            validations[`${valTypes.RANGE_LOWER_BOUND}Message`]
          } `;
        break;
      case valTypes.RANGE_UPPER_BOUND:
        if (
          validations[valTypes.RANGE_UPPER_BOUND] !== null &&
          validations[`${valTypes.RANGE_UPPER_BOUND}Message`] !== null
        )
          validatorMessages[valTypes.UPPER_BOUND] = `${
            validations[`${valTypes.RANGE_UPPER_BOUND}Message`]
          } `;
        break;
      case valTypes.DISALLOW_DATES_BEFORE:
        if (validations[valTypes.DISALLOW_DATES_BEFORE]) {
          validatorMessages[valTypes.DISALLOW_DATES_BEFORE] = (val) => {
            if (val && val.length > 9) {
              return (validatorMessages[valTypes.DISALLOW_DATES_BEFORE] = `${
                validations[`${valTypes.DISALLOW_DATES_BEFORE}Message`]
              } `);
            } else {
              return (validatorMessages[valTypes.DISALLOW_DATES_BEFORE] = '');
            }
          };
        }
        break;
      case valTypes.DISALLOW_DATES_AFTER:
        if (validations[valTypes.DISALLOW_DATES_AFTER]) {
          validatorMessages[valTypes.DISALLOW_DATES_AFTER] = (val) => {
            if (val && val.length > 9) {
              return (validatorMessages[valTypes.DISALLOW_DATES_AFTER] = `${
                validations[`${valTypes.DISALLOW_DATES_AFTER}Message`]
              } `);
            } else {
              return (validatorMessages[valTypes.DISALLOW_DATES_AFTER] = '');
            }
          };
        }
        break;
      case valTypes.CHECKBOX_MIN_COUNT:
        if (
          validations[valTypes.CHECKBOX_MIN_COUNT] !== null &&
          validations[`${valTypes.CHECKBOX_MIN_COUNT}Message`] !== null
        )
          validatorMessages[valTypes.CHECKBOX_MIN_COUNT] = `${
            validations[`${valTypes.CHECKBOX_MIN_COUNT}Message`]
          } `;
        break;
      case valTypes.CHECKBOX_MAX_COUNT:
        if (
          validations[valTypes.CHECKBOX_MAX_COUNT] !== null &&
          validations[`${valTypes.CHECKBOX_MAX_COUNT}Message`] !== null
        )
          validatorMessages[valTypes.CHECKBOX_MAX_COUNT] = `${
            validations[`${valTypes.CHECKBOX_MAX_COUNT}Message`]
          } `;
        break;
      case valTypes.ASYNC_VERIFICATIONS:
        if (validations[validation] !== null) {
          // Loop through external validations array
          for (let externalValidator of validations[validation]) {
            // check if null
            if (
              externalValidator.validationtype !== null &&
              externalValidator.validationerrortext !== null
            ) {
              // add to message array
              validatorMessages[externalValidator.validationtype] =
                externalValidator.validationerrortext;
            }
          }
          // Check for legacy verification, verificationMessage field, and whether there's already an entry in validatorMessages of that type
          if (
            validations['verification'] &&
            validations['verificationMessage'] &&
            !validatorMessages[validations['verification']]
          ) {
            validatorMessages[validations['verification']] = (val) => {
              if (val)
                return validations[`verificationMessage`].replace(
                  '{val}',
                  getVal(val, validation)
                );
              else return validations[`verificationMessage`];
            };
          }
        }
        break;
      case valTypes.ASYNC_VERIFICATION:
        if (
          validations[validation] !== null &&
          validations[`${validation}Message`] !== null
        ) {
          if (validation['verifications']) {
            break;
          } else {
            validatorMessages[validations[validation]] = (val) => {
              if (val)
                return validations[`${validation}Message`].replace(
                  '{val}',
                  getVal(val, validation)
                );
              else return validations[`${validation}Message`];
            };
          }
        }
        break;
      case valTypes.VALID_CODE_TYPE:
        if (
          validations[valTypes.VALID_CODE_TYPE] != null &&
          validations[valTypes.VALID_CODE_TYPE] === 'INLST'
        ) {
          if (
            validations[valTypes.REQUIRED] != null &&
            validations[`${valTypes.REQUIRED}Message`] != null
          ) {
            validatorMessages[valTypes.VALID_CODE_TYPE] =
              validations[`${valTypes.REQUIRED}Message`];
          } else {
            validatorMessages[valTypes.VALID_CODE_TYPE] =
              'Invalid choice, please choose from the list.';
          }
        }
        break;
      default:
        if (
          validations[validation] !== null &&
          validations[`${validation}Message`] !== null
        ) {
          validatorMessages[validation] = (val) => {
            if (val)
              return validations[`${validation}Message`].replace(
                '{val}',
                getVal(val, validation)
              );
            else return validations[`${validation}Message`];
          };
        }
        break;
    }
  }
  return tokenizeValidationMessages(validatorMessages);
};

export const getMaxLength = (step) => {
  const def = step.validation['dataValueLength'];
  if (def === null) return undefined;
  const hasPrecision = def.indexOf(',') > 0 && step.isDecimalAllowed;
  const max = hasPrecision
    ? parseInt(def.split(',')[0]) + 1
    : parseInt(def.split(',')[0]);
  return max;
};

export const checkDataType = (value, max) => {
  let isDataValueLengthValid = false;
  if (value instanceof Array) {
    value.forEach((key) => {
      isDataValueLengthValid = maxLength(key, max);
    });
  } else {
    isDataValueLengthValid = maxLength(value, max);
  }
  return isDataValueLengthValid;
};
