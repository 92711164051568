import React from 'react';
import PropTypes from 'prop-types';

const ChatBubble = ({ id, chatSource, chatText, newbubble }) => (
  <div className={newbubble}>
    <div className={chatSource} id={id}>
      <div className="ltChatImg" />
      <p>{chatText}</p>
    </div>
  </div>
);

ChatBubble.propTypes = {
  id: PropTypes.string,
  chatSource: PropTypes.string,
  chatText: PropTypes.string,
  newbubble: PropTypes.string,
};

export default ChatBubble;
