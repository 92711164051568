import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getChatHistoryController,
  getFormStateController,
} from '../../selectors/formSelector';
import ChatBubble from './ChatBubble';
import * as statusType from '../../actions/formActionTypes';

const ChatHistory = (props) => (
  <div>
    {props.chatHistory.map((chatBubble, index) => {
      return (
        <ChatBubble
          key={chatBubble.id}
          {...chatBubble}
          newbubble={index === props.chatHistory.length - 1 ? 'newbubble' : ''}
        />
      );
    })}
    <div
      className={
        props.formState.formStatus === statusType.FORM_LOADING
          ? 'chatformtyping newbubble'
          : ''
      }
    />
  </div>
);

ChatHistory.propTypes = {
  chatHistory: PropTypes.array,
  formState: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    chatHistory: getChatHistoryController(state),
    formState: getFormStateController(state),
  };
}

export default connect(mapStateToProps)(ChatHistory);
