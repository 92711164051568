import React from 'react';
import PropTypes from 'prop-types';
import useWithAttributeId from './useWithAttributeId';
import File from './File';

export default function FileList({ attributeId, fileList, ...props }) {
  const withAttributeId = useWithAttributeId(attributeId);

  return (
    <div className={withAttributeId('ltFileRowWrapper')}>
      {(fileList || []).map((file) => (
        <File key={file.id} {...file} {...props} attributeId={attributeId} />
      ))}
    </div>
  );
}

FileList.propTypes = {
  attributeId: PropTypes.string,
  fileList: PropTypes.array,
};
