import React, {Component} from 'react';
import PropTypes from 'prop-types';
class HiddenFields extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    function ready(fn) {
      if (document.readyState !== 'loading') {
        fn();
      } else {
        document.addEventListener('DOMContentLoaded', fn);
      }
    }
    ready(() => {
      const fnInput = document.getElementById('formData.FirstName');
      const lnInput = document.getElementById('formData.LastName');
      const phoneNumberInput = document.getElementById('formData.PhoneNumber');
      const streetAddressInput = document.getElementById('formData.StreetAddress');
      const emailAddressInput = document.getElementById('formData.EmailAddress');

      const sanitizeFirstName = function (element) {
        element.addEventListener('input', function (event) {
          const nameRegex = /[^a-zA-Z\-']/g;
          const inputValue = element.value.trim();
          const cleanValue = inputValue.replace(nameRegex, '');
          if (cleanValue !== inputValue || cleanValue.length <= 1) {
            return;
          }
          window.ReduxStore.dispatch(window.formActions.change('formData.FirstName', document.getElementById('formData.FirstName').value));
          event.preventDefault();
          event.stopImmediatePropagation();
        });
      };
      const sanitizeLastName = function (element) {
        element.addEventListener('input', function (event) {
          const nameRegex = /[^a-zA-Z\-']/g;
          const inputValue = element.value.trim();
          const cleanValue = inputValue.replace(nameRegex, '');
          if (cleanValue !== inputValue || cleanValue.length <= 1) {
            return;
          }
          window.ReduxStore.dispatch(window.formActions.change('formData.LastName', document.getElementById('formData.LastName').value));
          event.preventDefault();
          event.stopImmediatePropagation();
        });
      };
      const sanitizeEmailAddress = function (element) {
        element.addEventListener('input', function (event) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const emailAddress = element.value.trim();
          const isValidEmailAddress = emailRegex.test(emailAddress);
          if (isValidEmailAddress) {
            window.ReduxStore.dispatch(window.formActions.change('formData.EmailAddress', document.getElementById('formData.EmailAddress').value));
            event.preventDefault();
            event.stopImmediatePropagation();
          }
        });
      };
      function setPhoneNumber(number, event) {
        const numberRegex = /^\d{10}$/;
        if (numberRegex.test(number)) {
          window.ReduxStore.dispatch(window.formActions.change('formData.PhoneNumber', number));
          event.preventDefault();
          event.stopImmediatePropagation();
        }
      }
      const sanitizePhoneNumber = function (element) {
        element.addEventListener('input', function (event) {
          let number = phoneNumberInput.value.replace(/[^\d]/g, '');
          number = number.startsWith('+1')
            ? number.substr(2)
            : (number.startsWith('1')
              ? number.substr(1)
              : number);
          setPhoneNumber(number, event);
         });
      };
      const sanitizeStreetAddress = function (element) {
        element.addEventListener('input', function (event) {
          window.ReduxStore.dispatch(window.formActions.change('formData.StreetAddress', element.value));
          event.preventDefault();
          event.stopImmediatePropagation();
        });
      };

      sanitizeFirstName(fnInput);
      sanitizeLastName(lnInput);
      sanitizePhoneNumber(phoneNumberInput);
      sanitizeStreetAddress(streetAddressInput);
      sanitizeEmailAddress(emailAddressInput);
    });
  }

  /*eslint-disable react/no-danger*/
  render() {
    const { currentAutofillStep } = this.props;
    const isAttributeIdAutofill = currentAutofillStep.attributeid === "FirstName"
      || currentAutofillStep.attributeid === "StreetAddress"
      || currentAutofillStep.attributeid === "PhoneNumber";
    const inputId1 = currentAutofillStep.attributeid === "EmailAddress"
      ? "formData.EmailAddress"
      : "formData.OtherInput";
    const inputId2 = isAttributeIdAutofill
      ? "formData.EmailAddress"
      : "formData.OtherInput";

    return (
      <div style={{ height: 0,  width: 0, overflow : 'hidden' }}>
        {
          currentAutofillStep.attributeid === "EmailAddress"
            ? <input
              id={inputId1}
              type="default"
              autoComplete="email"
            />
            : null
        }
        <input
          id="formData.FirstName"
          type="default"
          autoComplete="given-name"
        />
        <input
          id="formData.LastName"
          type="default"
          autoComplete="family-name"
        />
        {
          isAttributeIdAutofill
            ? <input
              id={inputId2}
              type="default"
              autoComplete="email"
            />
            : null
        }
        <input
          id="formData.PhoneNumber"
          type="default"
          autoComplete="tel"
        />
        <input
          id="formData.StreetAddress"
          type="default"
          autoComplete="street-address"
        />
      </div>
    );
  }
  /*eslint-enable */
}
HiddenFields.propTypes = {
  content: PropTypes.string
};

export default HiddenFields;
