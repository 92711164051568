import React from 'react';
import PropTypes from 'prop-types';
import './softOffer.css';
import {connect} from "react-redux";
import {getFormData} from "../../../selectors/formSelector";

class SoftOfferDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  render() {
    return (
      <div className="softOfferBody">
          { this.props.label &&
              <div className="ltFormQuestion capitalize">{this.props.label} Loan</div>
          }
          <div className="softOfferSec">
              <div className="softOfferTitle">
                  <label>Your <span className="capitalize">{this.props.label}</span> Lender Match</label>
              </div>
              <input type="checkbox" id={`showQuoteInput${this.props.label}`} />
              <ul className="softOfferDetails">
                  {this.props.PrincipalAndInterestMonthlyPayment &&
                      <li>
                        <label>Est. Payment</label>
                        <span>${this.props.PrincipalAndInterestMonthlyPayment}/mo</span>
                      </li>
                  }
                  {(this.props.APRPercentage  || this.props.APRPercentage === 0) &&
                      <li>
                        <label>APR</label>
                        <span>{this.props.APRPercentage}%</span>
                        <small>variable</small>
                      </li>
                  }
                  {this.props.LoanTermInMonths &&
                      <li>
                        <label>Loan Term</label>
                        <span>{this.props.LoanTermInMonths} Months</span>
                      </li>
                  }
              </ul>
              <div className="softOfferQuote">
                  <label htmlFor={`showQuoteInput${this.props.label}`} className="showQuote">Quote</label>
                  <div className="quoteSavingsAmt">Save ${this.props.EstimatedMonthlySavings}/mo</div>
              </div>
          </div>
      </div>
    );
  }
}

SoftOfferDetails.propTypes = {
  // Soft offer props
  APRPercentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  EstimatedMonthlySavings: PropTypes.number,
  PrincipalAndInterestMonthlyPayment: PropTypes.number,
  LoanAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  LoanTermInMonths: PropTypes.number,
  Fees: PropTypes.number,
  LenderId: PropTypes.string,
  SoftOfferAttribute: PropTypes.string,
  SoftOfferType: PropTypes.string,
  label: PropTypes.string,
};

// Here be dragons
function mapStateToProps(state) {
  return {
    formData: getFormData(state)
  };
}

export default connect(mapStateToProps)(SoftOfferDetails);