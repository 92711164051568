export const NUMBER = 'number';
export const NUMBERWITHCOMMAS = 'numberwithcommas';
export const DATE = 'date';
export const DOB = 'dob';
export const MONTH_YEAR = 'monthandyear';
export const PHONE = 'tel';
export const EMAIL = 'email';
export const CURRENCY = 'currency';
export const TEXT = 'default';
export const TEXTCHAR = 'text';
export const SSN = 'ssn';
export const PERCENT = 'percent';
export const FIRSTNAME = 'given-name';
export const LASTNAME = 'family-name';
export const STREETADDRESS = 'street-address';
export const CITY = 'address-level1';
export const STATE = 'address-level2';
export const ZIPCODE = 'postal-code';
export const NEWPASSWORD = 'new-password';
export const PASSWORD = 'password';
export const URL = 'url';
export const STREETADDRESSL1 = 'address-line1';
export const STREETADDRESSL2 = 'address-line2';
export const Last4SSN = 'last4ssn';
