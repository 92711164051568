export const FORM_STEP = 'Form Step';
export const FORM_START = 'Form Start';
export const XSELL_EVENT_TYPE = 'banners';
export const XSELL_EVENT_NAME_DISPLAYED = 'displayed';
export const XSELL_EVENT_NAME_REDIRECTED = 'redirected';
export const XSELL_EVENT_NAME_CLICKED = 'clicked';
export const XSELL_EVENT_NAME_START = 'start';
export const FORM_NAVIGATION_BACK_CLICKED = 'Previous Page Clicked';
export const FORM_ENGAGEMENT = 'Form Engagement';
export const FORM_POSTED = 'Form Posted';
export const FORM_AUTOADVANCE = 'Form Auto Advance';
export const OOPS_SHOWN = 'Oops Shown';
export const OOW_OPTIN_VIEWED = 'SSN OPTIN Viewed';
export const OOW_OPTIN_COMPLETED = 'SSN OPTIN Completed';
export const OOW_OPTOUT_COMPLETED = 'SSN OPTOUT Completed';
export const OOW_ENGAGEMENT = 'FCS Client Qform Optin OOW Q';
export const FORM_CONVERSION = 'Form Conversion';
export const CHECKOUT_STARTED = 'Checkout Started';
export const AGREED_TERMS = 'Agreed Terms';
export const PROMOTION_VIEWED = 'Promotion Viewed';
export const PROMOTION_CLICKED = 'Promotion Clicked';
export const PHONE_NUMBER_MASK_OPTIN_VIEWED = 'Phone Number Optin Viewed';
export const PHONE_NUMBER_MASK_OPTIN = 'Phone Number Opted In';
export const PHONE_NUMBER_MASK_OPTOUT = 'Phone Number Opted Out';
export const EQUIFAX_MOBILE_STATUS = 'Equifax Mobile Status';
export const EQUIFAX_FIELDS_RETURNED = 'Equifax Fields Returned';
export const EQUIFAX_FIELDS_MODIFIED = 'Equifax Fields Modified';
export const STREET_SUGGESTIONS_TIMEOUT = 'Google Street Suggestions Timeout';
export const FORM_AUTO_SUBMIT_START = 'Form Auto Submit Start';
export const FORM_AUTO_SUBMIT_FAIL = 'Form Auto Submit Fail';
export const FIELD_ENGAGEMENT = 'Field Engagement';
export const LENDER_CONSENT = 'Lender Consent';
