import { ltLogger, getFetchFunction } from '../../bootstrap/index';
import * as logtypes from '../../common/logtypes';
import config from '../../reducers/config';

let verifySocialLogin = function (formUuid) {
  return getFetchFunction()(
    `${config.apiConfig.rootApiServerPath}${config.apiConfig.verifySocialLogin}${formUuid}`,
    {
      method: 'get',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    }
  );
};

let checkIfIE = function () {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
};

const phxClientProcess = {
  //Add each client side process node as a function
  googleRedirect: function (requestData) {
    return new Promise((resolve) => {
      let title = 'Login to Lending Tree';
      const url = `${config.apiConfig.rootApiServerPath}${config.apiConfig.ltLoginPath}${requestData.formUuid}/${requestData.authType}`;
      const myWindow = window.open(
        url,
        title,
        'width=600,height=500,left=600,top=200,status=no,toolbar=no,titlebar=no'
      );
      if (!myWindow) {
        resolve({ navigateToNextStep: false }); //Pop up blocked
        return;
      }
      window.addEventListener('message', function (event) {
        if (!config.apiConfig.rootApiServerPath.startsWith(event.origin)) {
          return;
        }
        if (
          !event.data ||
          event.data.messageFrom !== 'PHOENIX_API' ||
          event.data.messagefor !== 'SOCIAL_LOGIN'
        ) {
          return;
        }
        if (intervalId) {
          window.clearInterval(intervalId);
        }
        if (event.data.message === 'success') {
          resolve({ navigateToNextStep: true });
        } else {
          resolve({ navigateToNextStep: false });
        }
      });
      const intervalId = window.setInterval(function () {
        if (myWindow.closed) {
          if (intervalId) {
            window.clearInterval(intervalId);
          }
          if (checkIfIE()) {
            // For IE
            verifySocialLogin(requestData.formUuid)
              .then(function (response) {
                if (response.status === 200) {
                  response.json().then(function (data) {
                    if (data && data.message == 'success') {
                      resolve({ navigateToNextStep: true });
                    } else {
                      resolve({ navigateToNextStep: false });
                    }
                  });
                } else {
                  resolve({ navigateToNextStep: false });
                }
              })
              .catch(() => {
                resolve({ navigateToNextStep: false });
              });
          } else {
            // If another browser
            resolve({ navigateToNextStep: false });
          }
        }
      }, 2 * 1000);
    });
  },
  googleStreetSuggestions: function (requestData, timeoutCallback) {
    let autocompleteService =
      window.google && new window.google.maps.places.AutocompleteService();
    if (autocompleteService) {
      return new Promise(function (resolve) {
        let resolved = false;
        autocompleteService.getPlacePredictions(
          {
            input: requestData.input,
            componentRestrictions: { country: ['us'] },
            types: ['address'],
          },
          function (places, status) {
            let matches = [];
            try {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                matches = places.map((item) => {
                  let street, city, state;
                  if (item.structured_formatting) {
                    street = item.structured_formatting.main_text;
                    let cityState = item.structured_formatting.secondary_text;
                    if (cityState) {
                      let split = cityState.split(',');
                      if (split && split.length >= 2) {
                        city = split[0];
                        state = split[1];
                      }
                    }
                  }
                  return {
                    displayText: item.description,
                    streetLine: street,
                    cityName: city,
                    stateCode: state ? state.trim() : '',
                    place_id: item.place_id,
                  };
                });
              }
            } catch (error) {
              ltLogger(logtypes.ERROR, undefined, {}, undefined, {
                error: error,
              });
            }
            resolved = true;
            resolve({ matches: matches });
          }
        );
        const ssTimeoutMS = 3 * 1000;
        setTimeout(() => {
          if (!resolved) {
            timeoutCallback && timeoutCallback({ ssTimeoutMS });
            resolve({ matches: [] });
          }
        }, ssTimeoutMS);
      });
    }
    ltLogger(logtypes.ERROR, undefined, {}, undefined, {
      error: 'Could not load Google Street Suggesstion API',
    });
    return Promise.resolve({ matches: [] });
  },
};

const executeProcess = (funcToExec, requestData, timeoutCB) => {
  return phxClientProcess[funcToExec](requestData, timeoutCB);
};

export default executeProcess;
