import React from 'react';

const DataLoader = () => {
  return (
    <div className="autos-loader-container">
      <div className="loader-wrapper">
        <div className="loader"/>
      </div>
    </div>
  );
};

export default DataLoader;
