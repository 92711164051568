import { getStore } from '../store/index';
import './config';

const hasLoadedTUScripts = {
  current: false,
};

const FORMDATA_KEY = 'TuBlackbox';

const getBlackbox = () => {
  if (window.IGLOO && window.IGLOO.getBlackbox instanceof Function) {
    /**
     * The response object looks like:
     * {
     *    blackbox: string,
     *    finished: boolean,
     * }
     */
    const response = window.IGLOO.getBlackbox();

    if (response && response.finished === true) {
      return response.blackbox;
    }
  }
};

const parseStepName = (name) => {
  const valid = name != null && name.toString && name.toString().trim() !== '';

  return valid ? name : undefined;
};

const isStateValid = (state) => {
  return (
    state &&
    state.formData &&
    state.formMeta &&
    state.formData[FORMDATA_KEY] == null
  );
};

const init = async () => {
  if (hasLoadedTUScripts.current === false) {
    await require('./loader.js');

    hasLoadedTUScripts.current = true;
  }

  const store = getStore();
  const state = store.getState();
  const hasValidState = isStateValid(state);

  if (hasValidState) {
    const timer = window.setInterval(() => {
      const blackbox = getBlackbox();
      const { stepName } = state.formMeta;
      const parsedStepName = parseStepName(stepName);

      if (blackbox != null && !parsedStepName != null) {
        store.dispatch(
          window.formActions.change(
            `formData.extraDataFields.${FORMDATA_KEY}`,
            blackbox
          )
        );

        window.clearInterval(timer);
      }
    }, 300);
  }
};

window.initTUBlackbox = Object.freeze(init);