import React from 'react';
import PropTypes from 'prop-types';
import {
  restrictJobStartDate,
  changeFormStepProps,
} from '../../../actions/formActions';
import { getStore } from '../../../store/index';
import { getAsyncValidations } from '../../../validators/validators';
import { actions } from 'react-redux-form';
import { reportFieldEngagement } from '../../../bootstrap';

class MultiPartControl extends React.Component {
  constructor(props) {
    super(props);
    props.controlDefs['box3'].enumeration
      ? getStore().dispatch(
          changeFormStepProps({
            targetProp: 'multipartcontrol.box3.enumeration',
            targetValue: restrictJobStartDate(
              props.uniqueId,
              props.controlDefs['box3'].enumeration
            ),
            targetStepId: props.uniqueId,
          })
        )
      : getStore().dispatch(
          changeFormStepProps({
            targetProp: 'multipartcontrol.box3.enumeration',
            targetValue: [],
            targetStepId: props.uniqueId,
          })
        );
    if (props.value !== '') {
      this.state = props.splitFunction(props.value);
    } else {
      this.state = {
        box1value: '',
        box2value: props.controlDefs['box2'].value
          ? props.controlDefs['box2'].value
          : '',
        box3value: '',
      };
    }

    if (this.props.uniqueId === 'Last4SSN') {
      this.state = {
        box1value: props.controlDefs['box1'].placeholder.trim().length > 0
          ? props.controlDefs['box1'].placeholder.trim()
          : 'XXX',
        box2value: props.controlDefs['box2'].placeholder.trim().length > 0
          ? props.controlDefs['box2'].placeholder.trim()
          : 'XX',
      };
    }
    this.handleChange = this.handleChange.bind(this);
    this.onKeyDown = this.handleKeyDown.bind(this);
    this.focus = this.focus.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value)
      this.setState(this.props.splitFunction(nextProps.value));
  }

  focus(target, direction) {
    const index =
      direction === 'forward'
        ? parseInt(target.substr(-1)) + 1
        : parseInt(target.substr(-1)) - 1;
    if (this[`box${index}`] !== undefined) {
      this.props.controlDefs.box1.type === 'select' &&
      this.props.controlDefs.box2.type === 'select'
        ? this[`box${index}`].click()
        : this[`box${index}`].focus();
    }
  }

  getBoxStateValue(isValid, event) {
    return isValid
      ? event.currentTarget.value
      : event.currentTarget.value === ''
      ? event.currentTarget.value
      : event.currentTarget.defaultValue;
  }

  handleKeyDown(event, target) {
    // let key = event.keyCode || event.charCode;
    let key = event.which;
    let textField = this[target];
    const selectedText = textField.value.substring(
      textField.selectionStart,
      textField.selectionEnd
    );
    // Backspace or Delete
    if (key === 8 || key === 46) {
      if (this[target].value === '' || this[target].value.length === 0) {
        this.focus(target, 'backward');
        event.preventDefault();
      }
      // Tab
    } else if (key === 9) {
      return;
    } else {
      if (
        this.props.jumpRules !== undefined &&
        this[target].value.length === this.props.jumpRules[target] &&
        selectedText.length === 0
      ) {
        this.focus(target, 'forward');
        key !== 13 && event.preventDefault();
      }
    }
  }

  handleChange(event, target) {
    let regex = /^[0-9\b]+$/;
    let isValid = regex.test(event.currentTarget.value);

    const boxState = (() => {
      switch (target) {
        case 'box1':
          return {
            box1: this.getBoxStateValue(isValid, event),
            box2: this.state.box2value,
            box3: this.state.box3value,
          };
        case 'box2':
          return {
            box1: this.state.box1value,
            box2: this.getBoxStateValue(isValid, event),
            box3: this.state.box3value,
          };
        case 'box3':
          return {
            box1: this.state.box1value,
            box2: this.state.box2value,
            box3: this.getBoxStateValue(isValid, event),
          };
        default:
          return {};
      }
    })();

    const outVal = this.props.handleValueCombine(boxState);
    const isPopulated = (val) =>
      val !== undefined && val !== null && val !== '';
    let allPopulated =
      isPopulated(boxState.box1) &&
      isPopulated(boxState.box2) &&
      isPopulated(boxState.box3);
    if (this.props.uniqueId === 'SSN' && allPopulated) {
      allPopulated =
        boxState.box1.length == 3 &&
        boxState.box2.length === 2 &&
        boxState.box3.length === 4;
    }
    if (allPopulated) {
      reportFieldEngagement(this.props.uniqueId, outVal);
    }
    let asyncValidations = getAsyncValidations(this.props.validation);
    let asyncValidationFields = [];

    if (asyncValidations) {
      for (let i = 0; i < asyncValidations.length; i++) {
        if (asyncValidations[i].validationtype) {
          asyncValidationFields.push(asyncValidations[i].validationtype);
        }
      }
      getStore().dispatch(
        actions.resetValidity(
          `formData.${this.props.uniqueId}`,
          asyncValidationFields
        )
      );
    }

    this.setState({
      [`${target}value`]: this.getBoxStateValue(isValid, event),
      value: outVal,
    });
    this.props.onupdate(outVal);

    if (this.props.jumpRules !== undefined) {
      if (boxState[target].length === this.props.jumpRules[target]) {
        this.focus(target, 'forward');
      }
    }

    /**
     * Temporary functionality to auto-forward users on
     * completion of the step, if the multipart is NOT Phone
     * or SSN.
     */
    if (
      boxState.box1 &&
      boxState.box2 &&
      boxState.box3 &&
      this.props.uniqueId !== 'PhoneNumber' &&
      this.props.uniqueId !== 'SSN' &&
      this.props.uniqueId !== 'Last4SSN' &&
      !this.props.disableAutoforward
    )
      this.props.submitFunc();
  }

  render() {
    const {
      uniqueId,
      controlDefs,
      inputClass,
      wrapperClass,
      placeholders,
      value,
      jumpRules,
      autoFocus,
      mask,
    } = this.props;
    const maskTextClass = mask ? 'mask-input' : '';
    const inputClassName = this.props.uniqueId === 'Last4SSN' ? 'ltFormControlMulti' : inputClass;
    const wrapperClassName = this.props.uniqueId === 'Last4SSN' ? 'ltFormControlMultiWrapper' : wrapperClass;
    const isDisabled = this.props.uniqueId === 'Last4SSN' ? true : false;
    const contentMultipartUniqueIdClass = (this.props.uniqueId === 'Last4SSN' ||  this.props.uniqueId === 'SSN') ? 'SSN' : '';
    const contentMultipartInputTypeClass = (this.props.uniqueId === 'Last4SSN' || this.props.uniqueId === 'SSN')
      ? controlDefs['box1'].type === 'text'
        ? 'text'
        : 'select'
      : '';
    return (
      <div className={`ltFormGroupContentMultipart ${contentMultipartUniqueIdClass} ${contentMultipartInputTypeClass}`}>
        <input
          id="hidden"
          placeholder="hidden one"
          style={{ display: 'none' }}
          type={uniqueId === 'SSN' || 'PhoneNumber' ? 'tel' : 'text'}
          value={value}
          readOnly={true}
        />
        <span className={`${wrapperClassName} ${uniqueId} box1`}>
          {controlDefs['box1'].type === 'text' ? (
            <input
              disabled={isDisabled}
              id={`${uniqueId}.box1`}
              name={`${uniqueId}.box1`}
              value={this.state.box1value}
              type={uniqueId === 'SSN' || 'PhoneNumber' ? 'tel' : 'number'}
              autoFocus={window.innerWidth >= 768 && autoFocus}
              maxLength={jumpRules.box1}
              className={`${inputClassName} ${uniqueId} box1 ${maskTextClass}`}
              onChange={(e) => this.handleChange(e, 'box1')}
              onKeyDown={(e) => this.handleKeyDown(e, 'box1')}
              style={controlDefs['box1'].style}
              autoComplete="off"
              placeholder={placeholders['box1Placeholder']}
              ref={(input) => {
                this.box1 = input;
              }}
            />
          ) : (
            <select
              autoFocus={autoFocus}
              className={`${inputClass} ${uniqueId} box1`}
              onChange={(e) => this.handleChange(e, 'box1')}
              ref={(input) => {
                this.box1 = input;
              }}
              value={this.state.box1value}
            >
              <option value="">{placeholders['box1Placeholder']}</option>
              {controlDefs['box1'].enumeration.map((choice) => (
                <option key={choice.value} value={choice.value}>
                  {choice.label}
                </option>
              ))}
            </select>
          )}
        </span>
        <span
          className={`${wrapperClassName} ${uniqueId} box2`}
          style={controlDefs['box2'].style}
        >
          {controlDefs['box2'].type === 'text' ? (
            <input
              disabled={isDisabled}
              id={`${uniqueId}.box2`}
              name={`${uniqueId}.box2`}
              value={this.state.box2value}
              type={uniqueId === 'SSN' || 'PhoneNumber' ? 'tel' : 'number'}
              className={`${inputClassName} ${uniqueId} box2 ${maskTextClass}`}
              onChange={(e) => this.handleChange(e, 'box2')}
              onKeyDown={(e) => this.handleKeyDown(e, 'box2')}
              autoComplete="off"
              maxLength={jumpRules.box2}
              placeholder={placeholders['box2Placeholder']}
              ref={(input) => {
                this.box2 = input;
              }}
            />
          ) : (
            <select
              className={`${inputClass} ${uniqueId} box2`}
              onChange={(e) => this.handleChange(e, 'box2')}
              ref={(input) => {
                this.box2 = input;
              }}
              value={this.state.box2value}
            >
              <option value="">{placeholders['box2Placeholder']}</option>
              {controlDefs['box2'].enumeration.map((choice) => (
                <option key={choice.value} value={choice.value}>
                  {choice.label}
                </option>
              ))}
            </select>
          )}
        </span>
        <span className={`${wrapperClass} ${uniqueId} box3`}>
          {controlDefs['box3'].type === 'text' ? (
            <input
              id={`${uniqueId}.box3`}
              name={`${uniqueId}.box3`}
              value={this.state.box3value}
              onChange={(e) => this.handleChange(e, 'box3')}
              onKeyDown={(e) => this.handleKeyDown(e, 'box3')}
              maxLength={jumpRules.box3}
              style={controlDefs['box3'].style}
              type={uniqueId === 'SSN' || 'PhoneNumber' ? 'tel' : 'number'}
              className={`${inputClass} ${uniqueId} box3 ${maskTextClass}`}
              autoComplete="off"
              placeholder={placeholders['box3Placeholder']}
              ref={(input) => {
                this.box3 = input;
              }}
            />
          ) : (
            <select
              className={`${inputClass} ${uniqueId} box3`}
              onChange={(e) => this.handleChange(e, 'box3')}
              ref={(input) => {
                this.box3 = input;
              }}
              value={this.state.box3value}
            >
              <option value="">{placeholders['box3Placeholder']}</option>
              {controlDefs['box3'].enumeration.map((choice) => (
                <option key={choice.value} value={choice.value}>
                  {choice.label}
                </option>
              ))}
            </select>
          )}
        </span>
      </div>
    );
  }
}

MultiPartControl.defaultProps = {
  concatChar: '-',
};

MultiPartControl.propTypes = {
  type: PropTypes.string,
  onupdate: PropTypes.func,
  step: PropTypes.object,
  concatChar: PropTypes.string,
  value: PropTypes.string,
  splitFunction: PropTypes.func,
  controlDefs: PropTypes.object,
  handleValueCombine: PropTypes.func,
  jumpRules: PropTypes.object,
  submitFunc: PropTypes.func,
  uniqueId: PropTypes.string,
  inputClass: PropTypes.string,
  wrapperClass: PropTypes.string,
  placeholders: PropTypes.object,
  autoFocus: PropTypes.bool,
  mask: PropTypes.bool,
  disableAutoforward: PropTypes.bool,
  validation: PropTypes.object,
};

export default MultiPartControl;
