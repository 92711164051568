import React from 'react';
import PropTypes from 'prop-types';
import './softOffer.css';
import {connect} from "react-redux";
import {getFormData} from "../../../selectors/formSelector";

class Product extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  /**
   * Sends selected product to parent next handler (productSelection.js)
   */
  chooseProduct() {
    return this.props.handleNext(this.props.product);
  }

  /**
   * Render the non-clickable declined product component.
   */
  renderDeclinedProduct() {
    // Get the product label
    const label = this.props.label;

    // Render the component html
    return (
      <div className="ltFormGroup">
        <div className="ltFormQuestion">{label} Loan</div>
        <div className="ltFormProductDeclined">Offer Declined</div>
      </div>
    );
  }

  /**
   * Render the non-clickable no-offer product component.
   */
  renderNoOfferProduct() {
    // Get the product label
    const label = this.props.label;

    // Render the component html
    return (
      <div className="ltFormGroup">
        <div className="ltFormQuestion">{label} Loan</div>
        <div className="ltFormNoSavings">No Savings Found</div>
      </div>
    );
  }

  /**
   * Render the get started product component
   */
  renderGetStarted() {
    // Get the product label
    const label = this.props.label;

    // Render the component html
    return (
      <div className="ltFormGroup">
        <div className="ltFormQuestion">{label} Loan</div>
        <button className="ltFormGetStartedBtn" onClick={this.chooseProduct.bind(this)}>Calculate Savings</button>
      </div>
    );
  }

  /**
   * Standard React Component render
   */
  render() {
    switch(this.props.completedResult.status) {
      case 'declined':
        return this.renderDeclinedProduct();
      case 'no offer':
        return this.renderNoOfferProduct();
      default:
        return this.renderGetStarted();
    }
  }
}


Product.propTypes = {
  // Platform props
  step:       PropTypes.object,
  dispatch:   PropTypes.func,
  formData:   PropTypes.object,
  handleNext: PropTypes.func,

  // Builder props
  label:           PropTypes.string,
  product:         PropTypes.string,
  completedResult: PropTypes.object,

  // Product Selection props
};

// Here be dragons
function mapStateToProps(state) {
  return {
    formData: getFormData(state)
  };
}

export default connect(mapStateToProps)(Product);