import { sendEventToKafka } from '../utils/kafkaService';
import {
  analyticsIsLoaded,
  getMappedTrackProperties,
} from '../utils/ltAnalyticsService';
import { getStore } from '../store';

const mapErrorReportingData = (inputReq) => {
  const { formMeta } = getStore().getState();
  const modelValue = inputReq?.modelValue || inputReq?.props?.modelValue;

  return {
    FieldName: formMeta.stepName || undefined,
    FieldValue: modelValue || undefined,
    ErrorType: inputReq?.key || undefined,
    Timestamp: Date.now().toString(),
    QFormUID: formMeta.formUuid,
    FormId: formMeta.formId,
    StepSet: formMeta.stepSet,
    ClickSessionId: analyticsIsLoaded()
      ? getMappedTrackProperties().ClickSessionId
      : '',
    TreeAnonymousTransientUid: analyticsIsLoaded()
      ? getMappedTrackProperties().TreeAnonymousTransientUid
      : ''
  };
};

export const sendErrorDataToKafka = (objectProp) => {
  if (objectProp) {
      const errorData = mapErrorReportingData(objectProp);
      sendEventToKafka('errorReporting', errorData, null);
  }
};
