import React from 'react';
import PropTypes from 'prop-types';

/*eslint-disable react/no-danger*/

const FormBusy = ({content}) => {

  return (
    <div className="ltFormBusySpinner">
      <div className="spin">
        <div className="inner"/>
      </div>
      <div dangerouslySetInnerHTML={{__html: content}}/>
    </div>
  );
};

FormBusy.propTypes = {
  content: PropTypes.string
};
/*eslint-enable */

export default FormBusy;
