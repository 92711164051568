export const minLength = (input, min) => {

  return input.length >= parseInt(min);

};

export const maxLength = (input, max) => {

  return input.length <= parseInt(max);

};

export const precisionAndScale = (input, length, scale) => {
  const [leftInput, rightInput] = `${input}`.split('.');
  const isPrecise = leftInput.length <=  parseInt(length) -  parseInt(scale) ;
  const isScaled = rightInput === undefined || rightInput.length <= parseInt(scale);
  return isPrecise & isScaled;
};

export const isValidDecimalFormat = (input) => {
  let regex = /^\d+(\.\d+)?$/ ;
  return regex.test(input);
};