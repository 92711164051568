export const titleCase = (str, position) => {
  if (str) {
    return str.replace(/\w\S*/g, function (txt) {
      return (
        txt.charAt(position).toUpperCase() +
        txt.substr(position + 1).toLowerCase()
      );
    });
  }
};
