import { useState, useEffect } from 'react';
export default function loadScript(src) {
  // State to store loaded status
  const [status, setStatus] = useState({
    loaded: false,
    error: false,
  });
  useEffect(() => {
    // Create script
    let script = document.createElement('script');
    script.src = src;
    script.async = true;
    // Script event listener callbacks for load and error
    const onScriptLoad = () => {
      setStatus({ loaded: true, error: false });
    };
    const onScriptError = () => {
      // Cleanup on error
      script.remove();
      setStatus({
        loaded: true,
        error: true,
      });
    };
    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);
    document.body.appendChild(script);
    // Cleanup function
    return () => {
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    };
  }, [src]); // Only re-run effect if script src changes
  return status;
}
