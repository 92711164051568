export default {
  local: {
    LAUNCHSTRAP_URL:
      'https://www.dev.lendingtree.com/analytics/lta-launchstrap.min.js',
    JORNAYA_CAMPAIGN: '463e09b3-face-feed-cafe-291fdc87b970',
    AUTH_INIT_URL: 'https://auth.dev.lendingtree.com/api/InitSession',
  },
  dev: {
    LAUNCHSTRAP_URL:
      'https://www.dev.lendingtree.com/analytics/lta-launchstrap.min.js',
    JORNAYA_CAMPAIGN: '463e09b3-face-feed-cafe-291fdc87b970',
    AUTH_INIT_URL: 'https://auth.dev.lendingtree.com/api/InitSession',
  },
  staging: {
    LAUNCHSTRAP_URL:
      'https://www.staging.lendingtree.com/analytics/lta-launchstrap.min.js',
    JORNAYA_CAMPAIGN: '463e09b3-face-feed-cafe-291fdc87b970',
    AUTH_INIT_URL: 'https://auth.staging.lendingtree.com/api/InitSession',
  },
  preprod: {
    LAUNCHSTRAP_URL:
      'https://www.staging.lendingtree.com/analytics/lta-launchstrap.min.js',
    JORNAYA_CAMPAIGN: '463e09b3-face-feed-cafe-291fdc87b970',
    AUTH_INIT_URL: 'https://auth.preprod.lendingtree.com/api/InitSession',
  },
  prod: {
    LAUNCHSTRAP_URL:
      'https://www.lendingtree.com/analytics/lta-launchstrap.min.js',
    JORNAYA_CAMPAIGN: 'a1812ac6-a306-7d8e-8a34-7034094e1d59',
    AUTH_INIT_URL: 'https://auth.lendingtree.com/api/InitSession',
  },
};
