import * as formatTypes from './formatTypes';
import moment from 'moment';

export const dateParser = (val) => {
  if (val === undefined || val === null) return;

  let parsed = moment(val, 'M/D/YYYY', true);

  return parsed.isValid() ? parsed.format('MM/DD/YYYY') : val;
};

export const currencyParser = (val) => {
  if (val === undefined || val === null) return;

  // PHX-5415: integers are sometimes being passed, so need to cast to string
  return String(val).replace(/\D/g, '');
};

export const phoneParser = (val) => {
  if (val === undefined || val === null) return;

  return val.replace(/\D/g, '');
};

export const ssnDashParser = (val) => {
  if (val === undefined || val === null) return;

  return val.replace(/\D/g, '');
};

export const percentParcer = (val) => {
  if (val === undefined || val === null) return;

  return val.replace('%', '');
};

// PHX-3669: Number parser, when not a float
export const numberParser = (val) => {
  if (val === undefined || val === null) return;
  if (typeof val === 'number') return val;

  return val.replace(/\D/g, '');
};

export const specialCharacterParser = (event) => {
  const regex = new RegExp("^[a-zA-Z0-9, '()-.]+$");
  const key = String.fromCharCode(
    !event.charCode ? event.which : event.charCode
  );
  if (event.which !== 13 && !regex.test(key)) {
    event.preventDefault();
    return false;
  }
};

const numberWithCommasParser = (val) => {
  if (val === undefined || val === null) return;

  return val.toString().replace(/\D/g, '');
};

export const getParser = (type, isDecimalAllowed = false) => {
  switch (type) {
    case formatTypes.DOB:
    case formatTypes.DATE:
      return dateParser;
    case formatTypes.CURRENCY:
      return currencyParser;
    case formatTypes.PERCENT:
      return percentParcer;
    case formatTypes.PHONE:
      return phoneParser;
    case formatTypes.SSN:
      return ssnDashParser;
    case formatTypes.NUMBER:
      return isDecimalAllowed ? undefined : numberParser;
    case formatTypes.NUMBERWITHCOMMAS:
      return numberWithCommasParser;
    default:
      break;
  }
};
