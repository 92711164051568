import React, { Fragment } from 'react';

import Step from './step';
import SummarySteps from './summary-steps';
import Explain from './explain';
import StepHeader from './stepHeader';
import StepSubHeader from './stepSubHeader';
import ProgressBar from '../common/FormProgress';
import ToolTip from './controls/tooltip';
import PropTypes from 'prop-types';
import { formatSummaryValue } from '../../utils/stepFunctions';
import { runRules } from '../../utils/rules';

let getStepSummaryValue = (step) => {
  let value = step.value;

  if (step.summarysettings.showshorthand) {
    if (step.enumeration && step.enumeration.length > 0) {
      let matchingEnumerationItem = step.enumeration.find(
        (item) => item.value === step.value
      );
      return matchingEnumerationItem
        ? matchingEnumerationItem.label
        : formatSummaryValue(value, step.formatter);
    }
  }

  return formatSummaryValue(value, step.formatter);
};

const hasTooltipText = (step) =>
  step.summarysettings.showTooltip &&
  step.tooltip &&
  step.tooltip.tooltiptext &&
  step.tooltip.tooltiptext.length > 0;

let summarizeSteps = function (stepsToSummarize) {
  let summaryStep = {
    isSummary: true,
    individualSummaries: [],
  };

  // Break up the summarized steps into "groups"
  let groups = {};

  stepsToSummarize.forEach((step) => {
    let groupId = step.group && step.group.id ? step.group.id : step.id;

    if (!groups[groupId]) {
      groups[groupId] = [];
    }

    groups[groupId].push(step);
  });

  for (let id in groups) {
    let group = groups[id];
    let firstNode = group[0];
    let hasMultiple = group.length > 1;

    // Always use the label of the first node in the group
    let { label } = firstNode.summarysettings;

    let classNames = group.map((s) => s.attributeid);
    classNames.push(
      hasMultiple ? 'ltSummaryGroupMutiple' : 'ltSummaryGroupSingle'
    );

    const formatAsCurrency = (step) => {
      let currencyType = '';
      if (
        !step.addons ||
        (!step.addons.enabled && step.formatter !== 'numberwithcommas')
      )
        return currencyType;

      step.addons.items.forEach((e) => {
        if (e.type.includes('usd')) currencyType = '$';
      });
      return currencyType;
    };

    let value = hasMultiple
      ? group
          .map(
            (step) =>
              formatAsCurrency(step) +
              getStepSummaryValue(step) +
              (step.summarysettings.delimiter
                ? step.summarysettings.delimiter
                : '')
          )
          .join(' ')
      : formatAsCurrency(firstNode) + getStepSummaryValue(firstNode);

    let toolTipValue = group
      .filter(hasTooltipText)
      .map((step) => step.tooltip.tooltiptext)
      .join('<br>');

    let classes = classNames.join(' ');

    summaryStep.individualSummaries.push(
      <div key={`summaryGroup_${id}`} className={`ltSummaryGroup ${classes}`}>
        <div
          style={{ display: 'inline' }}
          className={`ltSummaryLabel ${classes}`}
        >{`${label} `}</div>
        <div
          style={{ display: 'inline' }}
          className={`ltSummaryValue ${classes}`}
        >
          {value}
          {toolTipValue && toolTipValue.length > 0 && (
            <ToolTip
              content={toolTipValue}
              iconType="Info"
              iconPosition="Right"
            />
          )}
        </div>
      </div>
    );
  }

  return summaryStep;
};

const isGroupStep = (step, groupId) => step.group && step.group.id === groupId;
const isSummaryStep = ({ step }) =>
  step.summarysettings && step.summarysettings.readonly && step.value;
const getEditableStepGroup = (steps) => {
  const l = steps.length;
  const editableGroup = [];
  for (let i = 0; i < l; i += 1) {
    const meta = steps[i];
    const { step, index } = meta;

    if (step.group && step.group.id) {
      const groupSteps = steps.filter((meta) =>
        isGroupStep(meta.step, step.group.id)
      );
      const group = {
        groupId: step.group.id,
        className: step.group.classname,
        originalIndex: index,
        steps: groupSteps,
      };

      editableGroup.push(group);

      i = i + groupSteps.length - 1;
    } else {
      editableGroup.push({ originalIndex: index, useOriginalStep: true });
    }
  }
  return editableGroup;
};

const getStepsToMap = (steps = []) => {
  const stepMeta = steps.map((step, index) => ({ step, index }));
  const summarySteps = stepMeta.filter(isSummaryStep);
  const editableSteps = stepMeta.filter((step) => !isSummaryStep(step));
  let summaryGroup = false;
  const editableGroup = getEditableStepGroup(editableSteps);

  if (summarySteps.length) {
    summaryGroup = {
      ...summarizeSteps(summarySteps.map(({ step }) => step)),
      id: summarySteps[0].step.id,
      showEditButton: summarySteps[0].step.summarysettings.showeditbutton,
      editButtonLabel: summarySteps[0].step.summarysettings.editbuttonlabel,
      showConfirmationlabel:
        summarySteps[0].step.summarysettings.showConfirmationlabel,
      confirmationlabel: summarySteps[0].step.summarysettings.confirmationlabel,
    };
  }

  return {
    editableGroup,
    summaryGroup,
  };
};

class Steps extends React.Component {
  constructor(props) {
    super(props);
    this.state = getStepsToMap(props.steps);
    this.makeSummaryEditable = this.makeSummaryEditable.bind(this);
  }

  makeSummaryEditable() {
    const stepMeta = this.props.steps.map((step, index) => ({ step, index }));
    this.setState({
      summaryGroup: false,
      editableGroup: getEditableStepGroup(stepMeta),
    });
  }

  render() {
    const {
      steps,
      handleNext,
      disabled,
      warnings,
      showExplain,
      AutomobileLoanType,
    } = this.props;
    const firstStep = steps[0];

    return (
      <Fragment>
        {firstStep.progressbarpercentage && firstStep.showProgressBar && (
          <ProgressBar percentComplete={firstStep.progressbarpercentage} />
        )}
        {!window.LT5theme && window.ltExplainTextPosition !== 'headline' && (
          <Explain
            content={firstStep.explaintext}
            show={showExplain}
            classExt={firstStep.attributeid}
            buttonText={firstStep.explainbuttontext}
          />
        )}
        {firstStep.header && (
          <StepHeader
            content={firstStep.header}
            classExt={firstStep.attributeid}
          />
        )}
        {firstStep.subheader && !(AutomobileLoanType === 'REFINANCE') && (
          <StepSubHeader
            content={firstStep.subheader}
            classExt={firstStep.attributeid}
          />
        )}
        <div
          className={`ltEditableFormGroupContainer${
            this.state.summaryGroup ? ' hasSummary' : ''
          }`}
        >
          {this.state.editableGroup.map((step, index) => {
            const stepContentProps = {
              steps: this.props.steps,
              handleNext,
              disabled,
              warnings,
              index,
              showExplain,
            };

            if (step.groupId) {
              return (
                <div
                  key={step.groupId}
                  className={`ltFormGroupContainer ${step.className}`}
                >
                  {step.steps.map((groupStep) => {
                    let ruleResult = { showStep: true };
                    if (
                      Array.isArray(groupStep.step.rules) &&
                      groupStep.step.rules.length > 0
                    ) {
                      runRules(
                        groupStep.step.rules,
                        ruleResult,
                        groupStep.step
                      );
                    }
                    let stepIndex =
                      Array.isArray(steps) &&
                      steps.findIndex((step) => step.id === groupStep.step.id);
                    groupStep.step = Object.assign(
                      {},
                      stepIndex !== -1 ? steps[stepIndex] : groupStep.step,
                      ruleResult
                    );
                    return (
                      <Step
                        key={groupStep.step.id}
                        step={groupStep.step}
                        {...stepContentProps}
                      />
                    );
                  })}
                </div>
              );
            } else {
              let stepObj = step.useOriginalStep
                ? steps[step.originalIndex]
                : step;
              let ruleResult = { showStep: true };
              if (Array.isArray(stepObj.rules) && stepObj.rules.length > 0) {
                runRules(stepObj.rules, ruleResult, stepObj);
              }
              //merge or overwrite attributes created after rule evaluation with existing step object
              stepObj = Object.assign({}, stepObj, ruleResult);
              return (
                <Step key={stepObj.id} step={stepObj} {...stepContentProps} />
              );
            }
          })}
        </div>
        {this.state.summaryGroup && (
          <SummarySteps
            key={this.state.summaryGroup.id}
            step={this.state.summaryGroup}
            action={this.makeSummaryEditable}
          />
        )}
      </Fragment>
    );
  }
}

Steps.propTypes = {
  steps: PropTypes.array,
  handleNext: PropTypes.func,
  showExplain: PropTypes.bool,
  disabled: PropTypes.bool,
  warnings: PropTypes.object,
  step: PropTypes.object,
  index: PropTypes.number,
  AutomobileLoanType: PropTypes.string,
};

export default Steps;
