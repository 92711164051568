import React from 'react';
import PropTypes from 'prop-types';

const SecurityMessages = ({ messages }) => {
  const messageObj = [];
  messages.forEach(element => {
    if (element.securityMessage.trim() !== '') {
      messageObj.push(element);
    }
  });
  const securityMessagesElement = messageObj.map((message) => {
    return (
      <div key={message.icon} class={`messageItem${message.icon}`}>
        <span />
        <div className={`message ${message.icon}`}>
          {message.securityMessage}
        </div>
      </div>
    );
  });
  return (securityMessagesElement.length > 0) ? <div className="ltFormCTASubtext">{securityMessagesElement}</div> : null;
};

SecurityMessages.propTypes = {
  messages: PropTypes.array,
};

export default SecurityMessages;
