import React, { useCallback, useReducer, useMemo, useRef } from 'react';
import { Errors, Control, actions } from 'react-redux-form';
import { connect } from 'react-redux';
import Subtext from '../../subText';
import PropTypes from 'prop-types';

import { setFormBusy, formStatusReset } from '../../../../actions/formActions';
import * as validTypes from '../../../../validators/validationTypes';
import config from '../../../../reducers/config';
import {
  getFormController,
  getFormStateController,
} from '../../../../selectors/formSelector';
import * as statusType from '../../../../actions/formActionTypes';
import { reportFieldEngagement } from '../../../../bootstrap';
import storageFactory from '../../../../utils/storageFactory';
import { hasAcceptableHostName } from '../../../../utils/url';

import useUpdateSession from './useUpdateSession';
import useSetValidations from './useSetValidations';
import useWithAttributeId from './useWithAttributeId';
import FileList from './FileList';

const errorComponentClassName = 'ltErrorText';
const storage = storageFactory();

const getPropertyMap = (valName) => {
  switch (valName) {
    case validTypes.REQUIRED:
    case validTypes.INLIST:
      return 'name';
    case validTypes.MIN_FILE_SIZE_BYTES:
    case validTypes.MAX_FILE_SIZE_BYTES:
      return 'size';
    default:
      return undefined;
  }
};

const formatAcceptList = (listArr) => {
  return listArr.map((item) => `.${item}`).join(',');
};

const parseRequestResponse = (request) => {
  if (request && typeof request.response === 'string') {
    return JSON.parse(request.response);
  }

  return request.response;
};

const getFileNames = (files) => {
  return files
    .filter((file) => file != null && file.name != null)
    .map(({ name }) => name)
    .join(',');
};

const listContains = (haystack, id) =>
  haystack.findIndex((o) => o.id === id) > -1;

/**
 * An easier API for dispatch from the useReducer hook.
 * `dispatch({ type: 'update', payload: data })` becomes
 * `dispatch('update', data)`
 */
const useDispatch = (reducer, initial, init) => {
  const [state, dispatch] = useReducer(reducer, initial, init);
  const easyDispatch = useCallback(
    (type, payload) => dispatch({ type, payload }),
    []
  );

  return [state, easyDispatch];
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'addDropZoneError':
      return {
        ...state,
        dropZoneClass: [...state.dropZoneClass.split(' '), 'ltHasError']
          .join(' ')
          .trim(),
      };
    case 'removeDropZoneError':
      return {
        ...state,
        dropZoneClass: [
          ...state.dropZoneClass.split(' ').filter((c) => c !== 'ltHasError'),
        ]
          .join(' ')
          .trim(),
      };
    case 'updateFileMaxReached':
      return {
        ...state,
        fileMaxReached: action.payload,
      };
    case 'uploadProgress':
      return {
        ...state,
        acceptedFiles: state.acceptedFiles.map((file) => {
          if (file.id === action.payload.id) {
            const { data: progress } = action.payload;

            return {
              ...file,
              progress,
              complete: progress === 100,
            };
          }

          return file;
        }),
      };
    case 'addRejectedFile':
      return {
        ...state,
        rejectedFiles: [...state.rejectedFiles, action.payload],
      };
    case 'setRejectedFiles':
      return {
        ...state,
        rejectedFiles: action.payload,
      };
    case 'setAcceptedFiles':
    case 'setValidations':
      return {
        ...state,
        ...action.payload,
      };
    case 'addAcceptedFile':
      return {
        ...state,
        acceptedFiles: [...state.acceptedFiles, action.payload],
      };
    case 'updateAcceptedFile':
      return {
        ...state,
        acceptedFiles: state.acceptedFiles.map((file) => {
          if (file.id === action.payload.id) {
            return {
              ...file,
              ...action.payload.data,
            };
          }

          return file;
        }),
      };
    case 'updateFormMeta':
      return {
        ...state,
        token: action.payload.token || state.token,
        formId: action.payload.formId || state.formId,
      };
    default:
      throw new Error(`No action found for "${action}"`);
  }
};

const initial = {
  acceptedFiles: [],
  rejectedFiles: [],
  validations: null,
  validationMessages: null,
  inputStyle: {
    width: '100%',
    height: '100%',
    opacity: '0',
  },
  dropContentStyle: {
    position: 'absolute',
    pointerEvents: 'none',
    width: '100%',
  },
  dropZoneClass: 'ltDropZoneChild',
  lastReportedValue: '',
  fileMaxReached: null,
  token: null,
};

const init = (step) => {
  const acceptedFiles = step.value
    ? Object.values(step.value).map((af) => ({
        ...af,
        complete: true,
        progress: 100,
      }))
    : initial.acceptedFiles;

  return {
    ...initial,
    acceptedFiles,
    attributeId: step.attributeid,
    formId: step.formId,
    formDataKey: `formData.${step.attributeid}`,
  };
};

const createFileId = (file) => btoa(file.size + file.name);

const createFileDTO = (file) => {
  return {
    id: createFileId(file),
    progress: 0,
    name: file.name,
    size: file.size,
    type: file.type,
  };
};

const DocumentUpload = ({ step, formController, formState, ...props } = {}) => {
  const [
    {
      acceptedFiles,
      rejectedFiles,
      validations,
      validationMessages,
      inputStyle,
      dropContentStyle,
      dropZoneClass,
      fileMaxReached,
      formId,
      attributeId,
      token,
      formDataKey,
    },
    dispatch,
  ] = useDispatch(reducer, step, init);

  const propsDispatch = useMemo(() => props.dispatch, [props.dispatch]);

  useUpdateSession(acceptedFiles, formId, attributeId);
  useSetValidations(acceptedFiles, dispatch, step.validation);

  const withAttributeId = useWithAttributeId(attributeId);

  const filesUploading = useRef([]);
  const getFileUploading = useCallback(
    (fileDTO) => {
      return filesUploading.current.find((file) => {
        const id = createFileId(file);

        return id === fileDTO.id;
      });
    },
    [filesUploading.current]
  );

  const fileList = useMemo(
    () => (acceptedFiles || []).concat(rejectedFiles),
    [acceptedFiles, rejectedFiles]
  );

  const containerClassName = withAttributeId(
    'ltFormControlUploader',
    fileMaxReached ? 'ltUploadComplete' : undefined
  );

  const showGroupError = useCallback(() => {
    const field = formController[attributeId];

    return !field
      ? false
      : !field.$form.pristine && !field.$form.validating && field.$form.touched;
  }, [formController, attributeId]);

  const handleGroupErrors = useCallback(() => {
    if (showGroupError()) {
      dispatch('addDropZoneError');
    } else {
      dispatch('removeDropZoneError');
    }
  }, [dispatch, showGroupError]);

  const groupValidators = useMemo(() => {
    return {
      required: (files = []) => {
        handleGroupErrors();

        return (
          !step.validation.required ||
          (files && files.filter((f) => f && f.complete).length > 0)
        );
      },
      maxNumberOfUploads: (files = []) => {
        handleGroupErrors();
        const { maxNumberOfUploads } = step.validation;
        const notCompleteOrFailedOrRejected = files.filter(
          (file) => file && !file.complete && !file.failed && !file.rejected
        );

        return (
          files == null ||
          (notCompleteOrFailedOrRejected.length <= maxNumberOfUploads &&
            files.length <= maxNumberOfUploads)
        );
      },
      minNumberOfUploads: (files = []) => {
        handleGroupErrors();
        const { required, minNumberOfUploads } = step.validation;
        const complete = files.filter((file) => file && file.complete);

        return (
          files == null ||
          (files && !required) ||
          complete.length >= minNumberOfUploads
        );
      },
    };
  }, [handleGroupErrors, step.validation]);

  const reportEngagement = useCallback(
    (formDataCollection, files) => {
      const reportValue =
        Array.isArray(formDataCollection) && formDataCollection.length > 0
          ? getFileNames(formDataCollection) + ','
          : getFileNames(files);

      if (reportValue !== '') {
        reportFieldEngagement(attributeId, reportValue);
      }
    },
    [attributeId]
  );

  const handleUploadProgress = useCallback(
    (file) => (event) => {
      if (event.lengthComputable) {
        dispatch('uploadProgress', {
          id: file.id,
          data: (event.loaded / event.total) * 100,
        });
      }
    },
    [dispatch]
  );

  const handleRequestOnload = useCallback(
    (request, file) => async () => {
      const data = { ...file };

      if (request.status === 200) {
        data.progress = 100;
        data.complete = true;

        try {
          const response = parseRequestResponse(request);

          if (response != null) {
            data.fileUrl = response.fileUrl || undefined;
            data.fileUid = response.fileUid || undefined;
          }
        } catch (err) {
          data.fileUrl = undefined;
        }

        dispatch('updateAcceptedFile', { id: data.id, data });

        const fileIndex = acceptedFiles.length;

        propsDispatch(actions.change(`${formDataKey}[${fileIndex}]`, data));

        if (acceptedFiles.some((af) => af.progress < 100)) {
          propsDispatch(
            actions.resetValidity(formDataKey, [
              'required',
              'maxNumberOfUploads',
              'minNumberOfUploads',
            ])
          );

          propsDispatch(actions.validate(formDataKey, groupValidators));

          const maxReached =
            acceptedFiles.filter((item) => item && item.complete).length ===
            step.validation.maxNumberOfUploads;

          dispatch('updateFileMaxReached', maxReached);
        }
      } else {
        dispatch('updateAcceptedFile', {
          id: data.id,
          data: {
            ...data,
            failed: true,
          },
        });
      }

      propsDispatch(formStatusReset());
      propsDispatch(actions.validate(formDataKey, groupValidators));
    },
    [
      acceptedFiles,
      dispatch,
      formDataKey,
      groupValidators,
      propsDispatch,
      step.validation.maxNumberOfUploads,
    ]
  );

  const upload = useCallback(
    async (file, formIdParam, tokenParam) => {
      const uploadFormId = formIdParam || formId;
      const uploadToken = tokenParam || token;

      const fileData = getFileUploading(file);

      propsDispatch(setFormBusy());

      if (fileMaxReached) {
        propsDispatch(formStatusReset());

        return;
      }

      const url = `${config.apiConfig.rootApiServerPath}${step.urlUploadPath}/${uploadFormId}/${attributeId}`;
      const request = new XMLHttpRequest();

      request.open('POST', url, true);
      request.setRequestHeader(
        'x-lt-phoenix',
        storage.getItem('lt_sessionKey')
      );
      request.setRequestHeader('lt-phoenix-token', uploadToken);
      request.withCredentials = hasAcceptableHostName(url);
      request.upload.onprogress = handleUploadProgress(file);
      request.onload = handleRequestOnload(request, file);

      const formData = new FormData();

      formData.append('file', fileData);

      request.send(formData);
    },
    [
      fileMaxReached,
      acceptedFiles,
      step.urlUploadPath,
      formId,
      attributeId,
      token,
      handleUploadProgress,
      handleRequestOnload,
      propsDispatch,
      dispatch,
      getFileUploading,
    ]
  );

  const handleChange = useCallback(
    (model, value) => async (onChangeDispatch, getState) => {
      const files = value.map(createFileDTO);
      const formDataCollection = getState().formData[attributeId];

      reportEngagement(formDataCollection, files);

      const { minNumberOfUploads, maxNumberOfUploads } = step.validation;
      const fileCount = acceptedFiles.concat(files || []).length;
      const completedFiles = acceptedFiles.filter(
        (file) => file && file.complete
      );
      const maxUploadsReached = maxNumberOfUploads - fileCount < 0;
      const minUploadsReached = completedFiles.length < minNumberOfUploads;

      const errors = {
        maxNumberOfUploads: !maxUploadsReached,
        minNumberOfUploads: !minUploadsReached,
      };

      if (Object.values(errors).some((v) => v === false)) {
        dispatch('addDropZoneError');

        onChangeDispatch(
          actions.change(model, [...acceptedFiles, ...rejectedFiles, ...files])
        );

        if (!errors.maxNumberOfUploads) {
          onChangeDispatch(actions.setValidity(formDataKey, errors));
          onChangeDispatch(actions.setTouched(formDataKey));
          onChangeDispatch(actions.validate(formDataKey, groupValidators));

          return;
        }
      } else {
        dispatch('removeDropZoneError');
      }

      for await (const file of value) {
        const dto = createFileDTO(file);

        if (!maxUploadsReached) {
          const err = {};
          const isUploaded =
            listContains(acceptedFiles, dto.id) &&
            !listContains(rejectedFiles, dto.id);

          let hasError = false;

          if (isUploaded) {
            err.alreadyUploaded = false;
            hasError = true;
          }

          for (const key of Object.keys(validations)) {
            const propName = getPropertyMap(key);
            const propValue = file[propName];

            if (propName) {
              err[key] = validations[key](propValue);

              if (!err[key]) {
                hasError = true;
                dto.reason = validationMessages[key](propValue);
              }
            }
          }

          const canBeUploaded =
            !hasError && completedFiles.length < maxNumberOfUploads;

          if (canBeUploaded) {
            onChangeDispatch(setFormBusy());

            const { formMeta } = getState();

            dispatch('updateFormMeta', {
              formId: formMeta.formId,
              token: formMeta.utoken,
            });

            dispatch('addAcceptedFile', dto);
            filesUploading.current.push(file);

            await upload(dto, formMeta.formId, formMeta.utoken);
          } else {
            const isAlreadyRejected = listContains(rejectedFiles, dto.id);

            if (!isAlreadyRejected) {
              dispatch('addRejectedFile', {
                ...dto,
                rejected: true,
              });
            }
          }

          if (hasError) {
            dispatch('addDropZoneError');
          } else {
            dispatch('removeDropZoneError');
          }

          const index = getState().formData[attributeId].length;
          const key = `formData.${attributeId}[${index}]`;

          onChangeDispatch(actions.change(key, dto));
          onChangeDispatch(actions.setValidity(key, err));
          onChangeDispatch(actions.setTouched(key));
        }
      }

      if (rejectedFiles.length > 0) {
        onChangeDispatch(actions.validate(formDataKey, groupValidators));
      } else {
        onChangeDispatch(actions.change(model, acceptedFiles));
      }
    },
    [
      step.validation,
      acceptedFiles,
      rejectedFiles,
      dispatch,
      attributeId,
      reportEngagement,
      formDataKey,
      groupValidators,
      validations,
      validationMessages,
      upload,
    ]
  );

  const handleRetry = useCallback(
    (fileId) => async () => {
      const file = acceptedFiles.find((f) => f.id === fileId);

      await upload(file);
    },
    [propsDispatch, upload]
  );

  const dropZoneStyle = useMemo(() => ({ position: 'relative' }), []);

  const DropContent = useCallback(
    ({ children, ...props } = {}) => (
      <div className={dropZoneClass} style={dropContentStyle} {...props}>
        {children}
      </div>
    ),
    [dropZoneClass, dropContentStyle]
  );

  const textControlPropsMap = useMemo(
    () => ({
      fieldValue: (props) => props.fieldValue,
    }),
    []
  );

  const handleFileAdd = useCallback(
    (mappedProps) => {
      if (acceptedFiles.length > 0) {
        mappedProps.dispatch(actions.change(formDataKey, acceptedFiles));
      }

      if (rejectedFiles.length > 0) {
        dispatch('setRejectedFiles', []);
      }

      dispatch('removeDropZoneError');

      propsDispatch(
        actions.resetValidity(formDataKey, [
          'minNumberOfUploads',
          'maxNumberOfUploads',
        ])
      );
    },
    [acceptedFiles, rejectedFiles.length, dispatch, propsDispatch, formDataKey]
  );

  const fileControlPropsMap = useMemo(
    () => ({
      onClick: (mappedProps) => () => handleFileAdd(mappedProps),
      onDrop: (mappedProps) => {
        return (e) => {
          if (step.allowDrop === false) {
            e.stopPropagation();
            e.preventDefault();

            return false;
          }

          handleFileAdd(mappedProps);
        };
      },
    }),
    [handleFileAdd, step.allowDrop]
  );

  return (
    <div className={containerClassName}>
      <div className={withAttributeId('ltDropWrapper')}>
        <div className={withAttributeId('ltDropZone')} style={dropZoneStyle}>
          <Control.file
            id={attributeId}
            name={attributeId}
            model={formDataKey}
            defaultValue={[]}
            validators={groupValidators}
            multiple={true}
            disabled={fileMaxReached === true}
            accept={formatAcceptList(step.validation.inList)}
            changeAction={handleChange}
            style={inputStyle}
            mapProps={fileControlPropsMap}
          />
          {step.dropcontent ? (
            <DropContent
              dangerouslySetInnerHTML={{ __html: step.dropcontent }}
            />
          ) : (
            <DropContent>
              {step.allowDrop ? 'Drag files here or ' : null}{' '}
              <span style={{ fontWeight: 'bold' }}>Browse</span>
            </DropContent>
          )}
        </div>
      </div>
      {fileList.length > 0 && (
        <FileList
          attributeId={attributeId}
          fileList={fileList}
          onRetry={handleRetry}
        />
      )}
      {formState.formStatus !== statusType.FORM_BUSY && (
        <Errors
          component={({ children }) => (
            <div
              className={`${withAttributeId(
                'ltHasError'
              )} ${errorComponentClassName}`}
            >
              {children.indexOf('<') === -1 ? (
                children
              ) : (
                // eslint-disable-next-line react/no-danger
                <span dangerouslySetInnerHTML={{ __html: children }} />
              )}
            </div>
          )}
          wrapper="div"
          show={(field) => {
            return !field.pristine && !field.validating && field.touched;
          }}
          model={formDataKey}
          messages={(() => {
            const field = formController[attributeId];
            // Some early renders do not have the field popped in the
            // redux state - if it's not there, don't check
            // errors.
            if (!field || !field.$form || !field.$form.validity) {
              return;
            }

            const {
              maxNumberOfUploadsMessage,
              minNumberOfUploadsMessage,
              requiredMessage,
            } = step.validation;

            const { maxNumberOfUploads, minNumberOfUploads, required } =
              field.$form.validity;

            // The required and min upload validations are a bit redundant, if
            // a min is required then the upload is implicitly required.  In an
            // to prevent similar validation messages, if we haven't failed for
            // min uploads we but have for required we'll show the required
            // validation message.
            if (minNumberOfUploads == null && required === false) {
              return {
                required: requiredMessage,
              };
            }

            // The way that the validation occurs, if a user drops more files
            // than are allowed, it prevents additional processing and when the
            // group validations are dispatched, both min and max are set to
            // false.  To prevent showing both min and max errors, if max is
            // set and false, use that error message.
            if (maxNumberOfUploads === false) {
              return {
                maxNumberOfUploads: maxNumberOfUploadsMessage,
              };
            }

            return {
              minNumberOfUploads: minNumberOfUploadsMessage,
            };
          })()}
        />
      )}
      {!fileMaxReached && (
        <Control.text
          model={formDataKey}
          component={(props) => (
            <Subtext
              text={step.help}
              forceShow={step.persistHelpText}
              classExt={attributeId}
            >
              {props}
            </Subtext>
          )}
          mapProps={textControlPropsMap}
        />
      )}
    </div>
  );
};

DocumentUpload.propTypes = {
  step: PropTypes.object,
  formController: PropTypes.object,
  dispatch: PropTypes.func,
  formState: PropTypes.object,
};

const mapStateToProps = (state) => ({
  formController: getFormController(state),
  formState: getFormStateController(state),
});

export default connect(mapStateToProps)(DocumentUpload);
