import { useEffect, useCallback } from 'react';
import {
  getValidations,
  getValidationMessages,
} from '../../../../validators/validators';
import * as validTypes from '../../../../validators/validationTypes';

const getVal = (origVal) => origVal.name;

export default function useSetValidations(files, dispatch, stepValidation) {
  const getTransform = useCallback(
    (property, validation) => {
      if (property) {
        switch (validation) {
          case validTypes.INLIST:
            return property.split('.').slice(-1)[0];
          case validTypes.REQUIRED:
            return property !== undefined ? 'true' : 'false';
          case validTypes.MAX_NUMBER_OF_UPLOADS:
            return files.length;
          default:
            return property;
        }
      }
      return null;
    },
    [files]
  );

  useEffect(() => {
    const validations = {
      validations: getValidations(
        stepValidation,
        undefined,
        undefined,
        getTransform
      ),
      validationMessages: {
        ...getValidationMessages(stepValidation, getVal),
        ...{
          alreadyUploaded: (val) => {
            return stepValidation.alreadyUploadedMessage
              ? stepValidation.alreadyUploadedMessage.replace('{val}', val.name)
              : '';
          },
        },
      },
    };

    dispatch('setValidations', validations);

    if (stepValidation.maxNumberOfUploads < stepValidation.minNumberOfUploads) {
      throw new Error(
        'Improperly configured upload node. Max uploads is less than Min uploads'
      );
    }
    if (stepValidation.required && !stepValidation.minNumberOfUploads) {
      throw new Error(
        'Improperly configured upload node. Minimum number of uploads not defined for required field'
      );
    }

    if (!stepValidation.required && stepValidation.minNumberOfUploads) {
      throw new Error(
        'Improperly configured upload node. Minimum number of uploads was set for an optional field'
      );
    }
  }, [stepValidation, getTransform]);
}
