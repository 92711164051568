const LOCAL = 'local';
const LOCALAPI = 'localapi';
// eslint-disable-next-line no-unused-vars
const DEV = 'dev';
const STAGE = 'staging';
const PREPROD = 'preprod';
const PROD = 'prod';

const getPhoenixApiPath = (env) => {
  switch (env) {
    case LOCAL:
      return 'https://www.dev.lendingtree.com/path';
    case LOCALAPI:
      return process.env.LOCAL_API_URL || 'http://localhost:8080';
    case PROD:
      return 'https://www.lendingtree.com/path';
    default:
      return `https://www.${env}.lendingtree.com/path`;
  }
};

window.getPhoenixApiPath = getPhoenixApiPath;

export const getPhoenixEnvironment = () => {
  return process.env.PHOENIX_ENVIRONMENT || 'local';
};

window.getPhoenixEnvironment = getPhoenixEnvironment;

const getLTALaunchKey = (env) => {
  switch (env) {
    case STAGE:
    case PREPROD:
      return 'EN8301ac0012d046b4a1d71561e2f578af';
    case PROD:
      return 'EN21cb38a11dec4a578659a774081ffe40';
    default:
      return 'EN3e5a7bd8de7c4a548a0e15e58ec317d3';
  }
};

const getGoogleAPIKey = (env) => {
  switch (env) {
    case STAGE:
    case PREPROD:
      return 'AIzaSyAsTeRQQAswmGoLviLdco4LXxILjmDJ_qY';
    case PROD:
      return 'AIzaSyDLgyfpCMxWhY8ZyLLMoQPRD1TSKA-IGzc';
    default:
      return 'AIzaSyBWmjQR1g0NYqvLd0U8dq2xo6xkf_Z5cvw';
  }
};

const getAuthenticationPath = (env) => {
  switch (env) {
    case LOCAL:
      return `https://auth.dev.lendingtree.com/api/whoami`;
    case STAGE:
    case PREPROD:
      return `https://auth.staging.lendingtree.com/api/whoami`;
    case PROD:
      return `https://auth.lendingtree.com/api/whoami`;
    default:
      return `https://auth.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com/api/whoami`;
  }
};

const getBaseSplitterUrl = (env) => {
  // Assume starting state is prod, no env specification needed
  let domainEnv = '.';
  if (env !== PROD) {
    domainEnv = env === LOCAL ? '.dev.' : `.${env}.`;
  }
  return `https://splitter${domainEnv}lendingtree.com/api/split`;
};

const getSplitterQuery = (env, product) => {
  switch (product.toLowerCase()) {
    case 'personal':
      return `?id=mc-personal`;
    case 'business':
      return env === STAGE ? `?id=staging-mc-business` : `?id=mc-business`;
    case 'automobile.purchase':
      return `?id=mc-auto-purchase`;
    case 'automobile.refinance':
      return `?id=mc-auto-refi`;
    case 'automobile.leasebuyout':
      return env === PROD
        ? `?id=mc-auto-purchase&AutomobileLoanType=BUYOUTLEASE`
        : `?id=mc-auto-buyout`;
    case 'purchase':
      return `?id=mc-purchase`;
    case 'refinance':
      return `?id=mc-refi`;
    case 'homeequity':
      return `?id=mc-he`;
    case 'reversemortgage':
      return `?id=mc-reverse`;
    case 'newsl':
    case 'slrefi':
      return `?id=mc-student-new&esourceid=520290&cchannel=offline&csource=lendingtree.com&cepage=%252f`;
    default:
      break;
  }
};

/**
 * PHX-5556: Inside flow splitter URLs for MyLT.
 * List currently maintained under "Action URLs" column in Confluence:
 * https://confluence.internal.tree.com/pages/viewpage.action?spaceKey=MYLT&title=URLS+for+form+flows
 */
export const getMyLtRedirectPath = (product) => {
  let splitterUrl = getBaseSplitterUrl(process.env.PHOENIX_ENVIRONMENT);
  let splitterQuery = getSplitterQuery(
    process.env.PHOENIX_ENVIRONMENT,
    product
  );
  if (splitterQuery) {
    return `${splitterUrl}${splitterQuery}`;
  }
};

export default {
  apiConfig: {
    rootApiServerPath: getPhoenixApiPath(process.env.PHOENIX_ENVIRONMENT),
    authenticationPath: getAuthenticationPath(process.env.PHOENIX_ENVIRONMENT),
    springUri:
      process.env.PHOENIX_ENVIRONMENT === PROD
        ? 'https://spring.lendingtree.com'
        : `https://spring.${process.env.PHOENIX_ENVIRONMENT}.lendingtree.com`,
    formStepPath: '/api/form/step/',
    emailValidatePath: '/api/validate/email',
    phoneValidatePath: '/api/validate/phone',
    zipcodeValidatePath: '/api/validate/zipcode/',
    servicableFromZipValidationPath: '/api/validate/servicableFromZip/',
    stateCodeFromZipValidatePath: '/api/validate/stateCodeFromZip/',
    equifaxPasscodeValidatePath: '/api/validate/equifax/instaTouch/passcode/',
    nmlsIdValidatePath: '/api/validate/nmlsid/',
    cityByStateValidatePath: '/api/validate/cityByState/',
    updateSessionUrlPath: '/api/session/update/',
    ltLoginPath: '/api/auth/redirect/preLogin/',
    verifySocialLogin: '/api/auth/verifySocialLogin/',
    updateAbandonEmail: '/api/enrich/abandoned/email/',
    indirectSupportValidationPath: '/api/validate/indirectSupport/',
    googleMapsAPIScriptPath: 'https://maps.googleapis.com/maps/api/js',
    googleMapsAPIKey: getGoogleAPIKey(process.env.PHOENIX_ENVIRONMENT),
    LTALaunchKey: getLTALaunchKey(process.env.PHOENIX_ENVIRONMENT),
    sdkLoaderUri:
      process.env.PHOENIX_ENVIRONMENT === PROD
        ? 'https://cdn.lendingtree.com/lt-sdk-ui/loader.js'
        : 'https://cdn.lendingtree.com/lt-sdk-ui-stage/loader.js',
  },
};
