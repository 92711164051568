import React from 'react';
import PropTypes from 'prop-types';
import './softOffer.css';
import {actions} from 'react-redux-form';
import {connect} from "react-redux";
import {getFormData} from "../../../selectors/formSelector";
import SoftOfferDetails from "./softOfferDetails";

class SoftOffer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  acceptOffer() {
    // Flag the offer as accepted
    this.props.dispatch(actions.change(`formData.AcceptedSoftOffer_${this.props.step.softoffertype}`, "1"));

    // Add EstimatedMonthlySavings to a formData aggregate
    const currentEstimatedSavings = this.props.formData.EstimatedMonthlySavings || 0;
    this.props.dispatch(actions.change(`formData.EstimatedMonthlySavings`, currentEstimatedSavings + this.props.step.EstimatedMonthlySavings));
    this.props.handleNext();
  }

  declineOffer() {
    this.props.dispatch(actions.change(`formData.AcceptedSoftOffer_${this.props.step.softoffertype}`, "0"));
    this.props.handleNext();
  }

  renderHasOffers() {
    // Try to get user friendly label for soft offer
    const label = this.props.step.productLabels && this.props.step.productLabels[this.props.step.softoffertype];

    return (
        <div className="softOffer">
            <div className="softOfferHeader">
                <div className="ltFormHeader">{this.props.step.foundoffertext}</div>
                <div className="softOfferSavingsAmt">${this.props.step.EstimatedMonthlySavings}/mo</div>
                <small className="softOfferEstimated">(estimated)</small>
            </div>
            <SoftOfferDetails
                Fees                               = {this.props.step.Fees}
                LoanAmount                         = {this.props.step.LoanAmount}
                APRPercentage                      = {this.props.step.APRPercentage}
                SoftOfferType                      = {this.props.step.softoffertype}
                LoanTermInMonths                   = {this.props.step.LoanTermInMonths}
                EstimatedMonthlySavings            = {this.props.step.EstimatedMonthlySavings}
                PrincipalAndInterestMonthlyPayment = {this.props.step.PrincipalAndInterestMonthlyPayment}
              label                              = {label}
            />
            <div className="softOfferBtnSec">
                <button type="button" className="ltFormButton declineBtn" onClick={this.declineOffer.bind(this)}>No Thanks</button>
                <button type="button" className="ltFormButton acceptBtn" onClick={this.acceptOffer.bind(this)}>Save and Continue</button>
            </div>
        </div>
    );
  }

  renderNoOffers() {
    return (
        <div className="softOfferSpinnerSec">
            <div className="spinnerMsg">{this.props.step.nofoundoffertext}</div>
            <div className="softOfferBtnSec">
                <button type="button" className="ltFormButton" onClick={this.declineOffer.bind(this)}>Continue</button>
            </div>
        </div>
    );
  }


  render() {
    return this.props.step.EstimatedMonthlySavings > 0 ?
      this.renderHasOffers() :
      this.renderNoOffers();
  }
}

SoftOffer.propTypes = {
  // Platform props
  step: PropTypes.object,
  dispatch: PropTypes.func,
  formData: PropTypes.object,
  handleNext: PropTypes.func,

  // Builder props
  softoffertype: PropTypes.string,
  foundoffertext: PropTypes.string,
  nofoundoffertext: PropTypes.string,

  // Soft offer props
  APRPercentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  LoanAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  PrincipalAndInterestMonthlyPayment: PropTypes.number,
  EstimatedMonthlySavings: PropTypes.number,
  SoftOfferAttribute: PropTypes.string,
  AcceptedSoftOffer: PropTypes.number,
  LoanTermInMonths: PropTypes.number,
  LenderId: PropTypes.string,
  Fees: PropTypes.number,
};


function mapStateToProps(state) {
  return {
    formData: getFormData(state)
  };
}

export default connect(mapStateToProps)(SoftOffer);