import * as stepFuncs from '../../../../utils/stepFunctions';

const getDividerChar = ({ formatter }) => stepFuncs.getDividerForFormat(formatter);

const useSSNMultipartFormat = ({ step }) => {
  const dividerChar = getDividerChar(step);

  return {
    handleValueCombine: () => (val) => `${val.box1}${val.box2}${val.box3}`,
    splitFunction: () => (val) => {
      if (val.length !== 9) return;

      return {
        box1value: (val.indexOf(dividerChar) > -1) ? val.split(dividerChar)[0] : val.substring(0, 3),
        box2value: (val.indexOf(dividerChar) > -1) ? val.split(dividerChar)[1] : val.substring(3, 5),
        box3value: (val.indexOf(dividerChar) > -1) ? val.split(dividerChar)[2] : val.substring(5, 9)
      };
    }
  };
};

export default useSSNMultipartFormat;
