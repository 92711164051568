import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
class ToolTip extends Component {
  constructor() {
    super();
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleShowTooltip = this.handleShowTooltip.bind(this);
    this.state = { isTooltipShown: false, hasHover: false };
    this.timer = undefined;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isTooltipShown: false });
    }
  }

  tooltipClose() {
    if (!this.timer || !this.state.hasHover) {
      this.setState({ isTooltipShown: false });
    }
  }

  handleTooltipClose(e) {
    e.preventDefault();
    if (e.type === 'mouseleave') {
      this.setState({ hasHover: false });
    }
    this.tooltipClose();
  }

  handleShowTooltip(e) {
    if (e.type !== 'touchstart') e.preventDefault();

    this.setState({ isTooltipShown: true, hasHover: true });

    if (e.type === 'click') {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.tooltipClose();
        this.timer = undefined;
      }, 1000);
    }
  }

  render() {
    let { content, iconType, iconPosition, tooltipDisplay, showxonTooltip } =
      this.props;
    let tooltipContainer;

    if (tooltipDisplay === 'Click' || tooltipDisplay === undefined) {
      tooltipContainer = (
        <a onClick={(e) => this.handleShowTooltip(e)}>
          <span className={`ltFormTooltipIcon${iconType}`}>
            <i />
          </span>
        </a>
      );
    } else if (tooltipDisplay === 'Hover') {
      tooltipContainer = (
        <a
          onMouseEnter={(e) => this.handleShowTooltip(e)}
          onMouseLeave={(e) => this.handleTooltipClose(e)}
          onTouchStart={(e) => this.handleShowTooltip(e)}
        >
          <span className={`ltFormTooltipIcon${iconType}`}>
            <i />
          </span>
        </a>
      );
    } else if (tooltipDisplay === 'Both') {
      tooltipContainer = (
        <a
          onClick={(e) => this.handleShowTooltip(e)}
          onMouseEnter={(e) => this.handleShowTooltip(e)}
          onMouseLeave={(e) => this.handleTooltipClose(e)}
        >
          <span className={`ltFormTooltipIcon${iconType}`}>
            <i />
          </span>
        </a>
      );
    }
    return (
      <div
        ref={this.wrapperRef}
        className={`ltFormTooltip ltFormTooltip${iconPosition}`}
        style={window.LT5theme ? { display: 'inline' } : {}}
      >
        <div>{content && content.length > 0 ? tooltipContainer : null}</div>
        {this.state.isTooltipShown ? (
          <aside className="ltTooltipSidebar">
            <div>
              {showxonTooltip ? (
                <span
                  className="tooltipClose"
                  onClick={(e) => this.handleTooltipClose(e)}
                >
                  &#10006;
                </span>
              ) : null}
              <div className="content">
                {/*eslint-disable-next-line react/no-danger*/}
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            </div>
          </aside>
        ) : null}
      </div>
    );
  }
}
ToolTip.propTypes = {
  content: PropTypes.string,
  show: PropTypes.bool,
  dispatch: PropTypes.func,
  iconType: PropTypes.string,
  iconPosition: PropTypes.string,
  showxonTooltip: PropTypes.bool,
  tooltipDisplay: PropTypes.string,
};
export default connect()(ToolTip);
