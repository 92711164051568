import React, { Component } from 'react';
import PropTypes from 'prop-types';
/**
 PL-563: Component for the SLider Plus and Minus buttons. When the component is mounted it listens on the following events
 1. click : for desktop's increment or decrement the slider once
 2. mousedown : while the mouse is kept pressed, keep incrementing the slider in 300ms intervals
 3. mouseup : when the mouse is released, stop the slider increment/decrement timer
 4. touchstart: corresponding mousedown event for mobile or other touch based devices. The event is registered with passive false
 so that we can prevent mousedown events from also firing.
 5. touchend : corresponding mouseup event for mobile or touch devices. The event is registered with passive false
 so that we can prevent mousedown events from also firing
 6. touchmove: After touchstart event if user shifts touch away from the slider button, without lifting the touch stop the slider 
 incement/decrement timer.
 7. mouseleave: same behavior as touchmove above for desktop devices.
 */
class SliderButton extends Component {
  constructor(props) {
    super(props);
    this.sliderButtonRef = React.createRef();
    this.timer = undefined;
  }

  componentDidMount() {
    this.props.clickAction &&
      this.sliderButtonRef.current.addEventListener(
        'click',
        this.props.clickAction
      );
    this.props.startTimerAction &&
      this.sliderButtonRef.current.addEventListener(
        'mousedown',
        this.props.startTimerAction
      );
    this.props.stopTimerAction &&
      this.sliderButtonRef.current.addEventListener(
        'mouseup',
        this.props.stopTimerAction
      );
    this.props.startTimerAction &&
      this.sliderButtonRef.current.addEventListener(
        'touchstart',
        this.props.startTimerAction,
        { passive: false }
      );
    this.props.stopTimerAction &&
      this.sliderButtonRef.current.addEventListener(
        'touchend',
        this.props.stopTimerAction,
        { passive: false }
      );
    this.props.touchMoveAction &&
      this.sliderButtonRef.current.addEventListener(
        'touchmove',
        this.props.touchMoveAction,
        { passive: false }
      );
    this.props.stopTimerAction &&
      this.sliderButtonRef.current.addEventListener(
        'mouseleave',
        this.props.stopTimerAction
      );
  }

  componentWillUnmount() {
    this.props.clickAction &&
      this.sliderButtonRef.current.removeEventListener(
        'click',
        this.props.clickAction
      );
    this.props.startTimerAction &&
      this.sliderButtonRef.current.removeEventListener(
        'mousedown',
        this.props.startTimerAction
      );
    this.props.stopTimerAction &&
      this.sliderButtonRef.current.removeEventListener(
        'mouseup',
        this.props.stopTimerAction
      );
    this.props.startTimerAction &&
      this.sliderButtonRef.current.removeEventListener(
        'touchstart',
        this.props.startTimerAction
      );
    this.props.stopTimerAction &&
      this.sliderButtonRef.current.removeEventListener(
        'touchend',
        this.props.stopTimerAction
      );
    this.props.touchMoveAction &&
      this.sliderButtonRef.current.removeEventListener(
        'touchmove',
        this.props.touchMoveAction
      );
    this.props.stopTimerAction &&
      this.sliderButtonRef.current.removeEventListener(
        'mouseleave',
        this.props.stopTimerAction
      );
  }

  render() {
    let { name, displayText } = this.props;
    return (
      <div className={`ltFormRange${name}`}>
        <button
          type="button"
          name={`range${name}`}
          id={`range${name}Btn`}
          ref={this.sliderButtonRef}
          className={`ltFormControlRange${name}`}
        >
          {displayText}
        </button>
      </div>
    );
  }
}
SliderButton.propTypes = {
  name: PropTypes.string,
  clickAction: PropTypes.func,
  startTimerAction: PropTypes.func,
  stopTimerAction: PropTypes.func,
  touchMoveAction: PropTypes.func,
  displayText: PropTypes.string,
};
export default SliderButton;
