// Intialize a new Abort Controller so cancelling any previous requests
let abortController = new AbortController();

/* This Logic is created based on the assumtion that there are no multiple process calls 
 for aborting to keep track of and new instance is created when instantiated by a component */
//  abortableFetch takes argument url and abort which is configurable via form builder
export const abortableFetch = (url, abort) => {
  if (url && abort) abortController.abort();
  abortController = new AbortController();
  let signal = abortController.signal;
  return signal;
};
