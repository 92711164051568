import { createSelector } from 'reselect';

const getCurrentSteps = (state) => state.formDefinition;

export const getCurrentStepInfo = createSelector(
  [getCurrentSteps],
  (formDefinition) => {
    return formDefinition.stepSet;
  }
);

const getForm = (state) => state.formDefinition;

export const getFormDefinition = createSelector([getForm], (formDefinition) => {
  return formDefinition;
});

const getData = (state) => state.formData;

export const getFormData = createSelector([getData], (formData) => {
  return formData;
});

const getController = (state) => state.forms.formData;

export const getFormController = createSelector(
  [getController],
  (formController) => {
    return formController;
  }
);

const getFormState = (state) => state.formState;

export const getFormStateController = createSelector(
  [getFormState],
  (formState) => {
    return formState;
  }
);

const getFormMeta = (state) => state.formMeta;

export const getFormMetaController = createSelector(
  [getFormMeta],
  (formMeta) => {
    return formMeta;
  }
);

const getChatHistory = (state) => state.chatHistory;

export const getChatHistoryController = createSelector(
  [getChatHistory],
  (chatHistory) => {
    return chatHistory;
  }
);
