import React, { useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../../../common/FormProgress';
import { fileSizeFormatter } from '../../../../utils/formatters';
import useWithAttributeId from './useWithAttributeId';

export default function File({
  onRetry,
  attributeId,
  name,
  size,
  progress,
  failed,
  rejected,
  complete,
  reason,
}) {
  const withAttributeId = useWithAttributeId(attributeId);
  const formatFileName = useCallback((fileName = '') => {
    return fileName.split('.').slice(-1)[0].toLowerCase();
  }, []);

  return (
    <div className={withAttributeId('ltFileRow')}>
      <div className={withAttributeId('ltFileAvatarWrapper')}>
        <div className={withAttributeId('ltFileAvatar')}>
          {formatFileName(name)}
        </div>
      </div>
      <div className={withAttributeId('ltFileDetailsWrapper')}>
        <div className={withAttributeId('ltFileName')} title={name}>
          {name}
        </div>
        <div className={withAttributeId('ltFileSize')}>
          {fileSizeFormatter(size)}
        </div>
        <ProgressBar percentComplete={progress}>{progress}</ProgressBar>
      </div>
      {failed ? (
        <div
          title="Click to try again"
          className="ltFormRowFailure"
          onClick={onRetry}
        />
      ) : rejected ? (
        <Fragment>
          <div title="Fail reason" className="ltFormRowFailure" />
          <div className="ltFileProcessing">{reason}</div>
        </Fragment>
      ) : complete && progress === 100 ? (
        <div className="ltFileRowComplete" />
      ) : (
        <div className="ltFileProcessing">Processing...</div>
      )}
    </div>
  );
}

File.propTypes = {
  onRetry: PropTypes.func,
  attributeId: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  progress: PropTypes.number,
  failed: PropTypes.bool,
  rejected: PropTypes.bool,
  complete: PropTypes.bool,
  reason: PropTypes.string,
};
