import configSetting from './env';
import * as products from '../common/producttypes';
import * as logtypes from '../common/logtypes';
import * as eventnames from '../common/trackingeventnames';
import storageFactory from '../utils/storageFactory';
import { getFormId } from '../utils/pathHelper';
import { PII_FIELDS, isPII, maskPII, concealPII } from '../utils/piiHelper';
import redcuersConfig from '../reducers/config';
import isoFetch from 'isomorphic-fetch';
import { fetch as whatwgFetch } from 'whatwg-fetch';
import ltAnalyticsService, {
  analyticsIsLoaded,
} from '../utils/ltAnalyticsService';
import { getStore } from '../store';
import { changeMeta } from '../actions/formActions';
import { actions } from 'react-redux-form';

let storage = storageFactory();
let config =
  configSetting[process.env.PHOENIX_ENVIRONMENT] || configSetting.dev;

const getPropertiesForCheckout = (trackProperties) => {
  let data = getProperties(trackProperties);
  data.orderId = data.qformuid;
  data.currency = 'USD';
  data.products = [{ name: data.product, quantity: 1 }];
  return data;
};
const getPropertiesForTargetProfile = (trackProperties) => {
  let profileParams = {};
  Object.keys(trackProperties).forEach((key) => {
    profileParams['profile.' + key] = trackProperties[key];
  });
  return profileParams;
};

const getPageTrackName = (formId, pageName) => `FORM: ${formId}/${pageName}/`;
const getProperties = (trackProperties) => {
  let data = Object.assign({}, trackProperties);
  if (window.ltPhoenixConfig && window.ltPhoenixConfig.clientTrackingData) {
    data = Object.assign(data, window.ltPhoenixConfig.clientTrackingData);
  }
  if (window.ltPhoenixConfig && window.ltPhoenixConfig.marketingOverrides) {
    data = { ...data, ...window.ltPhoenixConfig.marketingOverrides };
  }
  data.path = getPageTrackName(
    trackProperties.formId,
    trackProperties.stepName
  );
  data.qformuid = trackProperties.formUuid;
  data['step-index'] = trackProperties.stepIndex;
  data.formTheme = window.ltFormTheme || 'none';
  data.pageName = window.ltPageName || 'none';
  data.pageTheme = window.ltPageTheme || 'none';
  data.formBranch = `${data.pageName} | ${data.pageTheme} | ${data.formTheme}`;
  window.ltTelNum ? (data.telNum = window.ltTelNum) : undefined;
  data.environment = process.env.PHOENIX_ENVIRONMENT;

  for (let i = 0; i < PII_FIELDS.length; i++) {
    if (Object.prototype.hasOwnProperty.call(data, PII_FIELDS[i])) {
      delete data[PII_FIELDS[i]];
    }
  }
  //clean out nontracking fields
  delete data.validations;
  delete data.stepName;
  delete data.stepIndex;
  delete data.formUuid;

  // Remove any OOW entries from our payload
  // They are unique keys for every user and will fill up the 1600 columns we are
  // allowed in Redshift
  Object.keys(data).forEach((key) => {
    if (parseInt(key)) delete data[key];
    if (data[key] && typeof data[key] !== 'string' && data[key].length > 0)
      data[key] = data[key].join(',');
  });

  return data;
};

const getPropertiesForErrorReporting = (props) => {
  if (props && props.displayedSteps) {
    for (let i = 0; i < props.displayedSteps.length; i++) {
      if (
        isPII(props.displayedSteps[i].attributeid) &&
        props.displayedSteps[i]
          .value /*&& process.env.PHOENIX_ENVIRONMENT === 'prod'*/
      )
        props.displayedSteps[i].value = maskPII(props.displayedSteps[i].value);
    }
  }
  return Object.assign(
    {},
    { sessionId: storage.getItem('lt_sessionKey') },
    props,
    { formId: getFormId() }
  );
};

const logToAtatus = (options) => {
  try {
    const reportData = getPropertiesForErrorReporting(options.metaData);
    if (window.atatus) {
      atatus.notify(options.error, reportData, [
        `sessionId: ${reportData.sessionId}`,
        `formUuid: ${reportData.formUuid}`,
      ]);
    }
  } catch (error) {
    /*consume */
  } finally {
    if (process.env.PHOENIX_ENVIRONMENT !== 'prod')
      // eslint-disable-next-line no-console
      console.error(options.error);
  }
};

export const ltLogger = (
  logeventtype,
  event,
  trackProperties,
  steps,
  options
) => {
  try {
    if (
      !trackProperties ||
      (typeof trackProperties === 'object' &&
        Object.keys(trackProperties).length === 0)
    ) {
      let meta = getStore().getState().formMeta;
      trackProperties = { ...meta };
    }
    let mappedTrackProperties = getProperties(trackProperties);
    let cleanTrackProperties = concealPII(trackProperties);
    switch (logeventtype) {
      case logtypes.PAGE: {
        if (
          window.adobe &&
          window.adobe.target &&
          window.adobe.target.trackEvent
        ) {
          window.adobe.target.trackEvent({
            mbox: 'target-global-mbox',
            params: getPropertiesForTargetProfile(mappedTrackProperties),
          });
        }
        if (
          window.adobe &&
          window.adobe.target &&
          window.adobe.target.triggerView
        ) {
          window.adobe.target.triggerView(trackProperties.stepName);
        }

        handleEngagements(mappedTrackProperties);

        if (trackProperties.stepName.toLowerCase().indexOf('oow') === 0) {
          const oowIndex = trackProperties.stepName.split(' ')[1]?.trim();
          if (oowIndex != undefined && !oowEngagementsFired[oowIndex]) {
            oowEngagementsFired[oowIndex] = true;
            ltAnalyticsService(
              `${eventnames.OOW_ENGAGEMENT}${oowIndex}`,
              mappedTrackProperties
            );
          }
        }
        if (
          steps[0].interstitial !== undefined ||
          steps[0].interstitial === true
        ) {
          handlePromotionsViewed({
            ...mappedTrackProperties,
            ...cleanTrackProperties,
          });
        }
        if (
          trackProperties.stepName.toLowerCase().indexOf('oops') === 0 &&
          !oopsShownFired
        ) {
          oopsShownFired = true;
          ltAnalyticsService(
            eventnames.OOPS_SHOWN,
            mappedTrackProperties,
            options
          );
        }
        let pageTrackProperties = Object.assign({}, mappedTrackProperties);
        pageTrackProperties['step-name'] = trackProperties.stepName;
        ltAnalyticsService(
          pageTrackProperties.path,
          pageTrackProperties,
          options,
          'page'
        );

        break;
      }
      case logtypes.TRACK: {
        switch (event) {
          case eventnames.FORM_POSTED: {
            if (!formPostedEventFired) {
              formPostedEventFired = true;
              ltAnalyticsService(
                eventnames.FORM_POSTED,
                mappedTrackProperties,
                options
              );
            }
            break;
          }
          case eventnames.CHECKOUT_STARTED: {
            if (!checkoutStartedFired) {
              checkoutStartedFired = true;
              ltAnalyticsService(
                event,
                getPropertiesForCheckout(trackProperties),
                options
              );
            }
            break;
          }
          case eventnames.FORM_CONVERSION: {
            fireAndSetformConversionEvent(mappedTrackProperties, options);
            break;
          }
          case eventnames.AGREED_TERMS: {
            if (!agreedTermsFired) {
              agreedTermsFired = true;
              ltAnalyticsService(event, mappedTrackProperties, options);

              if (
                (mappedTrackProperties.product &&
                  mappedTrackProperties.product === products.MORTGAGE) ||
                mappedTrackProperties.product === products.PURCHASE ||
                mappedTrackProperties.product === products.REFI ||
                mappedTrackProperties.product.toLowerCase() ===
                  products.HOME_EQUITY.toLowerCase()
              ) {
                fireAndSetformConversionEvent(mappedTrackProperties, options);
              }
              if (!checkoutStartedFired)
                ltAnalyticsService(
                  eventnames.CHECKOUT_STARTED,
                  getPropertiesForCheckout(trackProperties)
                );
            }
            break;
          }
          case eventnames.LENDER_CONSENT: {
            if (!lenderConsentFired) {
              lenderConsentFired = true;
              ltAnalyticsService(event, mappedTrackProperties, options);

              if (
                (mappedTrackProperties.product &&
                  mappedTrackProperties.product === products.MORTGAGE) ||
                mappedTrackProperties.product === products.PURCHASE ||
                mappedTrackProperties.product === products.REFI ||
                mappedTrackProperties.product.toLowerCase() ===
                  products.HOME_EQUITY.toLowerCase()
              ) {
                fireAndSetformConversionEvent(mappedTrackProperties, options);
              }
              if (!checkoutStartedFired)
                ltAnalyticsService(
                  eventnames.CHECKOUT_STARTED,
                  getPropertiesForCheckout(trackProperties)
                );
            }
            break;
          }
          case eventnames.OOW_OPTIN_VIEWED: {
            if (!oowOptinViewedFired) {
              oowOptinViewedFired = true;
              ltAnalyticsService(event, mappedTrackProperties, options);
            }
            break;
          }
          case eventnames.OOW_OPTIN_COMPLETED:
          case eventnames.OOW_OPTOUT_COMPLETED: {
            if (!oowOptinChosenFired) {
              oowOptinChosenFired = true;
              ltAnalyticsService(event, mappedTrackProperties, options);
            }
            break;
          }
          case eventnames.PROMOTION_CLICKED:
            {
              mappedTrackProperties.promotion_id = trackProperties.stepName;
              mappedTrackProperties.creative = `PageTheme: ${trackProperties.pageTheme}, WidgetTheme: ${trackProperties.formTheme} FormId: ${trackProperties.formId}`;
              mappedTrackProperties.promotion_selected =
                trackProperties.promotion_selected;
              ltAnalyticsService(event, mappedTrackProperties, options);
            }
            break;
          case eventnames.FORM_NAVIGATION_BACK_CLICKED:
            ltAnalyticsService(event, mappedTrackProperties, options);
            break;
          case eventnames.PHONE_NUMBER_MASK_OPTIN_VIEWED:
            if (!phoneMaskOptionViewed) {
              phoneMaskOptionViewed = true;
              ltAnalyticsService(event, mappedTrackProperties, options);
            }
            break;
          case eventnames.PHONE_NUMBER_MASK_OPTIN:
          case eventnames.PHONE_NUMBER_MASK_OPTOUT:
            if (!phoneMaskChosenFired) {
              phoneMaskChosenFired = true;
              ltAnalyticsService(event, mappedTrackProperties, options);
            }
            break;
          case eventnames.STREET_SUGGESTIONS_TIMEOUT:
            if (!streetSuggestoinsTimeoutFired) {
              streetSuggestoinsTimeoutFired = true;
              ltAnalyticsService(event, mappedTrackProperties);
            }
            break;

          default:
            ltAnalyticsService(event, mappedTrackProperties, options);
        }
        break;
      }
      case logtypes.ERROR: {
        logToAtatus(options);
        break;
      }
      case logtypes.INFO: {
        break;
      }
      case logtypes.WARN: {
        break;
      }
      case logtypes.SUCCESS: {
        break;
      }
    }
  } catch (error) {
    logToAtatus({ error: error, metaData: options && options.metaData });
  }
};

export const reportFieldEngagement = (fieldName, incomingFieldValue) => {
  let fieldValue = incomingFieldValue;
  if (isPII(fieldName)) {
    fieldValue = 'pii-field';
  }
  const formMeta = getStore().getState().formMeta;
  let trackProperties = {
    ...formMeta,
    FieldEngaged: fieldName,
    FieldEngagedValue: fieldValue,
  };
  let currFieldEngagedCount = formMeta.fieldEngagedCount;
  if (!currFieldEngagedCount) {
    currFieldEngagedCount = 0;
  }
  ltLogger(
    logtypes.TRACK,
    `${eventnames.FIELD_ENGAGEMENT} ${currFieldEngagedCount}`,
    trackProperties
  );
  getStore().dispatch(
    changeMeta({
      formMeta: { ...formMeta, fieldEngagedCount: currFieldEngagedCount + 1 },
    })
  );
};
window.ltLogger = ltLogger;

export const container = (() => {
  let rootDiv = document.getElementById('ltPhoenixApp');
  if (!rootDiv) {
    rootDiv = document.getElementById('app');
    ltLogger(logtypes.ERROR, undefined, {}, undefined, {
      error: new Error(
        'Parent application is still referring to legacy app element'
      ),
      metaData: 'Parent application is still referring to legacy app element',
    });
  }
  return rootDiv;
})();

/*google analytics*/
window.dataLayer = window.dataLayer || [];
window.pageIsPhx = true;

if (window.atatus) {
  atatus.onBeforeErrorSend(function (error) {
    let { formMeta, formDefinition } = window.ReduxStore.getState();
    let data = getPropertiesForErrorReporting(
      Object.assign({}, formMeta, {
        displayedSteps: JSON.parse(JSON.stringify(formDefinition.stepSet)),
      })
    );
    if (!error.customData) {
      error.customData = data;
    }
    if (!error.tags) error.tags = [];
    if (!error.tags.some((tag) => tag.indexOf('sessionId') !== -1))
      error.tags.push(`sessionId: ${data.sessionId}`);
    if (!error.tags.some((tag) => tag.indexOf('formUuid') !== -1))
      error.tags.push(`formUuid: ${data.formUuid}`);
    if (!error.tags.some((tag) => tag.indexOf('formId') !== -1))
      error.tags.push(`formId: ${data.formId}`);

    return true;
  });
}

/*ltanalytics bootstrap config*/
//window.disabletarget = true;
window.trackingConfig = window.trackingConfig || {};
if (process.env.PHOENIX_ENVIRONMENT.indexOf('local') === -1)
  window.trackingConfig.userOriginationDataViaScript = true;

export const loadLtAnalytics = () => {
  //load ltanalytics to head of document
  let scriptTag = document.createElement('script');
  scriptTag.src = config.LAUNCHSTRAP_URL;
  document.head.appendChild(scriptTag);
  window.launch_key =
    window.launch_key || redcuersConfig.apiConfig.LTALaunchKey;
};
window.ltJornayaCampaign = config.JORNAYA_CAMPAIGN;

/*tracking event vars to fire once*/
let formConversionEventFired = false;
let formPostedEventFired = false;
let checkoutStartedFired = false;
let agreedTermsFired = false;
let lenderConsentFired = false;
let formStartFired = false;
let oopsShownFired = false;
let promotionsViewedFired = [];
let formEngagementsFired = [];
let oowOptinViewedFired = false;
let oowOptinChosenFired = false;
let oowEngagementsFired = [];
let phoneMaskOptionViewed = false;
let phoneMaskChosenFired = false;
let streetSuggestoinsTimeoutFired = false;

export const fireXsellEvent = ({
  type = eventnames.XSELL_EVENT_TYPE,
  product,
  qformuid,
  name,
  banners = [],
}) => {
  let eventData = {
    type,
    EventName: name,
    EventData: {
      product,
      displaying_app: 'phoenix',
      single_page_app_session_id: storage.getItem('lt_sessionKey'),
      qformuid,
      banners,
      banners_count: banners.length,
      parent_qformuid: getQueryParams('parent_qformuid') || null,
    },
  };

  if (analyticsIsLoaded() && window.ltanalytics.postToKafka) {
    window.ltanalytics.postToKafka(eventData);
  }
};
const handleEngagements = (mappedTrackProperties) => {
  const { 'step-index': stepIndex } = mappedTrackProperties;
  if (stepIndex === 1 && !formStartFired) {
    let { product, qformuid } = mappedTrackProperties;
    if (getQueryParams('parent_qformuid')) {
      fireXsellEvent({
        product,
        qformuid,
        name: eventnames.XSELL_EVENT_NAME_REDIRECTED,
      });
    }
    fireXsellEvent({
      product,
      qformuid,
      name: eventnames.XSELL_EVENT_NAME_START,
    });
    formStartFired = true;
    ltAnalyticsService(eventnames.FORM_START, mappedTrackProperties);
  } else if (stepIndex > 1 && !formEngagementsFired[stepIndex - 1]) {
    formEngagementsFired[stepIndex - 1] = true;
    ltAnalyticsService(
      `${eventnames.FORM_ENGAGEMENT} ${stepIndex - 1}`,
      mappedTrackProperties
    );
  }
};
const handlePromotionsViewed = (props) => {
  if (promotionsViewedFired.indexOf(props.stepName) == -1) {
    props.promotion_id = props.stepName;
    props.creative = `PageTheme: ${props.pageTheme}, WidgetTheme: ${props.formTheme} FormId: ${props.formId}`;
    props.promotion_selected = props.promotionSelected;
    ltAnalyticsService(eventnames.PROMOTION_VIEWED, props);
    promotionsViewedFired.push(props.stepName);
  }
};

/**
 *
 * @param {string} url A valid URL
 */
export const getParamsFromUrl = (url) => {
  if (!url) url = window.location.search;
  const el = document.createElement('a');
  el.href = url;
  let query = el.search.substr(1);
  if (!query) return {};
  return query.split('&').reduce((params, param) => {
    let [paramName, paramValue] = param.split('=');
    return {
      ...params,
      [paramName]: decodeURIComponent(paramValue),
    };
  }, {});
};

// TODO: Rename this to getQueryParam, update references, and use `undefined` instead of false if not found
export const getQueryParams = (parameter_name) => {
  let queryParamsObj = getParamsFromUrl(window.location.href);
  return parameter_name in queryParamsObj
    ? queryParamsObj[parameter_name]
    : false;
};

const fireAndSetformConversionEvent = (mappedTrackProperties, options) => {
  if (!formConversionEventFired) {
    ltAnalyticsService(
      eventnames.FORM_CONVERSION,
      mappedTrackProperties,
      options
    );
    formConversionEventFired = true;
    const store = getStore();
    store.dispatch(actions.change('formMeta.formConversionEventFired', true));
  }
};

export const isMyLTHost = (currentHost) => {
  /*
   * PHX-5910: Moving all "mc", "my", & "mylt" subdomain traffic to "spring" subdomain.
   * MC (Money Center) is deprecated. The rest of the MyLT domains will be turned off over time.
   */
  const MyLTHosts = [
    'spring.dev.lendingtree.com',
    'spring.staging.lendingtree.com',
    'spring.preprod.lendingtree.com',
    'spring.lendingtree.com',
    'my.lendingtree.com',
  ];
  return MyLTHosts.indexOf(currentHost) != -1;
};

/**
 * A quick function to determine if the top-left corner of an element is within the viewport
 * @param {DOMElement} element
 * @returns boolean
 */
export const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

let fetchFunction;
let defaultFetch = isMyLTHost(window.location.host) ? whatwgFetch : isoFetch;
export const setFetchFunction = (f) => (fetchFunction = f);
export const getFetchFunction = () => fetchFunction || defaultFetch;
window.getFetchFunction = getFetchFunction;
