import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import StepsComposer from './steps-composer';
import ToolTip from './controls/tooltip';
import Explain from './explain';
import { getFormatter } from '../../utils/formatters';
import { getParser } from '../../utils/parsers';
import { sanitizer } from '../../common/sanitizer';
import { resetHiddenComponent } from '../../actions/formActions';
import { Parser } from 'html-to-react';
import {
  getType,
  getMask,
  getPlaceHolderChar,
  getAutoFillTag,
} from '../../utils/stepFunctions';
import { titleCase } from '../../utils/titleCase';

const Step = ({
  step,
  steps,
  showExplain,
  index,
  handleNext,
  disabled,
  warnings,
  dispatch,
}) => {
  const {
    headline,
    subheadline,
    sublabel,
    showLabel,
    nodetype,
    attributeid,
    tooltip,
    label,
    classnames = '',
  } = step;
  let stepClasses = `${attributeid}` + (classnames ? ` ${classnames}` : '');
  let setHeadlineExplainText = window.ltExplainTextPosition === 'headline' ? true : false;

  useEffect(() => {
    if (!step.showStep) {
      dispatch(resetHiddenComponent(step.attributeid));
    }
  }, [step.showStep, dispatch]);

  let checkNodeTypes = (nodes) => {
    let node = nodes.filter((node) => {
      if (
        step.nodetype === node.nodetype &&
        node.attributeid === step.attributeid
      ) {
        return node;
      }
    });
    return node.length ? node[0].autofocus : false;
  };
  let checkAutoFocus = () => {
    let checkboxNotExistCount = 0;
    let autoFocusCheckedNodes = [];
    let multipartNodes = [];
    const isMultipartWithText = (item) =>
      item.nodetype === 'multipart' &&
      item.multipartcontrol &&
      Object.values(item.multipartcontrol).every((e) => e && e.type === 'text');
    steps.forEach((item) => {
      if (item.nodetype === 'multipart') {
        multipartNodes.push(item);
      }
      if (item.autofocus) {
        autoFocusCheckedNodes.push(item);
      } else {
        if (item.autofocus === undefined) {
          checkboxNotExistCount++;
        }
      }
    });

    if (
      autoFocusCheckedNodes.length === 1 &&
      checkNodeTypes(autoFocusCheckedNodes)
    ) {
      return autoFocusCheckedNodes[0].autofocus;
    } else if (
      autoFocusCheckedNodes.length > 1 &&
      step.nodetype === autoFocusCheckedNodes[0].nodetype &&
      step.attributeid === autoFocusCheckedNodes[0].attributeid
    ) {
      return autoFocusCheckedNodes[0].autofocus;
    } else if (
      autoFocusCheckedNodes.length === 0 &&
      steps.length === checkboxNotExistCount &&
      step.nodetype === 'multipart' &&
      multipartNodes[0] &&
      multipartNodes[0].attributeid === step.attributeid
    ) {
      return true;
    } else if (
      autoFocusCheckedNodes.length === 0 &&
      steps.length === 1 &&
      isMultipartWithText(step)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return step.showStep ? (
    <div
      className={`ltFormGroup lt${
        nodetype ? titleCase(nodetype, 0) : 'Input'
      } ${attributeid}${
        step.interstitialRadioButtonLayout
          ? ' ' + step.interstitialRadioButtonLayout
          : ''
      }`}
    >
      {(index === 0 || showLabel) && (headline || subheadline) && (
        <div className="ltFormHeadlineContainer">
          {headline && !setHeadlineExplainText && (
            <h1
              className="ltFormQuestionHeadline"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: sanitizer(headline) }}
            />
          )}
          {headline && setHeadlineExplainText && (
            <h1
              className="ltFormQuestionHeadline">{Parser().parse(sanitizer(headline))}
              <Explain
                content={steps[0].explaintext}
                classExt={steps[0].attributeid}
                show={showExplain}
                buttonText={steps[0].explainbuttontext}
              />
            </h1>
          )}
          {subheadline && (
            <h2
              className="ltFormQuestionSubHeadline"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: sanitizer(subheadline) }}
            />
          )}
        </div>
      )}
      {(index === 0 || showLabel) &&
        // determine if the div is showing anything.  TECH-DEBT: we could do these checks once or reorganize the HTML
        ((window.LT5theme && steps[0].explaintext && index == 0) ||
          label ||
          (tooltip &&
            tooltip.tooltiptext &&
            tooltip.tooltiptext.length > 0)) && (
          <div className={`ltFormQuestion ${stepClasses}`}>
            {label && (
              /* eslint-disable-next-line react/no-danger */
              <span dangerouslySetInnerHTML={{ __html: sanitizer(label) }} />
            )}
            {tooltip &&
              tooltip.tooltiptext &&
              tooltip.tooltiptext.length > 0 && (
                <ToolTip
                  content={tooltip.tooltiptext}
                  iconType={tooltip.tooltiptype}
                  iconPosition={tooltip.tooltiposition}
                  tooltipDisplay={tooltip.tooltipdisplay}
                  showxonTooltip={tooltip.showxontooltip}
                />
              )}
            {window.LT5theme && index === 0 && (
              <Explain
                content={steps[0].explaintext}
                show={showExplain}
                classExt={steps[0].attributeid}
                buttonText={steps[0].explainbuttontext}
              />
            )}
          </div>
        )}
      {(index === 0 || showLabel) && sublabel && (
        <p
          className="ltFormQuestionSubLabel"
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: sanitizer(sublabel) }}
        />
      )}
      <StepsComposer
        autoFocus={checkAutoFocus()}
        steps={steps}
        step={step}
        handleNext={handleNext}
        type={getType(step.formatter)}
        formatter={getFormatter(step.formatter)}
        modelParser={getParser(step.formatter, step.isDecimalAllowed)}
        inputMask={getMask(step.formatter)}
        placeholderChar={getPlaceHolderChar(step.formatter)}
        autoCompleteText={getAutoFillTag(step.formatter)}
        privacyChar={'\u25cf'}
        disabled={disabled}
        warnings={warnings}
      />
    </div>
  ) : (
    ''
  );
};

Step.propTypes = {
  index: PropTypes.number,
  step: PropTypes.object,
  steps: PropTypes.array,
  showExplain: PropTypes.bool,
  handleNext: PropTypes.func,
  disabled: PropTypes.bool,
  warnings: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect()(Step);
