import React from 'react';
import PropTypes from 'prop-types';

const ErrorWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.hasError;
  delete inputProps.step;
  delete inputProps.inputMask;
  delete inputProps.placeholderChar;
  delete inputProps.onPasted;
  delete inputProps.formatter;

  let className =
    props.warnings && props.warnings[props.step.attributeid]
      ? 'ltHasWarning'
      : 'ltHasError';

  return (
    <div
      className={`ltFormControlText${props.hasError ? ` ${className} ` : ' '}${
        props.step.attributeid
      }`}
    >
      {props.hasInput ? null : <input type="text" {...inputProps} />}
      {props.children}
    </div>
  );
};

ErrorWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hasInput: PropTypes.bool,
  hasError: PropTypes.bool,
  step: PropTypes.object,
  warnings: PropTypes.object,
};

export default ErrorWrapper;
